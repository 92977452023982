<div class="page">
  <div class="page-title-wrapper">
    <h4 class="mb-3 page-title">{{ "customers-create.create" | translate }}</h4>
  </div>

  <div class="main-container">
    <div class="sign-in-and-signup-container">
      <div class="sign-in-and-signup-card">
        <form [formGroup]="createSubCustomerForm" autocomplete="off">
          <div class="input-group-wrapper-feedback business-type-picker" *ngIf="country && country.value === 'DK'">
            <label for="business-type">{{ "customers-create.choose_business_type" | translate }}</label>
            <div class="aw-radio-wrapper mobile-wrapper" id="business-type">
              <div class="aw-radio">
                <input type="radio" class="" value="company" name="businessType" formControlName="businessType"
                       id="company-or-individual1" />
                <label class="" for="company-or-individual1">
                  <span class="aw-radio-label-span">{{ "customers-create.company_partnership" | translate }}</span>
                </label>
              </div>

              <div class="aw-radio">
                <input type="radio" class="" value="individual" name="businessType" formControlName="businessType"
                       id="company-or-individual2" />
                <label class="" for="company-or-individual2">
                  <span class="aw-radio-label-span">{{ "customers-create.individual_person" | translate }}</span>
                </label>
              </div>
            </div>
            <div class="feedback-container">
              @if (formSubmitted && businessType.invalid) {
                <div class="error-msg" *ngIf="businessType.errors.required">
                  {{ "customers-create.business_type_req" | translate }}
                </div>
              }
            </div>
          </div>

          <div class="input-group-wrapper-feedback">
            <label for="country-select">{{ "misc.country" | translate }}</label>
            <select id="country-select" class="form-select" formControlName="country">
              <option selected disabled value="null">{{ "sign_up.choose_country" | translate }}</option>
              <option [ngValue]="country.code" *ngFor="let country of countries">
                {{ helperService.getCountryTranslated(country.country) }}
              </option>
            </select>
            <div class="feedback-container">
              @if (formSubmitted && country.invalid) {
                <div class="error-msg" *ngIf="country.errors.required">
                  {{ "sign_up.country_req" | translate }}
                </div>
              }
            </div>
          </div>

          <div class="input-group-wrapper-feedback">
            <label for="email">{{ "misc.email" | translate }}</label>
            <input id="email" class="form-control" type="text" formControlName="email" />
            <div class="feedback-container">
              @if (formSubmitted && email.invalid) {
                <div class="error-msg" *ngIf="email.errors.required">
                  {{ "misc.email_required" | translate }}
                </div>
              }
            </div>
          </div>

          <div class="input-group-wrapper-feedback mt-2 mb-0">
            <div class="checkbox mb-0">
              <app-aw-checkbox id="terms_check" [initialCheckedValue]="termsChecked"
                               (checkStateEmitter)="toggleConsent('terms')" />
              <label for="terms_check">
                {{ "customers-create.accept" | translate }}
                <a class="primary-color" href="{{ termsOfUse }}" target="_blank" tabindex="-1">
                  {{ "customers-create.terms_n_conds" | translate }}
                </a>
              </label>
            </div>
            <div class="feedback-container">
              <div class="error-msg" *ngIf="formSubmitted && !termsChecked">
                {{ "customers-create.terms_need_accept" | translate }}
              </div>
            </div>
          </div>

          <div class="input-group-wrapper-feedback terms">
            <div class="checkbox">
              <app-aw-checkbox id="gdpr_check" [initialCheckedValue]="gdprChecked"
                               (checkStateEmitter)="toggleConsent('gdpr')" />
              <label for="gdpr_check">
                {{ "customers-create.accept" | translate }}
                <a class="primary-color" href="{{ gdprPolicy }}" target="_blank" tabindex="-1">
                  {{ "misc.gdpr" | translate }}
                </a>
              </label>
            </div>
            <div class="feedback-container">
              <div class="error-msg" *ngIf="formSubmitted && !gdprChecked">
                {{ "customers-create.gdpr_need_accept" | translate }}
              </div>
            </div>
          </div>

          <div class="d-grid gap-2">
            <button class="btn btn-primary" (click)="createCustomer()" [disabled]="showLoading">
              {{ !showLoading ? ("customers-create.create" | translate) : "" }}
              <loading-indicator *ngIf="showLoading" [size]="'large'" [color]="'white'"></loading-indicator>
            </button>
          </div>
        </form>
        <p class="support-call">
          {{ "misc.need_help" | translate }}
          <br />
          <a href="{{ supportPhone.telLink }}">{{ supportPhone.phoneNumber }}</a>
        </p>
      </div>
    </div>
  </div>
</div>
