import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/compat/database';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, lastValueFrom } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { Basic, DeviceTypes, remoteStartParams, replaceForm } from 'shared_models/device';
import { Serial } from 'shared_models/serial';
import { resetMachineSpecificationsServiceParams, updateMachineSpecificationServiceParams } from '../../../../shared_models/machine-specifications';
import { HttpService, RequestTypes } from '../helper/http.service';

@Injectable({
    providedIn: 'root'
})
export class DeviceService {
    serialInUseBool: Observable<any>;
    stripeMinChargeAmount: AngularFireObject<any>;
    device: AngularFireObject<any>;
    serial: Serial;
    baseUrl: string = environment.baseUrl;

    constructor(
        private db: AngularFireDatabase,
        private httpService: HttpService,
        private http: HttpClient,
        private authService: AuthService
    ) {}

    readstripeMinChargeAmount(currency: string): AngularFireObject<number> {
        return this.db.object(`stripe_minimum_charge_amount/${currency.toUpperCase()}`);
    }

    readSerialRecord(serial: string): AngularFireList<Record<string, any>> {
        return this.db.list(`serials/`, ref => ref.orderByChild('serial').equalTo(serial));
    }

    readDevice(uid: string, location_id: string, device_id: string): AngularFireObject<Basic> {
        return this.db.object(`customers/${uid}/locations/${location_id}/devices/${device_id}`);
    }

    readDeviceLastUsed(uid: string, location_id: string, device_id: string): AngularFireObject<Basic> {
        return this.db.object(`customers/${uid}/locations/${location_id}/devices/${device_id}/last_used`);
    }

    readDevices(uid: string, location_id: string): AngularFireObject<Record<string, Basic>> {
        return this.db.object(`customers/${uid}/locations/${location_id}/devices`);
    }

    readTerminalReaders(uid: string, location_id: string): AngularFireObject<Record<string, Basic>> {
        return this.db.object(`customers/${uid}/locations/${location_id}/terminal_readers`);
    }

    async releaseMachine(locationId: string, deviceId: string, uid: string): Promise<any> {
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(this.http.post(`${this.baseUrl}/api_device/release_machine`, { locationId, deviceId }, await this.authService.addHeaders(uid)))
                .then(() => {
                    return resolve(true);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    async createDevice(locId: string, device: Basic, uid: string): Promise<any> {
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(this.http.post(`${this.baseUrl}/api_device/create_device`, { locId, device }, await this.authService.addHeaders(uid)))
                .then(() => {
                    return resolve(true);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    async updateDevice(locId: string, device: any, onbehalf?: string, typeOfDevice?: DeviceTypes): Promise<any> {
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(
                this.http.put(
                    `${this.baseUrl}/api_device/update_device`,
                    { locId, device, typeOfDevice }, // endpoint uses db.update and therefore the device object only needs to hold the updated properties and the id in order to make the update.
                    await this.authService.addHeaders(onbehalf)
                )
            )
                .then(() => {
                    return resolve(true);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    async deleteDevice(locId: string, devId: string, uid: string): Promise<any> {
        return this.httpService.dynamicHttp('api_device/delete_device', RequestTypes.DELETE, { body: { locId, devId }, onBehalf: uid });
    }

    async remoteStart(remoteStartParams: remoteStartParams): Promise<any> {
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(this.http.put(`${this.baseUrl}/api_device/remote_start`, remoteStartParams))
                .then(() => {
                    return resolve(true);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    async replaceSerial(data: replaceForm, onBehalf: string): Promise<any> {
        return this.httpService.dynamicHttp('api_device/replace_serial', RequestTypes.PUT, { body: data, onBehalf });
    }

    async getProtocolVersion(serial: string): Promise<any> {
        return lastValueFrom(this.http.get(`${environment.baseUrl}/api_device/get_protocol_version?serial=${serial}`))
            .then((res: any) => {
                return res;
            })
            .catch(err => {
                return err;
            });
    }

    async getDevicesForOverview(params: string): Promise<any> {
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(this.http.get(`${environment.baseUrl}/api_operator/devices/all?${params}`))
                .then((res: any) => {
                    return resolve(res);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    async updateDeviceMachineServices(data: updateMachineSpecificationServiceParams, onBehalfUid?: string): Promise<any> {
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(this.http.post(`${environment.baseUrl}/api_device/update_machine_services`, data, await this.authService.addHeaders(onBehalfUid)))
                .then((res: any) => {
                    return resolve(res);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    async resetDeviceMachineServices(data: resetMachineSpecificationsServiceParams): Promise<any> {
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(this.http.post(`${environment.baseUrl}/api_device/reset_machine_services`, data))
                .then((res: any) => {
                    return resolve(res);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }
}
