import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, AbstractControl, FormArray, FormGroup, FormsModule, ReactiveFormsModule, ValidatorFn, ValidationErrors } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SnapshotAction } from '@angular/fire/compat/database';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeviceService } from '../../services/device/device.service';
import { HelperService } from '../../services/helper/helper.service';
import { LocationService } from '../../services/location/location.service';
import { CustomerService } from '../../services/customer/customer.service';
import { Basic, IncrementPicker, IncrementUnit, MachineProgram, remoteStartParams, replaceForm } from 'shared_models/device';
import { Location } from 'shared_models/location';
import { Config } from 'shared_models/config';
import { ProductType } from 'shared_models/product-type';
import moment from 'moment';
import { PricingModel, ReconfigValues } from '../device-setup/setup-models';
import { DashboardUser, Settings } from '@dashboard_models/dashboard-user';
import { Subscription, Observable } from 'rxjs';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { CdkDragDrop, moveItemInArray, CdkDropList, CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { TerminalReader } from 'shared_models/terminal';
import { AuthService } from '../../services/auth/auth.service';
import * as Claims from 'shared_models/claims';
import { MachineSpecificationModalComponent } from './machine-specification-modal/machine-specification-modal.component';
import { MachineServicesResetModalComponent } from './machine-services-reset-modal/machine-services-reset-modal.component';
import { MachineServicesEditModalComponent } from './machine-services-edit-modal/machine-services-edit-modal.component';
import { CustomModalComponent } from '../misc/custom-modal/custom-modal.component';
import { AnimatedButtonComponent } from '../misc/animated-button/animated-button.component';
import { LoadingComponent } from '../loading/loading.component';
import { NgIf, NgFor, AsyncPipe, KeyValuePipe } from '@angular/common';
import { LocalStorageService } from '@services/local-storage/local-storage.service';
import _ from 'lodash';

interface ProgramError {
    name: { minLength?: number; maxLength?: number };
    price: { minNumber?: number };
    pulse_number: { minNumber?: number; maxNumber?: number };
    fixed_duration: { minNumber?: number; maxNumber?: number };
}

@Component({
    selector: 'app-device',
    templateUrl: './device.component.html',
    styleUrls: ['./device.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        LoadingComponent,
        AnimatedButtonComponent,
        FormsModule,
        ReactiveFormsModule,
        NgFor,
        CustomModalComponent,
        CdkDropList,
        CdkDrag,
        CdkDragHandle,
        MachineServicesEditModalComponent,
        MachineServicesResetModalComponent,
        MachineSpecificationModalComponent,
        AsyncPipe,
        KeyValuePipe,
        TranslateModule
    ]
})
export class DeviceComponent implements OnInit, OnDestroy {
    currentTime: number;
    private timerId: any;
    role$: Observable<Claims.Roles> = this.authService.getRole;
    showLoadingIndicator = true;
    location_id: string;
    location: Location;
    device_id: string;
    device: any;
    deviceTypeLabel: string;
    PULSE_INCREMENT_ARR_FOR_TIME: number[] = this.helperService.buildMinOrMsValuesArray(30, 1); // only used on "time controlled", for "cost controlled" it is a free-number input field.
    brandList: string[] = this.helperService.brandEnumToArray();
    updateDeviceForm: UntypedFormGroup;
    eProductType = ProductType;
    ePricingModel = PricingModel;
    eConfig = Config;
    eIncrementUnit = IncrementUnit;
    formSubmitted: boolean;
    layout: string;
    updatingDevice: boolean;
    reconfigValues: ReconfigValues;
    isR1: boolean;
    isShortPulseDuration: boolean;
    user: DashboardUser;
    locationsSub: Subscription;
    deviceSub: Subscription;
    lastUsedSub: Subscription;
    subCustomerPermissionSub: Subscription;
    subCustomerAllowed = true;
    currency: string = this.helperService.getUser().settings.currency;
    subCustomerUid: string | null;
    panelOpenState = false;
    isOpen = true;
    btnPressed = false;
    dataValue: number; //remoteStart
    minutes: number; //remoteStart
    moneyAmount: number;
    minimumReached = true;
    showLoading = false;
    showLoadingReplace = false;
    destroy = false;
    showRemoteStart: boolean;
    loadDeleteDevice: boolean;
    settingMaintenance: boolean;
    programsForm: UntypedFormGroup;
    programsFormSubmitted: boolean;
    settings: Settings;
    uid: string;
    isCustomerOperated: boolean;
    hideFixedDuration: boolean;
    selected: boolean;
    selectedProgramKey: string;
    remoteProgram: any;
    deviceNamesOnLocation: string[] = [];
    initialDeviceName: string;
    clickCounter = 0;
    resetClickCounterTimeout: ReturnType<typeof setTimeout> | null;

    constructor(
        public authService: AuthService, // used in html
        private route: ActivatedRoute,
        private router: Router,
        private deviceService: DeviceService,
        private helperService: HelperService,
        private locationService: LocationService,
        private formBuilder: UntypedFormBuilder,
        private modalService: NgbModal,
        private toast: ToastrService,
        private translate: TranslateService,
        private customerService: CustomerService,
        private localStorageService: LocalStorageService
    ) {}

    ngOnDestroy(): void {
        this.locationsSub ? this.locationsSub.unsubscribe() : null;
        this.deviceSub ? this.deviceSub.unsubscribe() : null;
        this.lastUsedSub ? this.lastUsedSub.unsubscribe() : null;

        // Clear the timer when the component is destroyed
        if (this.timerId) {
            clearInterval(this.timerId);
        }
    }

    ngOnInit() {
        this.user = this.helperService.getUser();
        this.isCustomerOperated = window.location.pathname.split('/').includes('customers') ? true : false;
        this.subCustomerUid = this.route.snapshot.paramMap.get('sub_customer_id');
        this.uid = this.isCustomerOperated ? `${this.route.snapshot.paramMap.get('sub_customer_id')}_operated_by_${this.user.uid}` : this.user.uid;
        this.settings = this.user.settings;
        this.currency = this.settings.currency;
        this.location_id = this.route.snapshot.paramMap.get('location_id');
        this.device_id = this.route.snapshot.paramMap.get('device_id');
        this.setupUpdateDeviceForm();
        this.setSubscriptions();
        this.programsForm = this.formBuilder.group({
            programs: this.formBuilder.array([])
        });
        this.timerId = setInterval(() => {
            this.currentTime = parseInt((Date.now() / 1000).toString());
        }, 500);
    }

    setupUpdateDeviceForm() {
        this.updateDeviceForm = this.formBuilder.group({
            // MACHINE SPPECIFICATIONS, should be present on all new devices.
            machine_specifications: this.formBuilder.group({
                brand: new UntypedFormControl(null),
                model: new UntypedFormControl(null, [Validators.maxLength(25)]),
                serial_number: new UntypedFormControl(null, []),
                production_year: new UntypedFormControl(null, [
                    // Current year (+1) and 40 years back, as a list in a dropdown. Format is: YY)Y
                    Validators.min(parseInt(moment().format('YYYY')) - 40),
                    Validators.max(parseInt(moment().format('YYYY')) + 1),
                    Validators.maxLength(4),
                    Validators.minLength(4),
                    Validators.pattern(/^([1-9][0-9][0-9][0-9])$/)
                ]),
                note: new UntypedFormControl(null, [Validators.maxLength(250)])
            }),
            // DEVICE OPTIONS
            guid: new UntypedFormControl(null, [Validators.required]),
            name: new UntypedFormControl(null, [Validators.required, Validators.maxLength(22), this.uniqueNamesOnlyValidator()]),
            price: new UntypedFormControl(null, [Validators.required, Validators.pattern(/^(\d+(?:[\.\,]\d{1,2})?)$/)]), // float with one or two decimals
            startup_time: new UntypedFormControl(null, [Validators.required]),
            pulse_duration: new UntypedFormControl(null, [Validators.required, Validators.pattern(/^(?:2[5-9]|[3-9]\d|[1-9]\d{2}|[1-2]\d{3}|3000)$/)]), // range 25-3000
            pulse_increment: new UntypedFormControl(null), // validated in validateFormBasedOnConfig
            fixed_duration: new UntypedFormControl(null), // validated in onAcDetectModeChange
            pulse_number: new UntypedFormControl(null, [Validators.required, Validators.min(1), Validators.max(100)]),
            ac_detect_mode: new UntypedFormControl(null, [Validators.required]),
            maintenance: new UntypedFormControl(null),
            coin_drop_installed: new UntypedFormControl(null),
            open_door: new UntypedFormControl(null),
            machine_programs: new UntypedFormControl(null)
        });
        const roleSub: Subscription = this.role$.subscribe((role: Claims.Roles) => {
            if (!this.authService.hasLimitedAccess('edit_device', role)) {
                this.updateDeviceForm.disable();
            }
        });
        roleSub.unsubscribe();
    }

    uniqueNamesOnlyValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (this.reconfigValues) {
                const forbidden = this.deviceNamesOnLocation.includes(control.value) && this.reconfigValues.device.name !== control.value && this.initialDeviceName !== control.value;
                return forbidden ? { uniqueName: { value: control.value } } : null;
            } else {
                const forbidden = this.deviceNamesOnLocation.includes(control.value) && this.initialDeviceName !== control.value;
                return forbidden ? { uniqueName: { value: control.value } } : null;
            }
        };
    }

    setSubscriptions() {
        this.subCustomerPermissionSub = this.customerService
            .readSubCustomerPermission(this.user.uid)
            .snapshotChanges()
            .subscribe(action => {
                this.subCustomerPermissionSub.unsubscribe();
                if (this.user.uid.includes('_operated_by_')) {
                    this.subCustomerAllowed = action.payload.val().allow_location;
                }
                if (!this.subCustomerAllowed) {
                    for (const key in this.updateDeviceForm.controls) {
                        this.updateDeviceForm.controls[key].patchValue(this.updateDeviceForm.controls[key].disable());
                    }
                }
            });

        this.locationsSub = this.locationService
            .readLocation(this.uid, this.location_id)
            .snapshotChanges()
            .subscribe((locationSnapAction: SnapshotAction<Location>) => {
                this.location = locationSnapAction.payload.val();
                this.deviceNamesOnLocation = Object.values(this.location.devices).map(device => device.name);
                //only show remotestart if anton terminal is setup and have a user Uid
                this.showRemoteStart = false;
                for (const terminalKey in this.location.terminal_readers) {
                    if (this.location.terminal_readers[terminalKey].uid) {
                        this.showRemoteStart = true;
                    }
                }

                this.deviceSub = this.deviceService
                    .readDevice(this.uid, this.location_id, this.device_id)
                    .snapshotChanges()
                    .subscribe((deviceSnapAction: SnapshotAction<Basic>) => {
                        if (!deviceSnapAction.payload.exists()) {
                            this.router.navigate([this.subCustomerUid ? `customers/${this.subCustomerUid}/locations/${this.location.id}` : `locations/${this.location.id}`]);
                            return;
                        }

                        this.device = deviceSnapAction.payload.val();
                        this.device.guid.substr(-2) === 'R1' ? (this.isR1 = true) : (this.isR1 = false);

                        if (this.device.product_type === ProductType.Solarium) {
                            this.deviceTypeLabel = this.translate.instant('device.solarium');
                        } else {
                            this.deviceTypeLabel = this.translate.instant('device.commercial');
                        }

                        // determine layout (app view)
                        switch (this.device.config) {
                            case Config.IncrementPicker:
                                const deviceIncrement: IncrementPicker = this.device;
                                if (deviceIncrement.increment_unit === IncrementUnit.Money) this.layout = 'variable_cost.svg';
                                if (deviceIncrement.increment_unit === IncrementUnit.Time) this.layout = 'variable_time.svg';
                                if (this.device.open_door) this.layout = 'variable_time_open.svg';
                                if (this.device.product_type === ProductType.Solarium) this.layout = 'solarium.svg';
                                break;
                            case Config.FixedPrice:
                                this.layout = 'fixed_cost.svg';
                                break;
                            case Config.Access:
                                this.layout = 'access.svg';
                                break;
                            case Config.ProgramPicker:
                                this.layout = 'programs.svg';
                                break;
                            default:
                                this.layout = 'variable_cost.svg';
                                break;
                        }

                        // correcting values for input fields
                        this.device.price
                            ? (this.device.price = this.device.price / 100) // "from cents to dollars"
                            : null;

                        if (this.device.increment_unit === IncrementUnit.Money && this.device.pulse_increment && !this.device.pulse_number) {
                            this.device.pulse_number = 1;
                            this.device.ac_detect_mode = 0;
                            this.device.fixed_duration = 0;
                        }

                        this.device.increment_unit === IncrementUnit.Money && this.device.pulse_number && this.device.pulse_increment
                            ? (this.device.pulse_increment = this.device.pulse_increment / 100) // "from cents to dollars"
                            : null;

                        this.device.product_type === this.eProductType.Solarium && !this.device.startup_time
                            ? (this.device.startup_time = 0) // only to show the select correct in the form we set startup_time to 0 is it is a Solarium and is not set.
                            : null;

                        this.device.config === this.eConfig.FixedPrice && this.device.ac_detect_mode === undefined ? (this.device.ac_detect_mode = 0) : null;

                        // See's if any programs has been made yet, if undefined then we add an empty program.
                        // If defined, we then sort based on each obj sort_weight

                        this.setProgramsFormValues();
                        this.programErrorSub();

                        if (this.device.pulse_duration)
                            if (this.device.pulse_duration >= 60000) {
                                this.device.pulse_duration = this.device.pulse_duration / 60000;
                            } else {
                                this.isShortPulseDuration = true;
                            }

                        this.dataValue = this.device.pulse_increment;
                        this.updateDeviceForm.patchValue(this.device);
                        this.initialDeviceName = this.device.name;

                        this.onAcDetectModeChange(true); // to check for disabling fixed_duration

                        this.showLoadingIndicator = false;
                    });
            });
    }

    setProgramsFormValues() {
        const control = this.programsForm.get('programs') as FormArray;
        if (this.device.machine_programs !== undefined) {
            control.clear();
            const array = [];
            Object.keys(this.device.machine_programs).forEach(key => {
                const values = this.device.machine_programs[key];
                array.push({
                    pushkey: key,
                    name: values.name,
                    price: values.price,
                    pulse_number: values.pulse_number,
                    sort_weight: values.sort_weight,
                    fixed_duration: values.fixed_duration === undefined ? 15 : values.fixed_duration,
                    pulse_duration: values.pulse_duration
                });
            });

            array.sort((a, b) => a.sort_weight - b.sort_weight);
            for (const item of array) {
                control.push(
                    this.formBuilder.group({
                        pushkey: item.pushkey,
                        name: item.name,
                        price: item.price / 100,
                        pulse_number: item.pulse_number,
                        sort_weight: item.sort_weight,
                        fixed_duration: item.fixed_duration,
                        pulse_duration: item.pulse_duration
                    })
                );
            }
        }
    }

    programErrorSub() {
        // Looks for any errors in each input.
        this.programsForm.valueChanges.subscribe(value => {
            const errors: Record<string, { name: any; price: any; pulse_number: any; fixed_duration: any }> = {};
            value.programs.forEach(element => {
                element.pushkey ? null : (element.pushkey = this.helperService.createPushKey());
                errors[element.pushkey] = { name: null, price: null, pulse_number: null, fixed_duration: null };

                if (element.name !== null && element.name !== undefined) {
                    if (element.name.length < 3) {
                        errors[element.pushkey].name = { min: true, minLength: 3 };
                    } else if (element.name.length > 20) {
                        errors[element.pushkey].name = { max: true, maxLength: 20 };
                    }
                } else {
                    errors[element.pushkey].name = { min: true, minLength: 3 };
                }

                const price = parseFloat(String(element.price).replaceAll(',', ''));
                if (isNaN(parseInt(String(price))) || price < 0) {
                    errors[element.pushkey].price = { min: true, minNumber: 0 };
                }
                if ((isNaN(parseInt(String(element.pulse_number))) || element.pulse_number < 1) && this.device.pulse_duration) {
                    errors[element.pushkey].pulse_number = { min: true, minNumber: 1 };
                } else if ((isNaN(parseInt(String(element.pulse_number))) || element.pulse_number > 100) && this.device.pulse_duration) {
                    errors[element.pushkey].pulse_number = { max: true, maxNumber: 100 };
                }

                if (isNaN(parseInt(String(element.fixed_duration))) || element.fixed_duration < 0) {
                    errors[element.pushkey].fixed_duration = { min: true, minNumber: 0 };
                } else if (isNaN(parseInt(String(element.fixed_duration))) || element.fixed_duration > 180) {
                    errors[element.pushkey].fixed_duration = { max: true, maxNumber: 180 };
                }

                for (const key in errors) {
                    for (const prop in errors[key]) {
                        errors[key][prop] ? null : delete errors[key][prop];
                    }
                    Object.keys(errors[key]).length ? null : delete errors[key];
                }
            });
            this.programsForm.setErrors(Object.keys(errors).length ? errors : null);
        });
    }

    validateFormBasedOnConfig(type: ProductType) {
        function pulseDurationOnLegacy(control: AbstractControl): Record<string, any> | null {
            if (control.value % 250 !== 0) {
                return { pulseDurationOnLegacy: true };
            }
            return null;
        }

        if (type === ProductType.Solarium) {
            this.pulse_duration.setValidators(null);
            this.pulse_increment.setValidators(null);
            this.pulse_number.setValidators(null);
            this.ac_detect_mode.setValidators(null);
        } else {
            this.startup_time.setValidators(null);
            this.updateDeviceForm.patchValue(
                {
                    startup_time: null
                },
                { emitEvent: false }
            );

            // Validating: Variable, pulse, cost
            if (this.device.increment_unit === IncrementUnit.Money && this.device.pulse_number) {
                this.pulse_increment.setValidators([Validators.required, Validators.min(0.01), Validators.max(100000)]);
                this.fixed_duration.setValidators([Validators.required, Validators.min(0), Validators.max(180)]);
                this.price.setValidators(null);
                if (this.device.protocol_version === '1.5.0' || this.device.protocol_version === '1.0.0') {
                    this.pulse_duration.setValidators([Validators.required, Validators.pattern(null), Validators.min(250), pulseDurationOnLegacy]);
                }
                this.updateDeviceForm.patchValue(
                    {
                        price: null
                    },
                    { emitEvent: false }
                );
            }

            // Validating: Variable, pulse, time
            if (this.device.increment_unit === IncrementUnit.Time && this.device.pulse_number) {
                this.pulse_increment.setValidators([Validators.required, Validators.min(1), Validators.max(30)]);
                this.fixed_duration.setValidators(null);
                if (this.device.protocol_version === '1.5.0' || this.device.protocol_version === '1.0.0') {
                    this.pulse_duration.setValidators([Validators.required, Validators.pattern(null), Validators.min(250), pulseDurationOnLegacy]);
                }
                this.updateDeviceForm.patchValue(
                    {
                        fixed_duration: null
                    },
                    { emitEvent: false }
                );

                if (this.ac_detect_mode.value === 0 || !this.ac_detect_mode.value) {
                    this.ac_detect_mode.setValidators(null);
                } else {
                    this.ac_detect_mode.setValidators([Validators.required]);
                }
            }

            // Validating: Variable, pulse, program
            if (this.device.config === 'PROGRAM_PICKER' && this.device.pulse_duration) {
                this.price.setValidators(null);
                this.pulse_number.setValidators(null);
                if (this.ac_detect_mode.value === 0) {
                    this.fixed_duration.setValidators(null);
                } else {
                    this.fixed_duration.setValidators([Validators.required, Validators.min(0), Validators.max(180)]);
                }

                this.updateDeviceForm.patchValue(
                    {
                        price: null,
                        pulse_number: null,
                        fixed_duration: this.ac_detect_mode.value === 0 ? null : this.fixed_duration.value
                    },
                    { emitEvent: false }
                );
            }

            // Validating: Variable, full duration
            if (this.device.config === Config.IncrementPicker && !this.device.pulse_duration) {
                this.pulse_number.setValidators(null);
                this.startup_time.setValidators(null);
                this.pulse_duration.setValidators(null);
                this.fixed_duration.setValidators(null);

                this.updateDeviceForm.patchValue(
                    {
                        pulse_number: null,
                        startup_time: null,
                        pulse_duration: null,
                        fixed_duration: null
                    },
                    { emitEvent: false }
                );

                if (this.ac_detect_mode.value === 0 || !this.ac_detect_mode.value) {
                    this.ac_detect_mode.setValidators(null);
                } else {
                    this.ac_detect_mode.setValidators([Validators.required]);
                }
            }

            // Validating: Full Duration Program
            if (this.device.full_duration_program) {
                this.pulse_increment.setValidators(null);
                this.price.setValidators(null);
                this.pulse_duration.setValidators(null);
                if (this.ac_detect_mode.value === 0) {
                    this.fixed_duration.setValidators(null);
                } else {
                    this.fixed_duration.setValidators([Validators.required, Validators.min(0), Validators.max(180)]);
                }

                this.updateDeviceForm.patchValue(
                    {
                        price: null,
                        pulse_number: 1,
                        pulse_increment: null,
                        pulse_duration: null,
                        fixed_duration: this.ac_detect_mode.value === 0 ? null : this.fixed_duration.value
                    },
                    { emitEvent: false }
                );
            }

            // Validating: Fixed, pulse
            if (this.device.config === Config.FixedPrice && this.isShortPulseDuration) {
                this.startup_time.setValidators(null);
                this.pulse_increment.setValidators(null);
                if (this.device.protocol_version === '1.5.0' || this.device.protocol_version === '1.0.0') {
                    this.pulse_duration.setValidators([Validators.required, Validators.pattern(null), Validators.min(250), pulseDurationOnLegacy]);
                }
                this.fixed_duration.setValidators([Validators.required, Validators.min(0), Validators.max(180)]);
                this.updateDeviceForm.patchValue(
                    {
                        startup_time: null,
                        pulse_increment: null,
                        pulse_number: !this.pulse_number.value ? 1 : this.pulse_number.value,
                        fixed_duration: this.fixed_duration.value
                    },
                    { emitEvent: false }
                );
            }

            // Validating: Fixed, user start time
            if (this.device.config === Config.FixedPrice && this.device.pulse_duration && !this.isShortPulseDuration) {
                this.pulse_duration.setValidators([Validators.required, Validators.pattern(this.device.protocol_version === '1.5.0' || this.device.protocol_version === '1.0.0' ? /^([1-4])$/ : /^([1-9]|[1-9][0-9]|1[0-7][0-9]|180)$/)]);
                this.fixed_duration.setValidators([Validators.required, Validators.min(0), Validators.max(180)]);
                this.pulse_increment.setValidators(null);
                this.pulse_number.setValidators(null);
                this.updateDeviceForm.patchValue(
                    {
                        pulse_increment: null,
                        pulse_number: null,
                        fixed_duration: this.fixed_duration.value
                    },
                    { emitEvent: false }
                );
            }

            // Validating: R2, non-solarium
            if (!this.isR1) {
                this.ac_detect_mode.setValidators(null);
                this.updateDeviceForm.patchValue(
                    {
                        ac_detect_mode: null
                    },
                    { emitEvent: false }
                );
            }
        }

        for (const key in this.updateDeviceForm.controls) {
            this.updateDeviceForm.controls[key].updateValueAndValidity();
        }

        if (this.updateDeviceForm.invalid) {
            for (const key in this.updateDeviceForm.controls) {
                if (this.updateDeviceForm.controls[key].invalid) {
                    console.log(key, this.updateDeviceForm.controls[key].errors);
                }
            }
        }
    }

    getProgramError(): ProgramError {
        const errors = { name: null, price: null, pulse_number: null, fixed_duration: null };

        if (this.programsForm.errors) {
            Object.values(this.programsForm.errors).forEach(element => {
                if (element.name) {
                    element.name.minLength ? (errors.name = { minLength: element.name.minLength }) : null;
                    element.name.maxLength ? (errors.name = { minLength: element.name.maxLength }) : null;
                }

                if (element.price) {
                    element.price.min ? (errors.price = { min: true, minNumber: element.price.minNumber }) : null;
                }

                if (element.pulse_number) {
                    element.pulse_number.minNumber ? (errors.pulse_number = { min: true, minNumber: element.pulse_number.minNumber }) : null;
                    element.pulse_number.maxNumber ? (errors.pulse_number = { max: true, maxNumber: element.pulse_number.maxNumber }) : null;
                }

                if (element.fixed_duration) {
                    element.fixed_duration.min ? (errors.fixed_duration = { min: true, minNumber: element.fixed_duration.minNumber }) : null;
                    element.fixed_duration.max ? (errors.fixed_duration = { max: true, maxNumber: element.fixed_duration.maxNumber }) : null;
                }
            });
        }

        return errors;
    }

    drop(event: CdkDragDrop<string[]>) {
        const array = this.programsForm.get('programs').value;
        moveItemInArray(array, event.previousIndex, event.currentIndex);

        this.programsForm.patchValue({
            programs: array
        });
    }

    selectedProgram(program: any) {
        console.log(program);
        if (this.selectedProgramKey === program.key) {
            this.selectedProgramKey = '';
            this.selected = false;
        } else {
            this.selected = true;
            this.selectedProgramKey = program.key;
        }
        if (this.selected) {
            this.remoteProgram = program;
        }
    }

    addEmptyProgram(createProgram?: boolean) {
        const control = this.programsForm.get('programs') as FormArray;

        if (createProgram && control.controls.length >= 20) {
            createProgram = false;
            this.helperService.defaultHtmlToast(this.translate.instant('device.max_programs'), this.translate.instant('device.too_many_programs'), 'Warning');
        }
        this.programsForm.updateValueAndValidity();

        if (createProgram) {
            const pushkey = this.helperService.createPushKey();
            const errors: Record<string, { name: any; price: any; pulse_number: any }> = {};
            errors[pushkey] = { name: { min: true, minLength: 3 }, price: { min: true, minNumber: 0 }, pulse_number: { min: true, minNumber: 0 } };
            control.push(
                this.formBuilder.group({
                    pushkey: new UntypedFormControl(pushkey),
                    name: new UntypedFormControl(null),
                    price: new UntypedFormControl(null),
                    pulse_number: new UntypedFormControl(this.device.full_duration_program ? 1 : null),
                    sort_weight: new UntypedFormControl(control.length),
                    fixed_duration: new UntypedFormControl(15)
                })
            );
            this.programsForm.setErrors(errors);
        }
    }

    removeProgram(index: number) {
        const control = this.programsForm.get('programs') as FormArray;
        control.removeAt(index);
    }

    submitUpdatePrograms() {
        this.programsFormSubmitted = true;
        const obj: Record<string, MachineProgram> = {};
        let count = 0;

        this.programsForm.get('programs').value.forEach((_obj: MachineProgram) => {
            for (const key in _obj) {
                _obj[key] !== null ? null : delete _obj[key];
            }

            obj[_obj.pushkey] = {
                name: _obj.name,
                price: Math.round(_obj.price * 100 + Number.EPSILON), // ChatGPT response to safeguard against floating point errors
                sort_weight: count,
                fixed_duration: this.ac_detect_mode.value === 0 ? (_obj.fixed_duration === undefined ? 15 : _obj.fixed_duration) : !this.device.pulse_duration ? (_obj.fixed_duration === undefined ? 15 : _obj.fixed_duration) : 15
            };

            if (this.device.pulse_duration) {
                obj[_obj.pushkey].pulse_number = Math.round(_obj.pulse_number);
            }

            if (!this.device.pulse_duration && obj[_obj.pushkey].fixed_duration) {
                obj[_obj.pushkey].pulse_duration = _obj.fixed_duration * 60000;
            }

            if (this.device.full_duration_program) {
                obj[_obj.pushkey].pulse_number = 1;
            }

            count++;
        });
        this.updateDeviceForm.patchValue({
            machine_programs: obj
        });

        this.programsForm.updateValueAndValidity();

        if (this.programsForm.valid) {
            this.updateDevice();
            this.modalService.dismissAll();
        }
    }

    async updateDevice() {
        this.formSubmitted = true;
        this.validateFormBasedOnConfig(this.device.product_type);
        if (this.updateDeviceForm.valid && !this.updatingDevice) {
            this.updatingDevice = true;
            this.updateDeviceForm.patchValue(
                {
                    pulse_increment: this.pulse_increment.value && !isNaN(this.pulse_increment.value) ? parseFloat(this.pulse_increment.value) : null,
                    fixed_duration: this.fixed_duration.value
                },
                { emitEvent: false }
            );

            const copyFormData = { ...this.updateDeviceForm.value, ...{ id: this.device_id } };

            copyFormData.coin_feedback_enabled =
                copyFormData.coin_drop_installed && (copyFormData.ac_detect_mode || copyFormData.ac_detect_mode === 0) ? (copyFormData.ac_detect_mode === 1 || copyFormData.ac_detect_mode === 3 ? true : false) : null;

            if (this.device.product_type === this.eProductType.Solarium) {
                copyFormData.startup_time = parseInt(this.startup_time.value) ? parseInt(this.startup_time.value) : null; // instant start => 0, should be null i.e. removed
            } else {
                copyFormData.startup_time = null;
            }

            copyFormData.price || copyFormData.price === 0 ? (copyFormData.price = this.helperService.roundToTwoDecimals(copyFormData.price * 100)) : null; // "from cents to dollars"
            this.device.increment_unit === IncrementUnit.Money && this.device.pulse_number && this.device.pulse_increment ? (copyFormData.pulse_increment = copyFormData.pulse_increment * 100) : null; // "from cents to dollars"
            if (!(this.device.increment_unit === IncrementUnit.Money && this.device.pulse_number)) {
                copyFormData.ac_detect_mode > 0 ? (copyFormData.fixed_duration = 180) : null;
            }
            this.device.pulse_pause ? (copyFormData.pulse_pause = copyFormData.pulse_duration) : null;
            copyFormData.pulse_duration && !this.isShortPulseDuration ? (copyFormData.pulse_duration = copyFormData.pulse_duration * 60000) : null;

            if (((this.device.increment_unit === IncrementUnit.Time && this.device.pulse_number) || (this.device.config === Config.IncrementPicker && !this.device.pulse_number)) && this.ac_detect_mode.value === 0) {
                copyFormData.ac_detect_mode = null;
            }

            // check if machine_specifications has any values that are not null
            Object.values(copyFormData.machine_specifications).some((value: any) => value !== null) ? (copyFormData.machine_specifications.machine_spec_service = this.device.machine_specifications.machine_spec_service) : null;

            await this.deviceService
                .updateDevice(this.location_id, copyFormData, this.uid)
                .then(() => {
                    this.toast.success(this.translate.instant('device.changes_saved'), this.translate.instant('misc.success'));
                    this.formSubmitted = false;
                    this.programsFormSubmitted = false;
                    this.updatingDevice = false;
                })
                .catch(err => {
                    this.updatingDevice = false;
                });
        } else {
            console.error('Invalid form');
            console.error(this.updateDeviceForm);
            for (const key in this.updateDeviceForm.controls) {
                if (this.updateDeviceForm.controls[key].invalid) {
                    console.log(key, this.updateDeviceForm.controls[key].errors);
                }
            }
            this.updatingDevice = false;
        }
    }

    async setMaintenance() {
        this.settingMaintenance = true;
        await this.deviceService
            .updateDevice(this.location_id, { id: this.device_id, maintenance: !this.device.maintenance }, this.uid)
            .then(() => {
                this.settingMaintenance = false;
            })
            .catch(() => {
                this.settingMaintenance = false;
            });
    }

    deleteDevice() {
        this.loadDeleteDevice = true;
        this.deviceService
            .deleteDevice(this.location_id, this.device_id, this.uid)
            .then(() => {
                this.loadDeleteDevice = false;
                this.modalService.dismissAll();
                this.router.navigate([this.subCustomerUid ? `customers/${this.subCustomerUid}/locations/${this.location.id}` : `locations/${this.location.id}`], { queryParams: { delete_device: true } });
                this.toast.success(this.translate.instant('device.device_deleted'), this.translate.instant('misc.success'));
            })
            .catch(err => {
                this.loadDeleteDevice = false;
                this.toast.warning(err, this.translate.instant('misc.attention'), { timeOut: 10000 });
            });
    }

    replaceDevice(newSerial: string) {
        this.showLoadingReplace = true;
        const data: replaceForm = {
            guidOld: this.device.guid,
            guidNew: newSerial
        };
        if (this.device.relay_number) {
            data.relayNumber = this.device.relay_number;
        }
        this.deviceService
            .replaceSerial(data, this.uid)
            .then((resp: any) => {
                this.toast.success(`${resp.guidOld} ${this.translate.instant('device.serial_replaced')} ${resp.guidNew}`, this.translate.instant('misc.success'));
                this.showLoadingReplace = false;
            })
            .catch(err => {
                this.toast.warning(this.translate.instant(`device.replace_serial_error`), this.translate.instant('misc.attention'));
                this.showLoadingReplace = false;
            });
    }

    reconfig() {
        this.destroy = true;
        //wierd workaround to avoid animation on reroute

        this.reconfigValues = {
            dev_id: this.device.id,
            serial: this.device.guid,
            password: this.device.password,
            device: this.device,
            relay_number: this.device.relay_number
        };
        this.localStorageService.removeItem('reconfigValues');
        this.localStorageService.setItem('reconfigValues', this.reconfigValues);
        this.router.navigate([this.subCustomerUid ? `customers/${this.subCustomerUid}/locations/${this.location.id}/device_setup/reconfig` : `locations/${this.location.id}/device_setup/reconfig`], {
            queryParams: {
                name: this.device.name
            }
        });
    }

    getCurrencyInUppercase(currency: string): string {
        return currency.toUpperCase();
    }

    onAcDetectModeChange(init?: boolean) {
        if (((this.device.increment_unit === IncrementUnit.Time && this.device.pulse_number) || (this.device.config === Config.IncrementPicker && !this.device.pulse_number)) && this.ac_detect_mode.value === 0) {
            // variable, time, pulse or variable, full duration device
            // then we do not want to show or set fixed_duration
            this.hideFixedDuration = true;
            return;
        }
        this.hideFixedDuration = false;

        if (!(this.device.pulse_duration && this.isShortPulseDuration) && !(this.device.config === Config.ProgramPicker)) {
            return;
        }

        // new rule added the 11th of Oct. 2022 with Christian: "Add Busy Signal to Variable"
        // We add a new field called "Occupied for" (OF) which here in the component is translated into fixed_duration, and takes 0-180 min. from user input.
        // Logic as follows:
        // if ac_detect_mode === 0
        //      OF enabled = true // user can set the fixed_duration
        // else
        //      fixed_duration = 180
        //      OF enabled = false // hardcode 180 in fixed_duration

        if (!init) {
            if (this.ac_detect_mode.value > 0) {
                this.fixed_duration.setValue(180);
            } else {
                this.fixed_duration.setValue(null);
            }
            this.fixed_duration.updateValueAndValidity();
        }
    }

    getLastUsedLabel(last_used: number, created_on: number): { label: string; value: string | null } {
        if (last_used === created_on) {
            return {
                label: '',
                value: this.translate.instant('device.ready')
            };
        } else if (last_used > parseInt(moment().format('X'))) {
            return {
                label: `${this.translate.instant('device.available')}`,
                value: `${moment().locale(this.translate.currentLang).to(moment(last_used, 'X'))}`
            };
        } else {
            return {
                label: this.translate.instant('location.cycle_ended'),
                value: `${moment(last_used, 'X').locale(this.translate.currentLang).fromNow()}`
            };
        }
    }

    getFirstTerminalUserUid(location: Location): string {
        const result: string[] = [];
        for (const terminalKey in location.terminal_readers) {
            const terminalReader: TerminalReader = location.terminal_readers[terminalKey];
            if (terminalReader.uid) {
                return terminalReader.uid;
            }
        }
    }

    sendRemoteStart() {
        this.showLoading = true;

        if (this.location.terminal_readers) {
            const remoteStartDevice: remoteStartParams = {
                device_serial: this.device.guid,
                terminal_user_uid: this.getFirstTerminalUserUid(this.location),
                minutes: 0,
                data_value: 0
            };

            if (this.device.config === 'INCREMENT_PICKER' && this.device.increment_unit === 'money' && this.device.pulse_duration) {
                remoteStartDevice.data_value = this.dataValue * 100; //to cents
                remoteStartDevice.minutes = this.dataValue * this.device.pulse_increment;
            }
            if (this.device.config === 'INCREMENT_PICKER' && this.device.increment_unit === 'time' && this.device.pulse_duration) {
                remoteStartDevice.data_value = this.dataValue * 100; //to cents
                remoteStartDevice.minutes = this.dataValue * this.device.pulse_increment;
            }
            if (this.device.config === 'INCREMENT_PICKER' && this.device.increment_unit === 'time') {
                remoteStartDevice.data_value = this.dataValue;
                remoteStartDevice.minutes = this.dataValue;
            }
            if (this.device.config === 'PROGRAM_PICKER') {
                remoteStartDevice.program = this.remoteProgram.value;
                remoteStartDevice.data_value = this.remoteProgram.value.pulse_number;
                remoteStartDevice.minutes = this.remoteProgram.value.fixed_duration ? this.remoteProgram.value.fixed_duration : this.device.fixed_duration;
            }

            this.deviceService
                .remoteStart(remoteStartDevice)
                .then(() => {
                    this.toast.success(this.device.name + ' ' + this.translate.instant('device.succes_remote_start'), this.translate.instant('misc.success'), { timeOut: 10000 });
                    this.showLoading = false;
                    this.modalService.dismissAll('Cancel');
                })
                .catch(() => {
                    this.showLoading = false;
                    this.toast.error(this.device.name + ' ' + this.translate.instant('device.remote_start_failed'), this.translate.instant('misc.error'), { timeOut: 10000 });
                });
        }
    }

    remotestartValues(add: boolean) {
        add ? (this.dataValue += this.device.pulse_increment) : (this.dataValue -= this.device.pulse_increment);

        if (this.dataValue - this.device.pulse_increment <= 0 || this.dataValue + this.device.pulse_increment >= this.device.maximum_value) {
            this.minimumReached = true;
        } else {
            this.minimumReached = false;
        }
    }

    openModal(modal: any) {
        switch (modal._declarationTContainer.localNames[0]) {
            case 'deleteDeviceModal': {
                this.modalService
                    .open(modal, {
                        size: 'sm',
                        ariaLabelledBy: 'modal-basic-title'
                    })
                    .result.then((event: any) => {})
                    .catch((event: any) => {});
                break;
            }
            case 'remoteStart': {
                this.modalService
                    .open(modal, {
                        size: 'sm',
                        ariaLabelledBy: 'modal-basic-title'
                    })
                    .result.then((event: any) => {})
                    .catch((event: any) => {});
                break;
            }
            case 'createProgram': {
                if (this.ac_detect_mode.value === 0) {
                    this.modalService
                        .open(modal, {
                            size: 'lg',
                            ariaLabelledBy: 'modal-basic-title'
                        })
                        .result.then((event: any) => {})
                        .catch((event: any) => {});
                } else {
                    this.modalService
                        .open(modal, {
                            size: 'md',
                            ariaLabelledBy: 'modal-basic-title'
                        })
                        .result.then((event: any) => {})
                        .catch((event: any) => {});
                }
                break;
            }
            case 'editMachineServices': {
                if (!this.device.machine_specifications) {
                    this.device.machine_specifications = {
                        brand: null,
                        model: null,
                        serial_number: null,
                        production_year: null, // Current year and 40 years back, as a list in a dropdown. Format is: YYYY
                        note: null,
                        machine_spec_service: {
                            maintenance_limit: 1000,
                            current_maintenance_count: 0,
                            last_maintenance_timestamp: moment().unix()
                        }
                    };
                }
                this.modalService
                    .open(modal, {
                        size: 'md',
                        ariaLabelledBy: 'modal-basic-title'
                    })
                    .result.then((event: any) => {})
                    .catch(error => {});
                break;
            }
            case 'editMachineSpecifications': {
                this.modalService
                    .open(modal, {
                        size: 'md',
                        ariaLabelledBy: 'modal-basic-title'
                    })
                    .result.then((event: any) => {})
                    .catch(error => {});
                break;
            }
            default: {
                this.modalService
                    .open(modal, {
                        size: 'md',
                        ariaLabelledBy: 'modal-basic-title'
                    })
                    .result.then((event: any) => {})
                    .catch(error => {});
                break;
            }
        }
    }

    toggle() {
        this.btnPressed = true;
        this.isOpen = !this.isOpen;
        this.sleep(0.4).then(() => {
            this.btnPressed = false;
        });
    }

    sleep = (seconds: number) => {
        return new Promise(res => {
            setTimeout(res, seconds * 1000);
        });
    };

    sortPrograms = (a, b) => {
        return a.value.sort_weight > b.value.sort_weight ? 1 : -1;
    };

    getProductType(): string {
        const findKey = Object.keys(ProductType)[Object.values(ProductType).indexOf(this.device.product_type)];
        return findKey;
    }

    countAvailableForTech() {
        if (this.device.last_used < parseInt(moment().format('X'))) {
            return;
        }
        this.clickCounter++;
        if (this.clickCounter === 5) {
            // Release machine
            this.toast.info(this.translate.instant('device.releasing_machine'), this.translate.instant('misc.info'), { timeOut: 3000 });
            this.deviceService
                .releaseMachine(this.location_id, this.device.id, this.uid)
                .then(() => {
                    this.toast.success(this.translate.instant('device.machine_released'), this.translate.instant('misc.success'));
                })
                .catch(() => {
                    this.toast.error(this.translate.instant('device.machine_released_error'), this.translate.instant('misc.error'));
                });
        }

        if (this.resetClickCounterTimeout) {
            clearTimeout(this.resetClickCounterTimeout);
        }
        this.resetClickCounterTimeout = setTimeout(() => {
            this.clickCounter = 0;
        }, 5000);
    }

    protected readonly ProductType = ProductType;

    get brand() {
        return this.updateDeviceForm.get('machine_specifications.brand');
    }
    get model() {
        return this.updateDeviceForm.get('machine_specifications.model');
    }
    get serial_number() {
        return this.updateDeviceForm.get('machine_specifications.serial_number');
    }
    get production_year() {
        return this.updateDeviceForm.get('machine_specifications.production_year');
    }
    get note() {
        return this.updateDeviceForm.get('machine_specifications.note');
    }
    get guid() {
        return this.updateDeviceForm.get('guid');
    }
    get name() {
        return this.updateDeviceForm.get('name');
    }
    get price() {
        return this.updateDeviceForm.get('price');
    }
    get startup_time() {
        return this.updateDeviceForm.get('startup_time');
    }
    get pulse_duration() {
        return this.updateDeviceForm.get('pulse_duration');
    }
    get pulse_increment() {
        return this.updateDeviceForm.get('pulse_increment');
    }
    get pulse_number() {
        return this.updateDeviceForm.get('pulse_number');
    } // called "Repetition" in the ui
    get fixed_duration() {
        return this.updateDeviceForm.get('fixed_duration');
    }
    get ac_detect_mode() {
        return this.updateDeviceForm.get('ac_detect_mode');
    }
    get maintenance() {
        return this.updateDeviceForm.get('maintenance');
    }
    get coin_drop_installed() {
        return this.updateDeviceForm.get('coin_drop_installed');
    }
    get open_door() {
        return this.updateDeviceForm.get('open_door');
    }
}
