import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Action, ActionLog, actionState, actionType } from '../../../../../../shared_models/operator/actions';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { DashboardUser } from '../../../../../dashboard-models/dashboard-user';
import moment from 'moment/moment';
import { takeUntil } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { ActionsService } from '../../../../services/actions/actions.service';
import { HelperService } from 'src/app/services/helper/helper.service';
import { ActionCardService } from '../../../../services/actions/actionCard.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DateTimeService } from '../../../../services/date-time/date-time.service';
import { AuthService } from '../../../../services/auth/auth.service';
import * as Claims from 'shared_models/claims';
import { MachineServicesResetModalComponent } from '../../../device/machine-services-reset-modal/machine-services-reset-modal.component';
import { ActionsMobileModalComponent } from '../actions-mobile-modal/actions-mobile-modal.component';
import { RouterLink } from '@angular/router';
import { LoadingComponent } from '../../../loading/loading.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-actions-card',
    templateUrl: './actions-card.component.html',
    styleUrls: ['./actions-card.component.scss'],
    standalone: true,
    imports: [NgIf, LoadingComponent, RouterLink, NgFor, ActionsMobileModalComponent, MachineServicesResetModalComponent, AsyncPipe, TranslateModule]
})
export class ActionsCardComponent implements OnInit, OnDestroy {
    @Input() action: Action;
    @Input() isSmallerCard = false;
    @Input() isSkeleton: boolean;
    @Input() backendLoading = false;
    @Output() emitRemoveAction: EventEmitter<any> = new EventEmitter<any>();
    @Output() emitMoveActionToOpen: EventEmitter<any> = new EventEmitter<any>();
    @Output() emitReloadActions: EventEmitter<boolean> = new EventEmitter<boolean>();

    user: DashboardUser;
    toggleHistory = false;

    checkLoading = false;
    role$: Observable<Claims.Roles> = this.authService.getRole;

    latestLogValue: ActionLog;
    modalLogKey: string;
    timestamp;
    reversedLogKeys: string[];
    reversedActiveLogKeys: string[];

    showAdviceAction = true;
    showAlertAction = true;

    initLoading = true;
    isMobile = false;

    private ngUnsubscribe = new Subject<void>();

    constructor(
        public authService: AuthService, // used in html
        private translate: TranslateService,
        private actionService: ActionsService,
        private helperService: HelperService,
        private actionCardService: ActionCardService,
        private breakpointObserver: BreakpointObserver,
        public modalService: NgbModal,
        private dateTimeService: DateTimeService
    ) {
        actionService.selectedAdviceToggled$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
            this.showAdviceAction = value;
        });
        actionService.selectedAlertToggled$$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
            this.showAlertAction = value;
        });
        this.breakpointObserver.observe(['(max-width: 768px)']).subscribe((result: BreakpointState) => {
            this.isMobile = result.matches;
        });
    }

    ngOnInit(): void {
        this.initLoading = true;
        moment.locale(this.translate.currentLang);
        this.user = this.helperService.getUser();

        this.reversedLogKeys = this.getReversedLogKeys();
        if (this.action.active_actions) {
            this.reversedActiveLogKeys = this.getReversedActiveLogKeys();
        }

        this.latestLogValue = this.getLastestLogEntry();
        this.timestamp = this.getLocaleDate(this.latestLogValue.timestamp);
        this.initLoading = false;
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    getLocaleDate(timestamp: number): string {
        return this.dateTimeService.getDateAndTime(timestamp, false, true);
    }

    getLastestLogEntry(): ActionLog {
        const firstElementKey = Object.keys(this.action.log).reverse()[0];
        return this.action.log[firstElementKey];
    }

    getReversedLogKeys(): string[] {
        return Object.keys(this.action.log).reverse();
    }

    getReversedActiveLogKeys(): string[] {
        if (this.action.active_actions) {
            return Object.keys(this.action.active_actions).reverse();
        } else {
            return [];
        }
    }

    getState(actionLog: ActionLog): string {
        return this.translate.instant(`operator.actions.states.${actionLog.state}`);
    }

    getTitle(actionLog: ActionLog, isForLog: boolean): string {
        return this.actionCardService.getTitle(actionLog, isForLog, this.isSkeleton, this.action);
    }

    getDescription(actionLog: ActionLog, action: Action): string {
        return this.actionCardService.getDescription(actionLog, action);
    }

    async handleResetServiceEvent(event) {
        const isClosingBoth: boolean = event;
        if (isClosingBoth) {
            const keys: string[] = [];
            for (const actionLogKey in this.action.active_actions) {
                if (this.action.active_actions[actionLogKey] === actionType.machineMaintenanceDue || this.action.active_actions[actionLogKey] === actionType.outOfSoap) {
                    keys.push(actionLogKey);
                }
            }
            await this.checkAction(keys);
        } else {
            await this.checkAction([this.modalLogKey]);
        }
    }

    async handleResetMultipleServiceEvent(event) {
        const isClosingBoth: boolean = event;
        if (isClosingBoth) {
            const keys: string[] = [];
            for (const actionLogKey in this.action.active_actions) {
                if (this.action.active_actions[actionLogKey] === actionType.machineMaintenanceDue || this.action.active_actions[actionLogKey] === actionType.outOfSoap) {
                    keys.push(actionLogKey);
                }
            }
            await this.closeMultipleActions(keys);
        } else {
            await this.closeMultipleActions([this.modalLogKey]);
        }
    }

    async checkAction(actionLogKeys: string[]): Promise<void> {
        this.checkLoading = true;
        await this.actionService
            .checkAction(this.action, actionLogKeys)
            .then(response => {
                this.action = response;
                this.latestLogValue = this.getLastestLogEntry();
                this.reversedLogKeys = this.getReversedLogKeys();
                this.reversedActiveLogKeys = this.getReversedActiveLogKeys();
                this.emitRemoveAction.emit({ action: this.action });
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                this.checkLoading = false;
            });
    }

    createLogKey(log: ActionLog): string {
        return `${log.timestamp}_${log.action_type}${log.action?.product_type ? '_' + log.action.product_type : ''}`;
    }

    async unCheckAction(actionLogKey: string) {
        await this.actionService
            .uncheckAction(this.action, actionLogKey)
            .then(response => {
                const newLogKey: string = this.createLogKey(response);
                if (!this.reversedActiveLogKeys) {
                    this.reversedActiveLogKeys = [];
                }
                this.reversedActiveLogKeys = [newLogKey, ...this.reversedActiveLogKeys];
                this.action.log[newLogKey] = response;
                this.reversedLogKeys = [newLogKey, ...this.reversedLogKeys];
                this.emitMoveActionToOpen.emit({ log: response, logKey: newLogKey, actionKey: this.action.key });
            })
            .catch(err => {
                console.error(err);
            });
    }

    toggleHistoryExpand() {
        this.toggleHistory = !this.toggleHistory;
    }

    getPath(customerId: string, locationId?: string, deviceId?: string): string {
        return `${customerId === this.user.uid ? '' : `/customers/${customerId.split('_operated_by_')[0]}`}/locations${locationId ? `/${locationId}` : ''}/${deviceId ? `/devices/${deviceId}` : ''}`;
    }

    getStringForCustomerOrMyAccount(customerName): string {
        if (!this.authService.getOperatorStatus()) {
            return '';
        } else {
            if (customerName === 'my_account') {
                return this.translate.instant('operator.contract_modal.my_account');
            }
        }
        return customerName;
    }

    async handleMobileMultipleReloadRequest(event) {
        const logKey = event.logKey;
        await this.closeMultipleActions([logKey]);
    }

    async handleMobileSingleReloadRequest(event) {
        const logKey = event.logKey;
        await this.checkAction(logKey);
    }

    async closeMultipleActions(logKey: string[]) {
        this.checkLoading = true;
        this.toggleHistory = false;
        await this.actionService.checkMultipleActions(this.action, logKey).then(() => {
            this.checkLoading = false;
            this.emitReloadActions.emit(true);
        });
    }

    async removeAction(logKey: string) {
        this.toggleHistory = false;
        const actionLog: ActionLog = this.action.log[logKey];
        const actionIndex: number = this.reversedActiveLogKeys.indexOf(logKey);
        this.reversedActiveLogKeys.splice(actionIndex, 1);
        await this.checkAction([logKey]);
    }

    async moveActionBackToOpen(actionLog: ActionLog) {
        this.checkLoading = true;
        this.toggleHistory = false;
        const logKey: string = this.createLogKey(actionLog);
        await this.unCheckAction(logKey).then(() => {
            this.checkLoading = false;
        });
    }

    async moveMobileActionBackToOpen(event) {
        const log = event.actionLog;
        await this.moveActionBackToOpen(log);
    }

    async removeMobileAction(event) {
        const logKey: string = event.logKey;
        await this.removeAction(logKey);
    }

    checkIfActionIsSoapOrMaintenance(logKey) {
        return (
            (this.action.log[logKey].action_type === actionType.machineMaintenanceDue && this.action.log[logKey].state !== actionState.complete) ||
            (this.action.log[logKey].action_type === actionType.outOfSoap && this.action.log[logKey].state !== actionState.complete)
        );
    }

    openModal(modal: any, logKey?: string) {
        //Responsible for passing the contract to contract-form-modal
        if (logKey) {
            this.modalLogKey = logKey;
        }
        const modalOptions: NgbModalOptions = {
            ariaLabelledBy: 'modal-basic-title',
            size: 'lg'
        };
        const modalRef: NgbModalRef = this.modalService.open(modal, modalOptions);

        modalRef.result.then(
            () => {
                // on close
            },
            () => {
                // on error/dismiss
                // to remove the selected order and also reset the forced styling
            }
        );
    }
    protected readonly actionType = actionType;
    protected readonly actionState = actionState;
}
