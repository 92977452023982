<div class="checkbox-wrapper">
    <p *ngIf="text && textPosition === 'before'" class="text-before input-description">{{ text }}</p>
    <button
        class="checkbox-button"
        (click)="toggleCheckbox()"
        [disabled]="disabled"
        [ngClass]="{ active: checkbox }"
        [ngStyle]="checkbox ? { background: 'linear-gradient(180deg, ' + checkedBackgroundColor1 + ' 0%, ' + checkedBackgroundColor2 + ' 100%)' } : {}"
    >
        <img *ngIf="checkbox && !partial" src="assets/icons/checkmark-white.svg" alt="Checkbox" />
        <img *ngIf="checkbox && partial" src="assets/icons/exclusion.svg" alt="Checkbox" />
    </button>
    <p *ngIf="text && textPosition === 'after'" class="{{ noTextFormatting ? 'standard-text' : '' }} text-after input-description">{{ text }}</p>
</div>
