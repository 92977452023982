import { Component, OnDestroy, OnInit } from '@angular/core';
import { ContractService } from '../../../../services/contract/contract.service';
import { Contract, DetailedContract, sortParams, ContractLocation, ContractResponse } from '../../../../../../shared_models/operator/contracts';
import { HelperService } from '../../../../services/helper/helper.service';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { OperatorService } from '../../../../services/operator/operator.service';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { Subject, Subscription, Observable } from 'rxjs';
import { DashboardUser } from '../../../../../dashboard-models/dashboard-user';
import { SnapshotAction } from '@angular/fire/compat/database';
import hash from 'object-hash';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../../../../services/auth/auth.service';
import * as Claims from 'shared_models/claims';
import { PaginatePipe } from '../../../../pipe/paginate.pipe';
import { SpecificContractComponent } from '../specific-contract/specific-contract.component';
import { ContractFormComponent } from '../contract-form/contract-form.component';
import { LoadingComponent } from '../../../loading/loading.component';
import { NgIf, NgFor, AsyncPipe, KeyValuePipe } from '@angular/common';
import { DateTimeService } from '@services/date-time/date-time.service';

@Component({
    selector: 'app-contract-overview',
    templateUrl: './contract-overview.component.html',
    styleUrls: ['./contract-overview.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, LoadingComponent, MatPaginator, ContractFormComponent, SpecificContractComponent, AsyncPipe, KeyValuePipe, TranslateModule, PaginatePipe]
})
export class ContractOverviewComponent implements OnInit, OnDestroy {
    isMobile: boolean;
    role$: Observable<Claims.Roles> = this.authService.getRole;

    user: DashboardUser;
    loading = true;
    toggleExpand = false;
    contracts: DetailedContract[] = [];
    skeletonContracts: DetailedContract[];

    //pagination
    pageSize = 10;
    pageNumber = 0;
    pageSizeOptions = [10, 50, 100];
    sortBy: sortParams = sortParams.location;
    sortingAccending = true;
    fromDate: number;
    toDate: number;
    openedContracts: number[] = [];
    contractToBeEdited: DetailedContract;
    initLoading = true;
    getContractsFingerprint: string;
    uidUsage: 'default' | 'controlledDefault' | 'operator' | 'controlledOperator';

    private ngUnsubscribe = new Subject<void>();

    constructor(
        public authService: AuthService, // used in html
        private contractService: ContractService,
        private helperService: HelperService,
        public modalService: NgbModal,
        private operatorService: OperatorService,
        private activatedRoute: ActivatedRoute,
        private breakpointObserver: BreakpointObserver,
        public translate: TranslateService,
        private dateTimeService: DateTimeService
    ) {
        this.breakpointObserver.observe(['(max-width: 768px)']).subscribe((result: BreakpointState) => {
            this.isMobile = result.matches;
        });
        operatorService.selectedFrom$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
            this.fromDate = value;
            if (!this.initLoading) {
                this.getContracts();
            }
        });
        operatorService.selectedTo$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
            this.toDate = value;
            if (!this.initLoading) {
                this.getContracts();
            }
        });
        operatorService.selectedNewContract$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
            if (value) {
                if (!this.initLoading) {
                    this.contracts.push(value);
                    this.skeletonContracts.push({
                        customer_name: value.customer_name,
                        contract_id: value.contract_id,
                        customer_id: value.customer_id,
                        summed_revenue: null,
                        contract_key: value.contract_key,
                        performance: null,
                        revenue_target: null,
                        start_date: value.start_date,
                        end_date: value.end_date,
                        timespan: value.timespan,
                        locations: value.locations,
                        billable_amount: null,
                        billable: value.billable,
                        summed_users: 0,
                        avg_adoption: null,
                        summed_starts_day: 0,
                        currency: value.currency,
                        location_names: value.location_names,
                        org_revenue_target: value.revenue_target
                    });
                    this.sort(this.sortBy, true);
                }
            }
        });
    }

    async ngOnInit(): Promise<void> {
        moment.locale(this.translate.currentLang);
        this.user = this.helperService.getUser();
        await this.getContracts();
        this.initLoading = false;
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    isExpiring(timestamp: number): string {
        if (moment().add(6, 'month').unix() > timestamp) {
            return 'progressbar-inner-red';
        } else if (moment().add(12, 'month').unix() > timestamp) {
            return 'progressbar-inner-orange';
        } else {
            return 'progressbar-inner-green';
        }
    }

    calculateDateProgress(start_date: number, end_date: number): number {
        const timespan = end_date - start_date;
        const today = moment().unix();
        const progress = (1 - (end_date - today) / timespan) * 100;
        return progress > 100 ? 100 : progress < 3 ? 5 : progress;
    }

    async getContracts() {
        this.handleSkeletonView();
        const params = this.createParams();
        await this.contractService.getContracts(params).then((response: ContractResponse) => {
            if (this.getContractsFingerprint === response.info.fingerprint) {
                this.loading = false;
                if (!(this.sortBy === sortParams.contract || this.sortBy === sortParams.location)) this.openedContracts = [];
                this.contracts = response.data;
            }
        });
        this.sort(this.sortBy, true);
    }

    handleSkeletonView() {
        if (this.sortBy === sortParams.contract || this.sortBy === sortParams.location) {
            if (!this.skeletonContracts) {
                this.getSkeletonContracts();
            } else {
                this.contracts = this.skeletonContracts.slice();
                this.sort(this.sortBy, true);
            }
        }
    }

    createParams() {
        let params = `fromDate=${this.fromDate}&toDate=${this.toDate}`;
        this.getContractsFingerprint = hash.keys(`${params}${moment()}`);
        params += `&fingerprint=${this.getContractsFingerprint}`;
        return params;
    }

    getSkeletonContracts() {
        const contractSub: Subscription = this.operatorService
            .readContracts(this.user.uid)
            .snapshotChanges()
            .subscribe((contractsSnap: SnapshotAction<Record<string, Contract>>) => {
                contractSub.unsubscribe();
                const simpleContracts: Record<string, Contract> = contractsSnap.payload.val();
                for (const simpleCon in simpleContracts) {
                    const con: Contract = simpleContracts[simpleCon];
                    const locationsNamesSub: Subscription = this.operatorService
                        .readLocationName(con.customer_id, Object.keys(con.locations)[0])
                        .snapshotChanges()
                        .subscribe((locationNameSnap: SnapshotAction<string>) => {
                            locationsNamesSub.unsubscribe();
                            const location_names: string[] = [];
                            const conLocations: Record<string, ContractLocation> = {};
                            for (const loc in con.locations) {
                                location_names.push('-');
                                conLocations[loc] = {
                                    name: '-',
                                    users: 0,
                                    adoption: 0,
                                    starts_day: 0,
                                    revenue: 0
                                };
                            }
                            location_names[0] = locationNameSnap.payload.val();
                            const _con: DetailedContract = {
                                customer_name: con.customer_name,
                                contract_id: con.contract_id,
                                customer_id: con.customer_id,
                                summed_revenue: null,
                                contract_key: con.contract_key,
                                performance: null,
                                revenue_target: null,
                                start_date: con.start_date,
                                end_date: con.end_date,
                                timespan: con.timespan,
                                locations: conLocations,
                                billable_amount: null,
                                billable: con.billable,
                                summed_users: 0,
                                avg_adoption: null,
                                summed_starts_day: 0,
                                currency: con.currency,
                                location_names: location_names,
                                org_revenue_target: con.revenue_target
                            };

                            this.contracts.push(_con);
                            this.sort(this.sortBy, true);
                            this.loading = false;
                            const id = this.activatedRoute.snapshot.queryParams.id;
                            if (id) {
                                this.jumpToContract(id);
                            }
                            this.skeletonContracts = this.contracts.slice();
                        });
                }
            });
    }

    jumpToContract(contract_key: string) {
        const index = this.contracts.findIndex(contract => contract.contract_key === contract_key);
        this.pageNumber = Math.floor(index / this.pageSize);
        this.openedContracts = [index % this.pageSize];
    }

    toggleContractExpand(index) {
        if (this.openedContracts.includes(index)) {
            const idx = this.openedContracts.indexOf(index);
            this.openedContracts.splice(idx, 1);
        } else {
            this.openedContracts.push(index);
        }
        this.toggleExpand = !this.toggleExpand;
    }

    checkIfExpanded(index) {
        return this.openedContracts.includes(index);
    }

    formatCurrency(value: number | null, currency: string) {
        if (isNaN(value)) {
            return '-';
        }
        return this.helperService.localizeNumberWithCurrency(value ? value / 100 : 0, 2, 2, currency);
    }

    openModal(modal: any, contract: any) {
        //Responsible for passing the contract to contract-form-modal
        this.contractToBeEdited = contract;
        const modalOptions: NgbModalOptions = {
            ariaLabelledBy: 'modal-basic-title',
            size: 'lg'
        };
        const modalRef: NgbModalRef = this.modalService.open(modal, modalOptions);

        modalRef.result.then(
            () => {
                // on close
            },
            () => {
                // on error/dismiss
                // to remove the selected order and also reset the forced styling
            }
        );
    }

    handlePage(e: PageEvent) {
        this.openedContracts = [];
        this.pageSize = e.pageSize;
        this.pageNumber = e.pageIndex;
    }

    roundPerform(num: number | null): string {
        return num ? String(`${num < 0 ? '' : '+'}${this.helperService.roundToTwoDecimals(num * 100)}%`) : '';
    }
    round(num: number | null): string {
        return num ? String(`${this.helperService.roundToTwoDecimals(num * 100)}%`) : '0.00%';
    }

    sort(sortBy: string, skipFlip?: boolean): void {
        if (!skipFlip) {
            this.sortingAccending = sortBy === this.sortBy ? !this.sortingAccending : false;
            this.openedContracts = [];
        }
        this.sortBy = sortBy as sortParams;
        function propCompare(prop: sortParams, ascending: boolean) {
            if (prop !== sortParams.location) {
                return function compare(a, b) {
                    a[prop] === '-' ? (a[prop] = 0) : null;
                    b[prop] === '-' ? (b[prop] = 0) : null;
                    if (typeof a[prop] === 'string') {
                        return ascending ? a[prop].localeCompare(b[prop], 'en', { numeric: true, sensitivity: 'base' }) : b[prop].localeCompare(a[prop], 'en', { numeric: true, sensitivity: 'base' });
                    }
                    return ascending ? a[prop] - b[prop] : b[prop] - a[prop];
                };
            } else if (prop === sortParams.location) {
                return function compare(a, b) {
                    a[prop] === '-' ? (a[prop] = 0) : null;
                    b[prop] === '-' ? (b[prop] = 0) : null;
                    return ascending ? a[prop][0].localeCompare(b[prop][0], 'en', { numeric: true, sensitivity: 'base' }) : b[prop][0].localeCompare(a[prop][0], 'en', { numeric: true, sensitivity: 'base' });
                };
            }
        }
        this.contracts = this.contracts.slice().sort(propCompare(this.sortBy, this.sortingAccending));
    }

    deleteContract(contract_key: string) {
        this.openedContracts = [];
        const index: number = this.contracts.findIndex(contract => contract.contract_key === contract_key);
        this.contracts.splice(index, 1);
        this.contracts = this.contracts.slice();
        this.skeletonContracts.splice(index, 1);
    }

    updateContract(contract: any) {
        const index = this.contracts.findIndex(_contract => _contract.contract_key === contract.contract_key);
        this.contracts[index] = contract;
        this.sort(this.sortBy, true);
        this.jumpToContract(contract.contract_key);
        this.modalService.dismissAll();
    }

    getLocalTime(timestamp: number): string {
        return this.dateTimeService.getDateAndTime(timestamp, true, true);
    }
}
