import { Injectable } from '@angular/core';
import { AngularFireDatabase, SnapshotAction } from '@angular/fire/compat/database';
import { Crisp } from 'crisp-sdk-web';
import { DashboardUser } from '@dashboard_models/dashboard-user';
import { HelperService } from '../helper/helper.service';
import { take } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';
import { ShallowAccountDetails } from '@shared_models/billing/shallow-account-details';

interface CrispDataObj {
    business_type?: string;
    account_id?: string;
    customer_uid?: string;
    stripe_link?: string;
    db_link?: string;
    [key: string]: any;
}

@Injectable({ providedIn: 'root' })
export class ChatbotService {
    user: DashboardUser;
    constructor(
        private helperService: HelperService,
        private db?: AngularFireDatabase
    ) {}
    async setupChatBot(): Promise<void> {
        this.user = this.helperService.getUser();
        if (!this.user) {
            return;
        }

        const snap: SnapshotAction<ShallowAccountDetails> = (await lastValueFrom(this.db.object(`customers/${this.user.uid}/billing/shallow_account_details`).snapshotChanges().pipe(take(1)))) as SnapshotAction<ShallowAccountDetails>;
        const _obj: CrispDataObj = {};
        if (snap.payload.exists() && snap.payload.val() !== null) {
            const data: ShallowAccountDetails = snap.payload.val() as ShallowAccountDetails;
            if (data.name && typeof data.name === 'string') {
                Crisp.user.setNickname(data.name);
            }
            _obj.business_type = data.business_type;
            _obj.account_id = data.account_id;
            _obj.customer_uid = this.user.uid;
            _obj.stripe_link = `https://dashboard.stripe.com/connect/accounts/${data.account_id}/activity`;
            _obj.db_link = `https://console.firebase.google.com/u/0/project/airwallet-685ff/database/airwallet-685ff/data/~2Fcustomers~2F${this.user.uid}`;
            Crisp.session.setData(_obj);
        } else {
            Crisp.session.setData({ customer_uid: this.user.uid });
        }
        Crisp.user.setEmail(this.user.email);
    }
}
