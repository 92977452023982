import Stripe from 'stripe';
import { EmailGroups } from './invited-user';
import { BusinessType, StripeRegions } from './stripe';
import { CountryCode } from './external_accounts';

export interface Details {
    stripe_region: StripeRegions; // Always use stripe_region from custom claims. NOTE: Should never be changed! Usage examples: for fetching prices in billing page from life_subscriptions/ path
    accounting_emails?: string;
    account_id: string;
    email: string;
    account_number?: string;
    routing_number?: string;
    account_holder_name?: string;
    email_groups: Record<EmailGroups, boolean>;
    agreement_type: 'regular' | 'life';
    business_type: BusinessType;
    default_currency: string;
    onboarding_state: OnboardingState;
    persons?: Record<string, Stripe.Person>; // Stripe persons object
    verify_email?: number; // timestamp that is set when account is created. It is removed once verified (by frontend, overview page init code)
    support_url?: string;
    vat_number_details?: VatDetails; // is set by  set by Stripe webhook customer.tax_id event create and update. Can be null, because if vat_number_not_provided is set, it will remove this object
    vat_number_not_provided?: true | null; // (can be null, because we wanna remove it if not set) checkbox on account page controls this. If this is set, it will always apply 25% VAT and there will be no VAT number on invoices. This prop should be removed when VAT number is provided.
    country: string; // TODO: should this be optional??
    tos: TermsOfService;
    enterprise_config?: EnterpriseConfig;
}

export interface EnterpriseConfig {
    /*
    auto_subscribe_units comment:
    if true (set manually) all devices and terminals are created with prop
    subscribed: true and an invoice of the last month created units will be created with a scheduler
    the first night in the month (looking back on previous month created units)
    */
    auto_subscribe_units: boolean; // default is false
    /*
    trial_period_months comment:
    This is deciding for how long a trial period should be for devices created by the operator
    Can be undefined if no trial agreement is made with the customer
    */
    trial_period_months?: number;
}

export interface VatDetails {
    country: string; // pulled from shallow details address in billing (saved on account create)
    created: number; // unix for when it was created on the stripe customer
    type: any; // can be one of these enum values https://stripe.com/docs/api/tax_ids/customer_create#create_customer_tax_id-type - read docs here: https://stripe.com/docs/billing/customer/tax-ids#supported-tax-id
    value: string; // is the VAT number https://stripe.com/docs/api/tax_ids/customer_create#create_customer_tax_id-value
    verification: {
        status: 'pre_pending' | 'pending' | 'verified' | 'failed';
        verified_address?: string;
        verified_name?: string;
    };
}

export interface CreateNewCustomer {
    email: string;
    password: string;
    businessType: BusinessType;
    country: CountryCode;
    anonymousUid?: string;
    tos: TermsOfService;
}

export interface OnboardingState {
    // onboarding state is considered "completed" if { external_account: true, capabilities: 'active', billing: true }
    external_account: boolean; // from Stripe semantics, this is a bank account that payouts can be sent to.
    capabilities: 'active' | 'inactive' | 'pending' | 'missing_document'; // the different states that capabilities can have comes from account object in Stripe: https://stripe.com/docs/api/accounts/object#account_object-capabilities
    billing: boolean;
    account_state: AccountState;
}

export interface TermsOfService {
    accepted_terms_and_conditions: boolean;
    accepted_gdpr: boolean;
}

export enum AccountState {
    complete = 'complete',
    restricted_soon = 'restricted_soon',
    restricted = 'restricted',
    pending = 'pending',
    invited = 'invited',
    seen = 'seen'
}

export interface Persons {
    representative: {
        first_name: string;
        last_name: string;
        title: string;
    };
    verified: boolean;
}

export type TaxIdObject = {
    country: string;
    tax_id: any;
    description: string;
    example: string;
};
