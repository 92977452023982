import { Component, OnInit, ViewChild, WritableSignal } from '@angular/core';
import { QRCodeModule } from 'angularx-qrcode';
import { getAuth, multiFactor, TotpMultiFactorGenerator, TotpSecret, User } from 'firebase/auth';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AwSwitchComponent } from '../../../misc/aw-switch/aw-switch.component';
import { AuthService } from '../../../../services/auth/auth.service';
import { CustomModalComponent } from '@components/misc/custom-modal/custom-modal.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AwOtpInputComponent } from '@components/misc/aw-otp-input/aw-otp-input.component';
import { HelperService } from '@services/helper/helper.service';
import { LoadingComponent } from '@components/loading/loading.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-multifactor-qr-enabler',
    standalone: true,
    imports: [QRCodeModule, AwSwitchComponent, CustomModalComponent, TranslateModule, AwOtpInputComponent, LoadingComponent, NgIf],
    templateUrl: './multifactor-qr-enabler.component.html',
    styleUrl: './multifactor-qr-enabler.component.scss'
})
export class MultifactorQrEnablerComponent implements OnInit {
    totpSecret: TotpSecret;
    totpUri: string;
    @ViewChild('mfaModal') mfaModal: CustomModalComponent;

    user: User;

    hasMfaEnabled: WritableSignal<boolean> = this.authService.hasMfaEnabled;
    mfaSuccessful = false;
    otpCode: string;
    loadingMFAEnabling = false;
    mfaLoading = false;

    constructor(
        private modalService: NgbModal,
        private authService: AuthService,
        private helperService: HelperService,
        private translate: TranslateService
    ) {}

    async ngOnInit() {
        const auth = getAuth();
        this.user = auth.currentUser;
        await this.authService.checkMfaStatus();
    }

    async setupMfa() {
        console.log('calling setupmfa');
        if (this.user) {
            try {
                const multiFactorSession = await multiFactor(this.user).getSession();
                this.totpSecret = await TotpMultiFactorGenerator.generateSecret(multiFactorSession);
                this.totpUri = this.totpSecret.generateQrCodeUrl(this.user.email, 'Airwallet');
                this.openModal(this.mfaModal);
            } catch (err: any) {
                console.log('Error setting up MFA:', err.code);
                if (err.code === 'auth/requires-recent-login' || err.code === 'auth/user-token-expired') {
                    try {
                        const text: string = this.translate.instant('account.mfa.reauthenticate_for_enable_mfa');
                        await this.authService.handleReauthentication(text).then(() => {
                            this.user = getAuth().currentUser;
                            this.authService.hasMfaEnabled.set(true);
                        });
                        return await this.setupMfa(); // Retry after re-authentication
                    } catch (reAuthErr) {
                        console.error('Re-authentication failed:', reAuthErr);
                        this.resetMfa();
                    }
                } else if (err.code === 'auth/unverified-email') {
                    this.helperService.verifyEmailToast(this.user.email);
                    this.resetMfa();
                } else {
                    console.error('Error setting up MFA:', err);
                }
            }
        } else {
            console.error('No user is currently signed in.');
        }
    }

    async toggleMFA() {
        this.hasMfaEnabled.set(!this.hasMfaEnabled());
        this.mfaLoading = true;
        if (this.hasMfaEnabled()) {
            await this.setupMfa();
        } else {
            await this.disableMfa();
        }
        this.mfaLoading = false;
    }

    resetMfa() {
        this.totpSecret = null;
        this.totpUri = null;
        this.hasMfaEnabled.set(false);
    }

    async disableMfa() {
        console.log('Disabling MFA');
        try {
            if (this.user) {
                const mfaInfo = multiFactor(this.user).enrolledFactors;
                const totpEnrollment = mfaInfo.find(factor => factor.factorId === 'totp');

                if (totpEnrollment) {
                    await multiFactor(this.user).unenroll(totpEnrollment.uid);
                    this.helperService.defaultHtmlToast('', this.translate.instant('account.mfa.mfa_disabled'), 'Success');
                    this.resetMfa();
                    console.log('TOTP MFA disabled successfully');
                } else {
                    console.log('No TOTP MFA enrollment found');
                }
            } else {
                console.error('No user is currently signed in.');
            }
        } catch (error) {
            try {
                if (error.code === 'auth/requires-recent-login' || error.code === 'auth/user-token-expired') {
                    const text = this.translate.instant('account.mfa.reauthenticate_for_disable_mfa');
                    await this.authService.handleReauthentication(text).then(() => {
                        this.user = getAuth().currentUser;
                    });
                    return await this.disableMfa();
                } else {
                    console.error('Error disabling MFA:', error);
                }
            } catch (reAuthErr) {
                console.error('Re-authentication failed:', reAuthErr);
            }
        }
    }

    async catchOtpEvent(event: string) {
        this.otpCode = event;
        await this.registerMFA();
    }

    async registerMFA() {
        this.loadingMFAEnabling = true;

        if (this.otpCode.length !== 6) return;

        // Finalize the enrollment.
        const multiFactorAssertion = TotpMultiFactorGenerator.assertionForEnrollment(this.totpSecret, this.otpCode);
        await multiFactor(this.user)
            .enroll(multiFactorAssertion, 'TOTP')
            .then(() => {
                this.helperService.defaultHtmlToast('', this.translate.instant('account.mfa.mfa_enabled'), 'Success');
                this.mfaSuccessful = true;
                this.modalService.dismissAll();
            })
            .catch((error: any) => {
                if (error.code === 'auth/invalid-verification-code') {
                    this.helperService.defaultHtmlToast('', this.translate.instant('account.mfa.invalid_mfa'), 'Error');
                } else {
                    console.error('Error enabling MFA:', error);
                    this.helperService.defaultHtmlToast('', 'Something went wrong trying to enable Two-Factor Authentication.', 'Error');
                }
            })
            .finally(() => {
                this.loadingMFAEnabling = false;
            });
    }

    openModal(modal: any) {
        const modalOptions: NgbModalOptions = {
            ariaLabelledBy: 'modal-basic-title'
        };
        const modalRef: NgbModalRef = this.modalService.open(modal, modalOptions);

        modalRef.result.then(
            () => {
                // on close
            },
            () => {
                if (!this.mfaSuccessful) {
                    this.hasMfaEnabled.set(false);
                }
                this.mfaSuccessful = false;
            }
        );
    }
}
