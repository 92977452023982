<div>
    <button
        class="settings-button"
        (click)="togglePopUp($event)"
        [disabled]="isButtonDisabled || isLoading || isDisabled"
        [className]="openPopupBoxIndex && !isButtonDisabled ? 'active settings-button' : 'settings-button'"
    >
        <svg *ngIf="!isLoading" width="21.376" height="3.584" viewBox="0 0 21.376 3.584">
            <defs>
                <clipPath id="clip-path">
                    <rect id="Rectangle_735" data-name="Rectangle 735" width="21.376" height="3.584" transform="translate(0 0)" fill="none" />
                </clipPath>
            </defs>
            <g id="Group_525" data-name="Group 525" transform="translate(0 0)">
                <g id="Group_524" data-name="Group 524" clip-path="url(#clip-path)">
                    <path
                        id="Path_1023"
                        data-name="Path 1023"
                        d="M.5,3.072A1.764,1.764,0,0,1,0,1.792,1.754,1.754,0,0,1,.5.5a1.756,1.756,0,0,1,1.3-.5,1.769,1.769,0,0,1,1.28.5,1.727,1.727,0,0,1,.512,1.3A1.75,1.75,0,0,1,1.792,3.584,1.722,1.722,0,0,1,.5,3.072"
                    />
                    <path
                        id="Path_1024"
                        data-name="Path 1024"
                        d="M9.393,3.072a1.764,1.764,0,0,1-.5-1.28,1.754,1.754,0,0,1,.5-1.3,1.753,1.753,0,0,1,1.3-.5,1.766,1.766,0,0,1,1.279.5,1.724,1.724,0,0,1,.513,1.3,1.751,1.751,0,0,1-1.792,1.792,1.719,1.719,0,0,1-1.3-.512"
                    />
                    <path
                        id="Path_1025"
                        data-name="Path 1025"
                        d="M18.288,3.072a1.764,1.764,0,0,1-.5-1.28,1.754,1.754,0,0,1,.5-1.3,1.92,1.92,0,0,1,2.576,0,1.727,1.727,0,0,1,.512,1.3,1.75,1.75,0,0,1-1.792,1.792,1.722,1.722,0,0,1-1.3-.512"
                    />
                </g>
            </g>
        </svg>
        <loading-indicator *ngIf="isLoading" size="micro" color="secondary" />
    </button>
    <div #popupDiv class="popup" *ngIf="openPopupBoxIndex" [style]="'margin-left: ' + this.marginForLeftHover + 'px'">
        <div *ngFor="let option of menuOptions" (click)="emitClickedOption(option)">
            <p title="{{ option | translate }}">{{ option | translate }}</p>
        </div>
    </div>
</div>
