<div>
    <app-aw-switch [shouldBeChecked]="hasMfaEnabled()" [disableSwitch]="mfaLoading" (activateClick)="toggleMFA()"></app-aw-switch>
</div>

<ng-template #mfaModal let-modal>
    <app-custom-modal>
        <h4 modal-title>
            {{ "account.mfa.two_factor_capitalized" | translate }}
        </h4>
        <ng-container modal-body>
            <div class="qr-body">
                <p class="qr-title">{{ "account.mfa.scan_qr_code" | translate }}</p>
                <div class="qr-code">
                  <img src="../../../../assets/logo/airwallet_w_logo_white_border.svg" alt="Airwallet logo" class="qr-overlay-img">
                  <qrcode [qrdata]="totpUri" colorDark="#03045E" [margin]="2" [width]="280" [errorCorrectionLevel]="'L'"/>
                </div>
                <div class="alternate-text">
                    <p>{{ "account.mfa.scan_qr_code_alternative" | translate }}</p>
                    <p>{{ totpSecret.secretKey }}</p>
                </div>
            </div>
            <div class="otp-wrapper">
                <p>{{ "account.mfa.enter_passcode" | translate }}</p>
                <div class="otp-box-input">
                    <app-aw-otp-input (otpComplete)="catchOtpEvent($event)" />
                </div>
            </div>
        </ng-container>
        <ng-container modal-footer>
            <button class="btn btn-primary" style="width: 100%" (click)="registerMFA()">
                @if (loadingMFAEnabling) {
                    <loading-indicator [size]="'tiny'" [color]="'white'"></loading-indicator>
                } @else {
                    <span>{{ "account.mfa.register" | translate }}</span>
                }
            </button>
        </ng-container>
    </app-custom-modal>
</ng-template>
