import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';
import { StripeRegions } from 'shared_models/stripe';
import { HttpService, RequestTypes } from '@services/helper/http.service';
import { FilterSortParams } from '@shared_models/search-params/FilterSortParams';
import { BillingCard, BillingDetails, BillingPlan, BillingUnitQueryResult, CardRequireAction, ShallowBillingUnit } from '@shared_models/billing/billing';

@Injectable({ providedIn: 'root' })
export class BillingService {
    constructor(
        private db: AngularFireDatabase,
        private http: HttpService
    ) {}

    // * * * * * * * * * * * * * * *
    // * * * * FIREBASE SDK  * * * *
    // * * * * * * * * * * * * * * *
    readPrices(stripeRegion: StripeRegions, country: string): AngularFireObject<any> {
        // prices (from products) is stored in db from a Stripe webhook that stores on create and update.
        return this.db.object(`life_subscription_prices/${stripeRegion}/${country}`);
    }

    // * * * * * * * * * * * * * * *
    // * * * * * * HTTPS * * * * * *
    // * * * * * * * * * * * * * * *
    async getBillingUnits(params: FilterSortParams, control?: string): Promise<BillingUnitQueryResult> {
        return this.http.dynamicHttp(`api_billing/units`, RequestTypes.GET, { params: { params: JSON.stringify(params) }, control });
    }
    async getBillingUnitsForSubscriptionInit(params: FilterSortParams, options: { selectAll: boolean; selectedUnits: string[]; unselectedUnits: string[] }): Promise<ShallowBillingUnit[]> {
        return this.http.dynamicHttp(`api_billing/units/subscription`, RequestTypes.POST, { params: { params: JSON.stringify( params ) }, body: options });
    }
    async getCustomersAndLocationNames(control?: string): Promise<{ customers: { value: string; label: string }[]; subcustomersLocationNames: Record<string, { value: string; label: string }[]> }> {
        return this.http.dynamicHttp(`api_operator/all_names_for_filter`, RequestTypes.GET, { control });
    }
    async getCustomerDetails(): Promise<BillingDetails> {
        return this.http.dynamicHttp(`api_billing/customer_details`, RequestTypes.GET);
    }
    async createSubscription(plan: BillingPlan, serials: string[], reference: string, insurance: boolean): Promise<CardRequireAction> {
        return this.http.dynamicHttp(`api_billing/subscription`, RequestTypes.POST, { body: { plan, serials, reference, insurance } });
    }
    async cardSetupIntent(): Promise<string> {
        return this.http.dynamicHttp(`api_billing/card/setup_intent`, RequestTypes.GET);
    }
    async setCard(pmId: string): Promise<BillingCard> {
        return this.http.dynamicHttp(`api_billing/card/set`, RequestTypes.POST, { body: { pmId } });
    }
    async completeCardSubscription(pending3DSecureDocId: string): Promise<string> {
        return this.http.dynamicHttp(`api_billing/card/complete_3ds`, RequestTypes.POST, { body: { pending3DSecureDocId } });
    }
    async cancelSubscription(serial: string, cancel: boolean): Promise<string> {
        return this.http.dynamicHttp(`api_billing/unit/cancel`, RequestTypes.PUT, { params: { serial, cancel } });
    }
    async validateEan(eanNumber: string, fingerprint: string): Promise<{ isValid: boolean; fingerprint: string }> {
        return this.http.dynamicHttp(`api_billing/validate_ean`, RequestTypes.GET, { params: { eanNumber, fingerprint } });
    }
}
