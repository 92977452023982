import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { FilterOption, FilterType, SelectOption } from '../../../../../shared_models/aw-components/filterOption';
import dayjs from 'dayjs';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { AwDatepickerComponent } from '../aw-datepicker/aw-datepicker.component';
import { FormsModule } from '@angular/forms';
import { NgIf, NgFor, NgStyle } from '@angular/common';

@Component({
    selector: 'app-aw-filter-button',
    templateUrl: './aw-filter-button.component.html',
    styleUrls: ['./aw-filter-button.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, FormsModule, AwDatepickerComponent, NgSelectModule, TranslateModule, NgStyle]
})
export class AwFilterButtonComponent {
    isExpanded = false;
    openAccordions: number[] = [];

    @Input() filterOptions: FilterOption[] = [];
    @Input() popupAnchor: 'right' | 'left' = 'right';
    @Output() filterChanged = new EventEmitter<any>();
    @Output() emitDependencyChange = new EventEmitter<{ key: string; value: any }>();
    @Input() disabled: boolean;

    numberRangeKeys: SelectOption[] = [
        { value: 'BETWEEN', label: 'filter-button.between' },
        { value: 'EQUAL', label: 'filter-button.equal' },
        { value: 'GREATER', label: 'filter-button.greater_than' },
        { value: 'LESS', label: 'filter-button.less_than' }
    ];

    constructor(private elementRef: ElementRef) {}

    get numberOfFiltersChosen() {
        return this.filterOptions.filter(option => {
            if (option.hidden) {
                return false;
            }
            if (Array.isArray(option.value)) {
                return option.value.length > 0;
            }
            return option.value || option.value2;
        }).length;
    }

    toggleFilter(): void {
        this.isExpanded = !this.isExpanded;
        if (this.isExpanded) {
            this.openAccordions = [];
            this.filterOptions.forEach((option, index) => {
                if (option.value && option.value.length !== 0) {
                    this.openAccordions.push(index);
                }
            });
        }
    }

    @HostListener('document:click', ['$event'])
    clickOut(event: MouseEvent): void {
        if (this.isExpanded && !this.elementRef.nativeElement.contains(event.target)) {
            this.toggleFilter();
        }
    }

    onFilterOptionChange(option: FilterOption): void {
        // Needs a slight delay, because it sets value after emit is run otherwise.
        setTimeout(() => {
            if (option.hasDependencyOn) {
                // Reset the value of any filter options where their key matches the hasDependencyOn key
                this.resetDependentFilters(option.hasDependencyOn);

                // Emit the dependency change event
                this.emitDependencyChange.emit({ key: option.hasDependencyOn, value: option.value });
            }
        }, 0);
    }

    resetDependentFilters(dependentKey: string): void {
        this.filterOptions.forEach(filterOption => {
            if (filterOption.key === dependentKey) {
                filterOption.value = null;
                filterOption.value2 = null;
                if (filterOption.hasDependencyOn) {
                    this.resetDependentFilters(filterOption.hasDependencyOn);
                }
            }
        });
    }

    resetToDateIfHigher(option: FilterOption): void {
        if (option.value && option.value2 && option.value2 < option.value) {
            option.value2 = null;
        }
    }

    resetFromDateIfLower(option: FilterOption): void {
        if (option.value && option.value2 && option.value2 < option.value) {
            option.value = null;
        }
    }

    clearFiters(): void {
        this.filterOptions.forEach(option => {
            option.value = null;
            option.value2 = null;
        });
        this.submitFiltering();
        this.closeEveryAccordion();
    }

    openAccordion(index: number): void {
        if (this.openAccordions.includes(index)) {
            this.openAccordions = this.openAccordions.filter(item => item !== index);
        } else {
            this.openAccordions.push(index);
        }
    }

    /**
     * This method is used to create a filter object from the filter options.
     * Each filter option is an object with a key and a value. If a filter option has a value2,
     * it is added to the value as an array.
     * The resulting filter object is then emitted through the filterChanged event emitter.
     * Finally, all open accordions are closed.
     *
     * @example
     * // Given the following filter options:
     * this.filterOptions = [
     *   { key: 'date_range', value: '1712571732', value2: '1715170932' },
     *   { key: 'status', value: 'active' }
     * ];
     *
     * // Calling this method will result in the following filter object being emitted:
     * { date_range: ['1712571732', '1715170932'], status: 'active' }
     *
     * // And all open accordions will be closed.
     */
    submitFiltering(): void {
        const filter = this.filterOptions.reduce((acc, option) => {
            if (option.value || option.value2) {
                if (option.numberRangeKey) {
                    if (option.numberRangeKey === 'GREATER') {
                        acc[option.key] = [option.value, null];
                    } else if (option.numberRangeKey === 'LESS') {
                        acc[option.key] = [null, option.value];
                    } else if (option.numberRangeKey === 'BETWEEN') {
                        acc[option.key] = [option.value, option.value2];
                    } else {
                        acc[option.key] = [option.value, option.value];
                    }
                } else if (option.isDateRange) {
                    // If 'from' date is set and 'to' date is not set, set 'to' date to today
                    if (option.value && !option.value2) {
                        const today = dayjs().unix(); // Get today's date in Unix timestamp
                        acc[option.key] = [option.value, today];
                    }
                    // If 'to' date is set and 'from' date is not set, set 'from' date to 0
                    else if (!option.value && option.value2) {
                        acc[option.key] = [0, option.value2];
                    }
                    // If both 'from' and 'to' dates are set, add them to the filter as is
                    else if (option.value && option.value2) {
                        acc[option.key] = [option.value, option.value2];
                    }
                } else {
                    acc[option.key] = option.value;
                }
            }
            return acc;
        }, {});

        this.filterChanged.emit(filter);
        this.closeEveryAccordion();
    }

    closeEveryAccordion(): void {
        this.openAccordions = [];
        this.isExpanded = false;
    }

    protected readonly FilterType = FilterType;
}
