import { Component, OnInit, ViewChild, WritableSignal } from '@angular/core';
import { LoadingComponent } from '../loading/loading.component';
import { Router } from '@angular/router';
import { Location, NgIf } from '@angular/common';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { environment } from '../../../environments/environment';
import { MaintenanceService } from '@services/auth/maintenance.service';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { TranslateModule } from '@ngx-translate/core';
import { AuthPageService } from '@services/auth/auth-page.service';
import { animate, animateChild, group, query, state, style, transition, trigger } from '@angular/animations';
import { fade } from 'src/app/animations/fade'; // Import the fade animation
import { AuthService } from '@services/auth/auth.service';

@Component({
    selector: 'app-auth-page',
    standalone: true,
    imports: [LoadingComponent, SignInComponent, SignUpComponent, LottieComponent, NgIf, TranslateModule],
    templateUrl: './auth-page.component.html',
    styleUrl: './auth-page.component.scss',
    animations: [
        trigger('containerHeight', [
            state('sign-in', style({ height: '183px' })),
            state('mfa', style({ height: '125px' })),
            state('sign-up', style({ height: '430px' })),
            state('companyIndividual', style({ height: '525px' })),
            transition('sign-in <=> sign-up, sign-in <=> mfa, sign-up <=> companyIndividual, companyIndividual <=> sign-in', [
                group([
                    animate('500ms ease'), // Resizes container height
                    query('@fade', animateChild(), { optional: true }) // Animates opacity of inner view
                ])
            ])
        ]),
        fade
    ]
})
export class AuthPageComponent implements OnInit {
    @ViewChild(SignInComponent) signInComponent: SignInComponent;
    @ViewChild(SignUpComponent) signUpComponent: SignUpComponent;

    loading: WritableSignal<boolean>;
    isMfaSignIn: WritableSignal<boolean>;
    isCompanyIndividualSignUp: WritableSignal<boolean>;

    view: string;
    options: AnimationOptions = {
        // https://www.npmjs.com/package/ngx-lottie
        path: '../../assets/animations/AW_waves.json'
    };
    supportPhone: {
        phoneNumber: string;
        telLink: string;
    } = environment.supportPhone;
    version: string = environment.VERSION;
    showLoad = true;
    isUnderMaintenance = false;
    backAt: number | null = null;

    constructor(
        private router: Router,
        private location: Location,
        private maintenanceService: MaintenanceService,
        private authPageService: AuthPageService,
        private authService: AuthService
    ) {
        this.view = this.router.url.split('/').pop().split('?')[0];
        this.loading = this.authPageService.loading;
        this.isMfaSignIn = this.authPageService.mfaSignIn;
        this.isCompanyIndividualSignUp = this.authPageService.companyIndividualSignUp;
    }

    async ngOnInit() {
        this.maintenanceService.isUnderMaintenance$.subscribe((isUnderMaintenance: boolean) => {
            this.isUnderMaintenance = isUnderMaintenance;
        });

        // Subscribe to backAt timestamp
        this.maintenanceService.backAt$.subscribe((timestamp: number | null) => {
            this.backAt = timestamp;
        });

        this.showLoad = false;
    }

    getFormattedBackAt(): string {
        if (!this.backAt) {
            return '';
        }
        const date = new Date(this.backAt * 1000);
        return date.toLocaleString(); // Adjust formatting as needed
    }

    getContainerState(): string {
        if (this.isMfaSignIn()) {
            return 'mfa';
        } else if (this.isCompanyIndividualSignUp()) {
            return 'companyIndividual';
        } else {
            return this.view;
        }
    }

    toggleAuthPage() {
        if (this.view === 'sign-in') {
            this.view = 'sign-up';
            this.location.replaceState('/sign-up');
        } else {
            this.view = 'sign-in';
            this.location.replaceState('/sign-in');
        }
    }

    async handleSubmit(): Promise<void> {
        if (this.isMfaSignIn()) {
            this.signInComponent.handleMfa();
        } else if (this.view === 'sign-in') {
            this.signInComponent.signIn();
        } else {
            await this.signUpComponent.signUp().then(async (created: boolean) => {
                if (created) {
                    await this.authService.signOut(true); // to secure that there are not two different users signed in, because it messes up the UI in the first opened window, and the navbar is also shown in this set-password page, which it not should be.
                    this.view = 'sign-in';
                }
            });
        }
    }
}
