import { Component, OnInit } from '@angular/core';
import { PageLayoutComponent } from '../misc/aw-page-layout/page-layout.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AwFilterButtonComponent } from '../misc/aw-filter-button/aw-filter-button.component';
import { NgForOf, NgIf, NgStyle } from '@angular/common';
import { AwTableComponent } from '../misc/aw-table/aw-table.component';
import { FilterOption, FilterType } from '../../../../shared_models/aw-components/filterOption';
import { FilterSortParams, Sort, TableSelection } from '../../../../shared_models/search-params/FilterSortParams';
import { TableHeaderOptions } from '../../../../shared_models/aw-components/tableHeaderOptions';
import { BreakpointObserver } from '@angular/cdk/layout';
import { PageEvent } from '@angular/material/paginator';
import { HelperService } from '../../services/helper/helper.service';
import { PaginatePipe } from '../../pipe/paginate.pipe';
import { DiscountService } from '../../services/discount/discount.service';
import { AwCheckboxComponent } from '../misc/aw-checkbox/aw-checkbox.component';
import { DiscountToolManageComponent } from './discount-tool-manage/discount-tool-manage.component';
import { AwHoverIconComponent } from '../misc/aw-hover-icon/aw-hover-icon.component';
import { DeleteModalService } from '../../services/delete-modal/delete-modal.service';
import { DeleteModalOptions } from '../../../../shared_models/deleteModalOptions';
import { BillingService } from '../../services/billing/billing.service';
import { NgbModal, NgbModalModule, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DiscountCreateModalComponent } from './discount-create-modal/discount-create-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingComponent } from '../loading/loading.component';
import { CustomToolTipComponent } from '../misc/custom-tool-tip/custom-tool-tip.component';
import { DisplayBillingUnit } from '@shared_models/billing/billing';

@Component({
    selector: 'app-discount-tool',
    standalone: true,
    imports: [
        PageLayoutComponent,
        TranslateModule,
        AwFilterButtonComponent,
        NgIf,
        AwTableComponent,
        PaginatePipe,
        NgForOf,
        AwCheckboxComponent,
        NgStyle,
        DiscountToolManageComponent,
        AwHoverIconComponent,
        DiscountCreateModalComponent,
        LoadingComponent,
        CustomToolTipComponent
    ],
    templateUrl: './discount-tool.component.html',
    styleUrl: './discount-tool.component.scss'
})
export class DiscountToolComponent implements OnInit {
    units: DisplayBillingUnit[] = [];
    globalDiscount: number = 0;
    isMobile = false;
    selectedUid: string;
    modalRef: NgbModalRef;
    tableSelection: TableSelection = {
        selectAll: false,
        selectedUnits: [],
        unselectedUnits: []
    };
    loadingDevices = false;
    initialLoading = true;
    pageSizeOptions: number[] = [15, 50, 250];
    params: FilterSortParams
    customersLocationsNames: Record<string, { value: string; label: string }[]> = {};
    customers: { value: string; label: string }[] = [];

    deleteModalOptions: DeleteModalOptions = {
        titleTranslationString: 'discount_tool.remove_discount',
        descriptionTranslateString: 'discount_tool.confirm_delete_text',
        buttonTranslationString: 'discount_tool.remove_discount'
    };
    tableHeaderOptions: TableHeaderOptions[] = [
        {
            sortKey: 'serial',
            title: this.translate.instant('device.serial_num'),
            width: '15%'
        },
        {
            sortKey: 'subscribed',
            title: this.translate.instant('locations.state'),
            width: '10%'
        },
        {
            sortKey: '',
            title: this.translate.instant('misc.customer'),
            width: '15%'
        },
        {
            sortKey: '',
            title: this.translate.instant('misc.location'),
            width: '15%'
        },
        {
            sortKey: 'lname',
            title: this.translate.instant('misc.device'),
            width: '15%'
        },
        {
            sortKey: '',
            title: this.translate.instant('billing.discount'),
            width: '15%',
            sortDirection: 'desc'
        },
        {
            sortKey: 'created',
            title: this.translate.instant('customers.created'),
            width: '10%',
            alignment: 'right',
            sortDirection: 'desc'
        },
        {
            sortKey: '',
            title: '',
            width: '5%',
            alignment: 'right'
        }
    ];
    filterOptions: FilterOption[] = [
        {
            key: 'customer_id',
            type: FilterType.SELECT,
            value: null,
            label: 'Customers',
            selectOptions: []
        },
        {
            key: 'state',
            type: FilterType.SELECT,
            value: null,
            label: 'Status',
            selectOptions: [
                {
                    value: 'active',
                    label: 'Active'
                },
                {
                    value: 'inactive',
                    label: 'Inactive'
                }
            ]
        },
        {
            key: 'location_key',
            type: FilterType.SELECT_MULTIPLE,
            value: null,
            label: 'Locations',
            selectOptions: []
        },
        {
            key: 'device_type',
            type: FilterType.SELECT_MULTIPLE,
            value: null,
            label: 'Device types',
            selectOptions: []
        },
        { key: 'created', type: FilterType.DATE_RANGE, value: null, isDateRange: true, label: 'customers.created' }
    ];

    constructor(
        private breakpoint: BreakpointObserver,
        public helperService: HelperService,
        protected translate: TranslateService,
        private deleteModalService: DeleteModalService,
        private modalService: NgbModal,
        private billingService: BillingService,
        private route: ActivatedRoute,
        private router: Router,
        private discountService: DiscountService
    ) {
        this.breakpoint.observe(['(max-width: 768px)']).subscribe(result => {
            this.isMobile = result.matches;
        });
    }

    async ngOnInit() {
        this.modalService.dismissAll();
        this.initParams();
        this.initialLoading = true;
        const uid = this.route.snapshot.queryParams['uid'];
        if (uid) {
            const customerFilter = this.filterOptions.find(option => option.key === 'customer_id');
            customerFilter.selectOptions = [{ value: uid, label: 'MyAccount' }];
            customerFilter.value = uid;
            this.params.filter.customer_id = uid;
            this.selectedUid = uid;
            await Promise.all([this.getCustomersAndLocationNames(), this.getGlobalDiscount()]);            
            await this.loadUnits();
        }
        this.reorderInactiveTableHeadersForMobile();
        this.initialLoading = false;
    }

    initParams() {
        this.tableSelection.selectAll = false;
        this.tableSelection.unselectedUnits = [];
        this.tableSelection.selectedUnits = [];
        this.params = {
            filter: {},
            sortBy: { key: 'created', order: 'desc' },
            pageSize: 15,
            pageNumber: 0,
            total: 0
        };
    }

    async selectUid(uid: string): Promise<void> {
        await this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { uid },
            queryParamsHandling: 'merge' // Merge with existing query params
        });
        this.ngOnInit();
    }

    async getGlobalDiscount(): Promise<void> {
        await this.discountService.getGlobalDiscount(this.selectedUid).then(data => {
            this.globalDiscount = data?.percentage ? data.percentage : 0;
        })
    }

    async loadUnits() {
        this.loadingDevices = true;
        await this.billingService
            .getBillingUnits(this.params, this.selectedUid)
            .then(data => {
                const tmpUnits: DisplayBillingUnit[] = [];
                data.units.forEach(unit => {
                    tmpUnits.push({
                        ...unit,
                        ...this.discountService.getHoverIconProperties(unit, this.globalDiscount),
                        isChecked: this.tableSelection.selectAll ? !this.tableSelection.unselectedUnits.includes(unit.serial) : this.tableSelection.selectedUnits.includes(unit.serial)
                    });
                });
                this.units = tmpUnits;
                this.params.total = data.total;
                if (data.next_cursor) this.params.next_cursor = data.next_cursor;
                if (data.prev_cursor) this.params.prev_cursor = data.prev_cursor;
            })
            .catch(error => {
                console.error(error);
            });
        this.loadingDevices = false;
    }

    async getCustomersAndLocationNames() {
        await this.billingService.getCustomersAndLocationNames(this.selectedUid).then(data => {
            this.customersLocationsNames = data.subcustomersLocationNames;
            data.customers.unshift({ value: this.selectedUid, label: this.translate.instant('transactions.my_account') });
            this.customers = data.customers;
            this.filterOptions.find(option => option.key === 'customer_id').selectOptions = data.customers;
        });
    }
    getLocationName(unit: DisplayBillingUnit): string {
        return this.customersLocationsNames[unit.owner_uid].find(location => location.value === unit.location_id)?.label;
    }
    getCustomerName(unit: DisplayBillingUnit): string {
        return this.customers.find(customer => customer.value === unit.owner_uid)?.label;
    }

    handleFilterValueChange(event: Record<string, any>) {
        if (event.key === 'location_id') this.filterOptions.find(option => option.key === 'location_id').selectOptions = this.customersLocationsNames[event.value];
    }

    handlePage(e: PageEvent) {
        let action: 'next' | 'prev' | 'first' | 'last' = 'next';
        if (this.params.pageSize !== e.pageSize) {
            this.params.pageSize = e.pageSize;
            this.params.pageNumber = 0;
            this.params.prev_cursor = null;
            this.params.next_cursor = null;
        } else {
            if (e.pageIndex - 1 === e.previousPageIndex) action = 'next';
            if (e.pageIndex + 1 === e.previousPageIndex) action = 'prev';
            if (e.pageIndex < e.previousPageIndex - 1) action = 'first';
            if (e.pageIndex > e.previousPageIndex + 1) action = 'last';
        }
        this.params.pageNumber = e.pageIndex;
        this.params.pageSize = e.pageSize;
        this.params.action = action;
        this.loadUnits();
    }

    catchSortChanged(event: Sort) {
        if (this.params.sortBy.key !== event.key) this.params.pageNumber = 0;
        this.params.sortBy = event;
        this.loadUnits();
    }

    toggleUnitSelection(unit: DisplayBillingUnit, isChecked: boolean): void {
        unit.isChecked = isChecked;
        if (isChecked) {
            if (!this.tableSelection.selectAll) this.tableSelection.selectedUnits.push(unit.serial);
            this.tableSelection.unselectedUnits = this.tableSelection.unselectedUnits.filter(serial => serial !== unit.serial);
        } else {
            this.tableSelection.selectedUnits = this.tableSelection.selectedUnits.filter(serial => serial !== unit.serial);
            if (this.tableSelection.selectAll) {
                this.tableSelection.unselectedUnits.push(unit.serial);
            }
        }
        if (this.tableSelection.selectAll && this.tableSelection.unselectedUnits.length === this.units.length) this.tableSelection.selectAll = false;
        if (this.tableSelection.selectedUnits.length === this.params.total) {
            this.tableSelection.selectAll = true;
            this.tableSelection.selectedUnits = [];
            this.tableSelection.unselectedUnits = [];
        }
        if (this.tableSelection.unselectedUnits.length === this.params.total) {
            this.tableSelection.selectAll = false;
            this.tableSelection.selectedUnits = [];
            this.tableSelection.unselectedUnits = [];
        }
    }

    selectAllUnits(isChecked: boolean): void {
        this.tableSelection.selectAll = isChecked;
        for (const unit of this.units) {
            unit.isChecked = isChecked;
        }
        this.tableSelection.selectedUnits = [];
        this.tableSelection.unselectedUnits = [];
    }

    reorderInactiveTableHeadersForMobile() {
        if (this.isMobile) {
            const lastElement = this.tableHeaderOptions.pop();
            this.tableHeaderOptions.unshift(lastElement);
        }
    }

    openModal(modal: NgbModalModule) {
        this.modalRef = this.modalService.open(modal, {
            ariaLabelledBy: 'modal-basic-title'
        });
    }

    async deleteDiscount(): Promise<void> {
        if (await this.deleteModalService.userHasConfirmedDeletion(this.deleteModalOptions))
            this.discountService
                .deleteDiscount(this.selectedUid, this.params, this.tableSelection)
                .then(async (data) => {
                    this.helperService.defaultHtmlToast('', data.message, data.unitsChanged ? 'Success' : 'Warning');
                    this.deleteModalService.resetModalState();
                    this.ngOnInit();
                })
                .catch(error => {
                    this.helperService.defaultHtmlToast('', error?.error?.error, 'Error');
                });
    }

    async catchFilterChanged(event: any) {
        this.params.filter = event;
        this.params.pageNumber = 0;
        this.params.total = 0;
        await this.loadUnits();
    }
}
