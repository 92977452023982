import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { HttpService, RequestTypes } from '../../services/helper/http.service';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
    providedIn: 'root'
})
export class ExportExcelService {
    constructor(private httpService: HttpService) {}

    async exportToExcel(path: string, params: string, filename: string, customer_key?: string): Promise<void> {
        return this.httpService
            .dynamicHttp(path, RequestTypes.GET, { params: { params }, onBehalf: customer_key })
            .then((res: any) => {
                if (res.content) {
                    const binaryString = window.atob(res.content);
                    const binaryLen = binaryString.length;
                    const bytes = new Uint8Array(binaryLen);
                    for (let i = 0; i < binaryLen; i++) {
                        const ascii = binaryString.charCodeAt(i);
                        bytes[i] = ascii;
                    }
                    const blob = new Blob([bytes], { type: EXCEL_TYPE });
                    const fileName = `${filename}-${moment().format('DD-MM-YYYY_HH.mm.ss')}${EXCEL_EXTENSION}`;
                    saveAs(blob, fileName);
                }
            })
            .catch(err => {
                return err;
            });
    }

    async exportOnMail(path: string, params: string, customer_key?: string): Promise<string> {
        return await this.httpService.dynamicHttp(path, RequestTypes.GET, { params: { params }, onBehalf: customer_key });
    }
}
