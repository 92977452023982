import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import moment from 'moment-timezone';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Datepicker } from 'vanillajs-datepicker';
import { DatePickerLanguages } from '../../../constants/datepickerLanguages';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { OperatorService } from '../../../services/operator/operator.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Observable, Subject } from 'rxjs';
import * as Claims from 'shared_models/claims';
import { AuthService } from '../../../services/auth/auth.service';
import { HelperService } from '../../../services/helper/helper.service';
import { filter, takeUntil } from 'rxjs/operators';
import { ContractFormComponent } from '../contracts/contract-form/contract-form.component';
import { RouterLink } from '@angular/router';
import { NgIf, AsyncPipe } from '@angular/common';
@Component({
    selector: 'app-filter-bar',
    templateUrl: './filter-bar.component.html',
    styleUrls: ['./filter-bar.component.scss'],
    standalone: true,
    imports: [NgIf, RouterLink, ContractFormComponent, AsyncPipe, TranslateModule]
})
export class FilterBarComponent implements OnInit, OnDestroy {
    @Input('filter-title') title = '';
    @Output() fromDateEmitter = new EventEmitter<number>();
    @Output() toDateEmitter = new EventEmitter<number>();
    @Output() showOnlyMaintenanceEmitter = new EventEmitter<boolean>();

    isMobile: boolean;
    role$: Observable<Claims.Roles>;
    datepickerStart: Datepicker;
    datepickerEnd: Datepicker;
    from: number;
    to: number;
    loading = true;
    filterByMaintenance = false;
    private ngUnsubscribe = new Subject<void>();

    constructor(
        public authService: AuthService, // used in html
        private translate: TranslateService,
        public modalService: NgbModal,
        private operatorService: OperatorService,
        private breakpointObserver: BreakpointObserver,
        private helperService: HelperService
    ) {
        this.breakpointObserver.observe(['(max-width: 768px)']).subscribe((result: BreakpointState) => {
            this.isMobile = result.matches;
        });

        operatorService.selectedMaintenance$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
            this.filterByMaintenance = value;
        });
    }

    ngOnInit(): void {
        this.role$ = this.authService.getRole;
        moment.locale(this.translate.currentLang);
        this.initDatePicker();
        Object.assign(Datepicker.locales, DatePickerLanguages());
        Datepicker.locales['da'] ? (Datepicker.locales['da']['today'] = 'I dag') : null; // fix for incorrect danish translation in the library
        this.setDateInterval(29);
        this.showOnlyMaintenance(this.filterByMaintenance);
        this.loading = false;
    }
    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        this.operatorService.setMaintenanceValue(false);
    }

    onDayFilterButtonClick($event, days: number) {
        this.setDateInterval(days);
        const clickedElement = $event.target;
        const buttons = document.querySelectorAll('.pill-active-button');
        buttons.forEach(button => {
            button.classList.remove('pill-active-button');
            button.classList.add('pill-button');
        });
        clickedElement.classList.remove('pill-button');
        clickedElement.classList.add('pill-active-button');
    }

    setDateInterval(days: number) {
        this.to = moment().startOf('day').unix() + moment().utcOffset() * 60;
        this.from = moment().subtract(days, 'days').startOf('day').unix() + moment().utcOffset() * 60;
        //Emits the date value
        this.fromDateEmitter.emit(this.from);
        this.toDateEmitter.emit(this.to);
        //Sets datepicker values
        this.datepickerStart.setDate(this.from * 1000);
        this.datepickerEnd.setDate(this.to * 1000);
    }

    datePickerChanged(pickerChanged: 'from' | 'to') {
        if (!this.loading) {
            const buttons = document.querySelectorAll('.pill-active-button');
            buttons.forEach(button => {
                button.classList.remove('pill-active-button');
                button.classList.add('pill-button');
            });
        }

        pickerChanged === 'from'
            ? (this.from = parseInt(
                  moment(this.datepickerStart.dates[0] + moment(this.datepickerStart.dates[0]).utcOffset() * 60 * 1000, 'x')
                      .utc()
                      .startOf('day')
                      .format('X')
              ))
            : (this.to = parseInt(
                  moment(this.datepickerEnd.dates[0] + moment(this.datepickerEnd.dates[0]).utcOffset() * 60 * 1000, 'x')
                      .utc()
                      .startOf('day')
                      .format('X')
              ));

        pickerChanged === 'from' ? this.fromDateEmitter.emit(this.from) : this.toDateEmitter.emit(this.to);
        pickerChanged === 'from' ? this.operatorService.setFrom(this.from) : this.operatorService.setTo(this.to);

        this.datepickerEnd.setOptions({ minDate: this.datepickerStart.dates[0] });
        this.datepickerStart.setOptions({ maxDate: this.datepickerEnd.dates[0] });
    }

    initDatePicker() {
        this.datepickerStart = new Datepicker(document.getElementById('operator-from'), {
            language: this.translate.currentLang,
            todayButton: true,
            clearButton: true,
            autohide: true,
            format: navigator.language === 'en-US' ? 'mm/dd/yyyy' : 'dd/mm/yyyy'
        });
        this.datepickerEnd = new Datepicker(document.getElementById('operator-to'), {
            language: this.translate.currentLang,
            todayButton: true,
            clearButton: true,
            autohide: true,
            format: navigator.language === 'en-US' ? 'mm/dd/yyyy' : 'dd/mm/yyyy'
        });
    }

    openModal(modal: any) {
        const modalOptions: NgbModalOptions = {
            ariaLabelledBy: 'modal-basic-title',
            size: 'lg'
        };
        const modalRef: NgbModalRef = this.modalService.open(modal, modalOptions);

        modalRef.result.then(
            () => {
                // on close
            },
            () => {
                // on error/dismiss
                // to remove the selected order and also reset the forced styling
            }
        );
    }

    showOnlyMaintenance(showOnlyMaintenance: boolean) {
        this.showOnlyMaintenanceEmitter.emit(showOnlyMaintenance);
        this.filterByMaintenance = showOnlyMaintenance;
        this.operatorService.setMaintenanceValue(showOnlyMaintenance);
    }
}
