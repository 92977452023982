import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FilterSortParams } from '@shared_models/search-params/FilterSortParams';
import _ from 'lodash';
import { AuthService } from '../auth/auth.service';
import { lastValueFrom } from 'rxjs';
import { AWHeaders } from '@shared_models/headers';
import { paginateData, sortCouponList } from './paginate-sort-helper';
import { Coupon, isCoupon } from '@shared_models/coupon';

const CACHE_TIMEOUT: number = 10 * 60 * 1000; // 10 minutes in milliseconds
const CACHE_ITEMS_BREAKPOINT: number = 50; // The number of items at which the cache service should sort on Frontend instead of Backend

class Cache<T> {
    private data = new Map<string, { value: T; timestamp: number }>();

    get(key: string): T | undefined {
        const cached = this.data.get(key);
        if (cached) {
            const now = Date.now();
            if (now - cached.timestamp > CACHE_TIMEOUT) {
                // If the data is older than 10 minutes, delete it from the cache
                this.data.delete(key);
                return undefined;
            }
            return cached.value;
        }
        return undefined;
    }

    set(key: string, value: T): void {
        const now = Date.now();
        const cached = this.data.get(key);
        if (cached) {
            // If the key already exists in the cache, update the value but preserve the original timestamp
            this.data.set(key, { value, timestamp: cached.timestamp });
        } else {
            // If the key does not exist in the cache, add it with the current timestamp
            this.data.set(key, { value, timestamp: now });
        }
    }

    clear(): void {
        this.data.clear();
    }

    bustAllCacheByUrl(baseUrl: string): void {
        for (const key of this.data.keys()) {
            const keyObject = JSON.parse(key);
            if (keyObject.baseUrl === baseUrl) {
                this.data.delete(key);
            }
        }
    }
}

export interface PaginatedData<T> {
    pages: Record<number, T[]>;
    totalItems: number;
}

@Injectable({ providedIn: 'root' })
export class PaginateFetchCacheService<T> {
    private cache: Cache<PaginatedData<T>> = new Cache<PaginatedData<T>>();

    constructor(
        private http: HttpClient,
        private authService: AuthService
    ) {}

    /**
     * Fetches paginated data from a given API endpoint and caches it for future use.
     *
     * This function first checks if the requested data is already cached. If it is, and the current page is cached,
     * it checks if more pages should be fetched based on the current page number and the page buffer. If more pages
     * should be fetched, it fetches and caches them. It then returns the cached page.
     *
     * If the requested data is not cached or the current page is not cached, it fetches the data from the API endpoint,
     * caches it, and returns it.
     *
     * @param {string} apiEndpoint - The API endpoint to fetch data from.
     * @param {FilterSortParams} params - The parameters for filtering and sorting the data.
     * @param uidOnBehalf
     * @returns {Promise<PaginatedData<T>>} - An Observable of the fetched paginated data.
     */
    async getData(apiEndpoint: string, params: FilterSortParams, uidOnBehalf?: string): Promise<PaginatedData<T>> {
        const { pageNumber, pageSize, sortBy, filter } = params;
        const baseUrl: string = apiEndpoint.split('?')[0];
        const cacheKey: string = JSON.stringify({ baseUrl, pageSize, sortBy, filter });
        const smallCacheKey: string = JSON.stringify({ baseUrl, pageSize, filter }); // Used if the items are less than 40
        const cachedData: PaginatedData<T> = this.cache.get(cacheKey);
        const smallCachedData: PaginatedData<T> = this.cache.get(smallCacheKey);

        if (smallCachedData) {
            return this.sortAndPaginateCachedData(smallCachedData, params);
        }

        if (cachedData) {
            const cachedPage: T[] = cachedData.pages[pageNumber];

            if (cachedPage) {
                if (this.shouldFetchMorePages(pageNumber, pageSize, cacheKey)) {
                    this.fetchAndCachePage(apiEndpoint, cacheKey, uidOnBehalf);
                }

                return { pages: { [pageNumber]: cachedPage }, totalItems: cachedData.totalItems };
            }
        }
        const headers: Record<string, AWHeaders> = await this.authService.addHeaders(uidOnBehalf);
        const data: PaginatedData<T> = await lastValueFrom(this.http.get<PaginatedData<T>>(apiEndpoint, headers));
        if (cachedData) {
            // If cachedData already exists, merge the new data with the existing data
            cachedData.pages = { ...cachedData.pages, ...data.pages };
            cachedData.totalItems = data.totalItems;
            this.cache.set(cacheKey, cachedData);
        } else {
            // If cachedData does not exist, create a new cache entry
            if (data.totalItems < CACHE_ITEMS_BREAKPOINT) {
                this.cache.set(smallCacheKey, data);
            } else {
                this.cache.set(cacheKey, data);
            }
        }
        return data;
    }

    async updateDataIfDifferentFromCache(apiEndpoint: string, params: FilterSortParams, uidOnBehalf?: string): Promise<PaginatedData<T>> {
        const { pageSize, sortBy, filter } = params;
        const baseUrl: string = apiEndpoint.split('?')[0];
        const cacheKey: string = JSON.stringify({ baseUrl, pageSize, sortBy, filter });
        const localCacheKey: string = JSON.stringify({ baseUrl, pageSize, filter }); // Used if the items are less than 40

        const fetchedData: PaginatedData<T> = await this.fetchAndCachePage(apiEndpoint, cacheKey, uidOnBehalf);
        const cachedData: PaginatedData<T> = this.cache.get(cacheKey);
        const smallCachedData: PaginatedData<T> = this.cache.get(localCacheKey);
        if (!!cachedData && !_.isEqual(fetchedData, cachedData)) {
            this.cache.set(cacheKey, fetchedData);
        } else if (!!smallCachedData && !_.isEqual(fetchedData, smallCachedData)) {
            this.cache.set(localCacheKey, fetchedData);
        }
        return this.cache.get(cacheKey) ?? this.cache.get(localCacheKey);
    }

    private async fetchAndCachePage(apiEndpoint: string, cacheKey: string, uidOnBehalf?: string): Promise<PaginatedData<T>> {
        const headers: Record<string, AWHeaders> = await this.authService.addHeaders(uidOnBehalf);
        const data: PaginatedData<T> = await lastValueFrom(this.http.get<PaginatedData<T>>(apiEndpoint, headers));
        const cachedData: PaginatedData<T> = this.cache.get(cacheKey);
        if (cachedData) {
            // If cachedData already exists, merge the new data with the existing data
            cachedData.pages = { ...cachedData.pages, ...data.pages };
            cachedData.totalItems = data.totalItems;
            this.cache.set(cacheKey, cachedData);
        }
        return cachedData ?? data;
    }

    /**
     * Determines whether more pages should be fetched based on the current page number, page size, and cache key.
     *
     * This function first retrieves the cached data using the cache key. It then calculates the total number of pages
     * based on the total number of items and the page size. It checks if the current page is the initial page or the last page.
     * If it is, it returns false as no more pages need to be fetched.
     *
     * If the current page is not the initial or the last page, it checks if the current page is near the end or the start of the cached pages.
     * If it is, it returns true indicating that more pages should be fetched. Otherwise, it returns false.
     *
     * @param {number} pageNumber - The current page number.
     * @param {number} pageSize - The number of items per page.
     * @param {string} cacheKey - The key used to retrieve the cached data.
     * @returns {boolean} - A boolean indicating whether more pages should be fetched.
     */
    private shouldFetchMorePages(pageNumber: number, pageSize: number, cacheKey: string): boolean {
        const pageBuffer: number = 2;
        const cachedData: PaginatedData<T> = this.cache.get(cacheKey);
        const cachedPageNumbers: string[] = Object.keys(cachedData.pages);
        const totalPages: number = Math.ceil(cachedData.totalItems / pageSize);
        const isInitialPage: boolean = pageNumber === 0;
        const isLastPage: boolean = pageNumber === totalPages - 1;
        if (isInitialPage || isLastPage) {
            return false;
        }
        const isNearEndOfCachedPages: boolean = !cachedPageNumbers.includes((pageNumber + pageBuffer).toString());
        const isNearStartOfCachedPages: boolean = !cachedPageNumbers.includes((pageNumber - pageBuffer).toString());
        return isNearEndOfCachedPages || isNearStartOfCachedPages;
    }

    /**
     * Sorts and paginates cached data with items lower than the CACHED_ITEMS_BREAKPOINT based on provided sorting parameters.
     * This method takes cached data, which is structured with pages of items, and a set of sorting parameters.
     * It first flattens the pages into a single array of items, then sorts this array based on the sorting parameters.
     * After sorting, it repaginates the items according to the original pagination parameters (page number and page size).
     * This ensures that the client receives a correctly sorted and paginated set of data from the cache.
     *
     * @param {PaginatedData<T>} smallCachedData - The cached data containing pages of items.
     * @param {FilterSortParams} params - The parameters for filtering and sorting the data, including page number and page size.
     * @returns {Promise<PaginatedData<T>>} - A promise that resolves to the newly sorted and paginated data.
     */
    async sortAndPaginateCachedData(smallCachedData: PaginatedData<T>, params: FilterSortParams): Promise<PaginatedData<T>> {
        let allItems: T[] = Object.values(smallCachedData.pages).flat(); // Flatten the pages to a single array

        allItems = this.sortCachedData(allItems, params);

        const paginatedData: PaginatedData<T> = { pages: {}, totalItems: allItems.length };
        paginatedData.pages = paginateData(allItems, params.pageNumber, params.pageSize, 3);

        return paginatedData;
    }

    /**
     * Sorts an array of items based on the provided sorting parameters.
     * This function checks the type of each item in the array to determine the appropriate sorting logic.
     * If all items are `User`, it applies a specific sorting logic for users, etc.
     * Otherwise, it defaults to a generic sorting logic based on the `sortBy` parameter.
     *
     * @param {T[]} allItems - The array of items to be sorted.
     * @param {FilterSortParams} params - The sorting parameters, including the key to sort by and the order.
     * @returns {T[]} The sorted array of items.
     */
    private sortCachedData(allItems: T[], params: FilterSortParams): T[] {
        console.log('Sorting cached data');
        if (allItems.every(isCoupon)) {
            allItems = sortCouponList(allItems as Coupon[], params.sortBy) as T[];
        } else {
            allItems = _.orderBy(
                allItems,
                [
                    item => {
                        const value = _.get(item, params.sortBy.key);
                        return typeof value === 'string' ? value.toLowerCase() : value;
                    }
                ],
                [params.sortBy.order]
            );
        }
        return allItems;
    }

    public bustAllCacheByUrl(baseUrl: string): void {
        this.cache.bustAllCacheByUrl(baseUrl);
    }

    public cacheExists(apiEndpoint: string, params: FilterSortParams): boolean {
        const baseUrl: string = apiEndpoint.split('?')[0];
        const cacheKey: string = JSON.stringify({ baseUrl, pageSize: params.pageSize, sortBy: params.sortBy, filter: params.filter });
        const smallCacheKey: string = JSON.stringify({ baseUrl, pageSize: params.pageSize, filter: params.filter }); // Used if the items are less than 40

        if (this.cache.get(cacheKey) || this.cache.get(smallCacheKey)) {
            return true;
        } else {
            return false;
        }
    }
}
