import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreditReport, CreditUser } from 'shared_models/credit-payment';
import { CreditPaymentService } from '../../../services/credit-payment/credit-payment.service';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import moment from 'moment';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { DashboardUser } from '@dashboard_models/dashboard-user';
import { HelperService } from 'src/app/services/helper/helper.service';
import { ActivatedRoute } from '@angular/router';
import { PaginatePipe } from '../../../pipe/paginate.pipe';
import { CreditReportListModalComponent } from './credit-report-list-modal/credit-report-list-modal.component';
import { CreditUserFormModalComponent } from './credit-user-form-modal/credit-user-form-modal.component';
import { LoadingComponent } from '../../loading/loading.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-credit-user-table',
    templateUrl: './credit-user-table.component.html',
    styleUrls: ['./credit-user-table.component.scss'],
    standalone: true,
    imports: [NgIf, LoadingComponent, NgFor, MatPaginator, CreditUserFormModalComponent, CreditReportListModalComponent, TranslateModule, PaginatePipe]
})
export class CreditUserTableComponent implements OnInit, OnDestroy {
    @Input() locationId: string;
    creditUser: CreditUser;
    allCreditUsersArr: CreditUser[] = [];
    numberOfCreditUsers = 0;
    loading = true;
    pageSize = 10;
    pageNumber = 0;
    pageSizeOptions: number[] = [10, 50, 100];
    reportsSub: Subscription;
    reports: CreditReport[] = [];
    isMobileForCreditUserTable: boolean;
    user: DashboardUser;
    isCustomerOperated: boolean;
    uid: string;
    subCustomerUid: string;

    constructor(
        private modalService: NgbModal,
        private creditPaymentService: CreditPaymentService,
        private translate: TranslateService,
        private breakpointObserver: BreakpointObserver,
        private helperService: HelperService,
        private route: ActivatedRoute
    ) {
        this.breakpointObserver.observe(['(max-width: 768px)']).subscribe((result: BreakpointState) => {
            this.isMobileForCreditUserTable = result.matches;
        });
    }

    ngOnDestroy() {
        this.reportsSub ? this.reportsSub.unsubscribe() : null;
    }

    async ngOnInit(): Promise<void> {
        this.user = this.helperService.getUser();
        this.isCustomerOperated = window.location.pathname.split('/').includes('customers') ? true : false;
        this.uid = this.isCustomerOperated ? `${this.route.snapshot.paramMap.get('sub_customer_id')}_operated_by_${this.user.uid}` : this.user.uid;
        this.appendAllCreditUsers();
        this.setSubscriptions();
    }

    async appendAllCreditUsers() {
        this.creditPaymentService.getCreditUsers(this.locationId, this.uid).then(creditUsers => {
            this.allCreditUsersArr = creditUsers;
            this.allCreditUsersArr.sort((a, b) => (a.created < b.created ? 1 : -1));
        });
        this.loading = false;
    }

    setSubscriptions() {
        this.reportsSub = this.creditPaymentService
            .readReports(this.uid, this.locationId)
            .snapshotChanges()
            .subscribe(reportsSnapAction => {
                this.reportsSub.unsubscribe();
                this.reports = []; // reset if changes on snapshot detected
                if (reportsSnapAction.payload.exists()) {
                    const reportsOnLocation: Record<string, CreditReport> = reportsSnapAction.payload.val();
                    for (const key in reportsOnLocation) {
                        this.reports.push(reportsOnLocation[key]);
                    }
                }
            });
    }

    created(created: number): string {
        if (moment(created, 'X').year() === moment().year()) {
            return moment(created, 'X').locale(this.translate.currentLang).format('D MMM'); // short formart for current year
        }
        return moment(created, 'X').locale(this.translate.currentLang).format('D MMM YYYY'); // else for previous years, longer format
    }

    openModal(modal: any, creditUser?: CreditUser) {
        if (modal === null) return;

        this.creditUser = creditUser ? creditUser : null;
        this.modalService
            .open(modal, {
                ariaLabelledBy: 'modal-basic-title'
            })
            .result.then((event: any) => {})
            .catch((event: any) => {});
    }

    handlePage(e: PageEvent) {
        this.pageSize = e.pageSize;
        this.pageNumber = e.pageIndex;
    }

    createUserInList(creditUser: CreditUser) {
        this.allCreditUsersArr.unshift(creditUser);
        this.allCreditUsersArr = this.allCreditUsersArr.slice();
    }

    updateUserInList(creditUser: CreditUser) {
        const index: number = this.allCreditUsersArr.findIndex(_creditUser => _creditUser.id === creditUser.id);
        this.allCreditUsersArr[index] = { ...this.allCreditUsersArr.slice()[index], ...creditUser };
        this.allCreditUsersArr = this.allCreditUsersArr.slice();
    }

    deleteUserInList(creditUserId: string) {
        const index: number = this.allCreditUsersArr.findIndex(creditUser => creditUser.id === creditUserId);
        this.allCreditUsersArr.splice(index, 1);
        this.allCreditUsersArr = this.allCreditUsersArr.slice();
    }
}
