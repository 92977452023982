export const bankAccountCountries: Record<string, {
    routing_type: "swift" | "none" | "routing" | "sort_code" | "bank_code",
    account_type: "iban" | "account",
}> = {
    "AL": {
        "routing_type": "swift",
        "account_type": "iban"
    },
    "DZ": {
        "routing_type": "swift",
        "account_type": "account"
    },
    "AO": {
        "routing_type": "swift",
        "account_type": "iban"
    },
    "AG": {
        "routing_type": "swift",
        "account_type": "account"
    },
    "AR": {
        "routing_type": "none",
        "account_type": "account"
    },
    "AM": {
        "routing_type": "swift",
        "account_type": "account"
    },
    "AU": {
        "routing_type": "routing",
        "account_type": "account"
    },
    "AZ": {
        "routing_type": "routing",
        "account_type": "iban"
    },
    "AT": {
        "routing_type": "none",
        "account_type": "iban"
    },
    "BS": {
        "routing_type": "swift",
        "account_type": "account"
    },
    "BH": {
        "routing_type": "swift",
        "account_type": "iban"
    },
    "BD": {
        "routing_type": "routing",
        "account_type": "account"
    },
    "BE": {
        "routing_type": "none",
        "account_type": "iban"
    },
    "BJ": {
        "routing_type": "none",
        "account_type": "iban"
    },
    "BN": {
        "routing_type": "swift",
        "account_type": "account"
    },
    "BO": {
        "routing_type": "none",
        "account_type": "account"
    },
    "BA": {
        "routing_type": "swift",
        "account_type": "iban"
    },
    "BW": {
        "routing_type": "swift",
        "account_type": "account"
    },
    "BR": {
        "routing_type": "routing",
        "account_type": "account"
    },
    "BG": {
        "routing_type": "none",
        "account_type": "iban"
    },
    "BT": {
        "routing_type": "swift",
        "account_type": "account"
    },
    "KH": {
        "routing_type": "swift",
        "account_type": "account"
    },
    "CA": {
        "routing_type": "routing",
        "account_type": "account"
    },
    "CL": {
        "routing_type": "routing",
        "account_type": "account"
    },
    "CO": {
        "routing_type": "bank_code",
        "account_type": "account"
    },
    "CR": {
        "routing_type": "none",
        "account_type": "iban"
    },
    "HR": {
        "routing_type": "none",
        "account_type": "iban"
    },
    "CY": {
        "routing_type": "none",
        "account_type": "iban"
    },
    "CZ": {
        "routing_type": "none",
        "account_type": "iban"
    },
    "CI": {
        "routing_type": "none",
        "account_type": "iban"
    },
    "DK": {
        "routing_type": "none",
        "account_type": "iban"
    },
    "DO": {
        "routing_type": "routing",
        "account_type": "account"
    },
    "EC": {
        "routing_type": "swift",
        "account_type": "account"
    },
    "EG": {
        "routing_type": "routing",
        "account_type": "iban"
    },
    "SV": {
        "routing_type": "swift",
        "account_type": "iban"
    },
    "EE": {
        "routing_type": "none",
        "account_type": "iban"
    },
    "ET": {
        "routing_type": "swift",
        "account_type": "account"
    },
    "FI": {
        "routing_type": "none",
        "account_type": "iban"
    },
    "FR": {
        "routing_type": "none",
        "account_type": "iban"
    },
    "GA": {
        "routing_type": "swift",
        "account_type": "account"
    },
    "GM": {
        "routing_type": "swift",
        "account_type": "account"
    },
    "DE": {
        "routing_type": "none",
        "account_type": "iban"
    },
    "GH": {
        "routing_type": "routing",
        "account_type": "account"
    },
    "GI": {
        "routing_type": "none",
        "account_type": "iban"
    },
    "GR": {
        "routing_type": "none",
        "account_type": "iban"
    },
    "GT": {
        "routing_type": "swift",
        "account_type": "iban"
    },
    "GY": {
        "routing_type": "swift",
        "account_type": "account"
    },
    "HK": {
        "routing_type": "routing",
        "account_type": "account"
    },
    "HU": {
        "routing_type": "none",
        "account_type": "iban"
    },
    "IS": {
        "routing_type": "none",
        "account_type": "iban"
    },
    "IN": {
        "routing_type": "routing",
        "account_type": "account"
    },
    "ID": {
        "routing_type": "bank_code",
        "account_type": "account"
    },
    "IE": {
        "routing_type": "none",
        "account_type": "iban"
    },
    "IL": {
        "routing_type": "none",
        "account_type": "iban"
    },
    "IT": {
        "routing_type": "none",
        "account_type": "iban"
    },
    "JM": {
        "routing_type": "routing",
        "account_type": "account"
    },
    "JP": {
        "routing_type": "routing",
        "account_type": "account"
    },
    "JO": {
        "routing_type": "swift",
        "account_type": "iban"
    },
    "KZ": {
        "routing_type": "swift",
        "account_type": "iban"
    },
    "KE": {
        "routing_type": "swift",
        "account_type": "account"
    },
    "KW": {
        "routing_type": "swift",
        "account_type": "iban"
    },
    "LA": {
        "routing_type": "swift",
        "account_type": "account"
    },
    "LV": {
        "routing_type": "none",
        "account_type": "iban"
    },
    "LI": {
        "routing_type": "none",
        "account_type": "iban"
    },
    "LT": {
        "routing_type": "none",
        "account_type": "iban"
    },
    "LU": {
        "routing_type": "none",
        "account_type": "iban"
    },
    "MO": {
        "routing_type": "swift",
        "account_type": "account"
    },
    "MG": {
        "routing_type": "swift",
        "account_type": "iban"
    },
    "MY": {
        "routing_type": "swift",
        "account_type": "account"
    },
    "MT": {
        "routing_type": "none",
        "account_type": "iban"
    },
    "MU": {
        "routing_type": "none",
        "account_type": "iban"
    },
    "MX": {
        "routing_type": "none",
        "account_type": "account"
    },
    "MD": {
        "routing_type": "swift",
        "account_type": "iban"
    },
    "MC": {
        "routing_type": "none",
        "account_type": "iban"
    },
    "MN": {
        "routing_type": "swift",
        "account_type": "account"
    },
    "MA": {
        "routing_type": "swift",
        "account_type": "iban"
    },
    "MZ": {
        "routing_type": "swift",
        "account_type": "account"
    },
    "NA": {
        "routing_type": "swift",
        "account_type": "account"
    },
    "NL": {
        "routing_type": "none",
        "account_type": "iban"
    },
    "NZ": {
        "routing_type": "none",
        "account_type": "account"
    },
    "NE": {
        "routing_type": "none",
        "account_type": "iban"
    },
    "NG": {
        "routing_type": "swift",
        "account_type": "account"
    },
    "MK": {
        "routing_type": "swift",
        "account_type": "iban"
    },
    "NO": {
        "routing_type": "none",
        "account_type": "iban"
    },
    "OM": {
        "routing_type": "swift",
        "account_type": "account"
    },
    "PK": {
        "routing_type": "swift",
        "account_type": "iban"
    },
    "PA": {
        "routing_type": "swift",
        "account_type": "iban"
    },
    "PY": {
        "routing_type": "routing",
        "account_type": "account"
    },
    "PE": {
        "routing_type": "none",
        "account_type": "account"
    },
    "PH": {
        "routing_type": "swift",
        "account_type": "account"
    },
    "PL": {
        "routing_type": "none",
        "account_type": "iban"
    },
    "PT": {
        "routing_type": "none",
        "account_type": "iban"
    },
    "QA": {
        "routing_type": "swift",
        "account_type": "iban"
    },
    "RO": {
        "routing_type": "none",
        "account_type": "iban"
    },
    "RW": {
        "routing_type": "swift",
        "account_type": "account"
    },
    "LC": {
        "routing_type": "swift",
        "account_type": "account"
    },
    "SM": {
        "routing_type": "swift",
        "account_type": "iban"
    },
    "SA": {
        "routing_type": "swift",
        "account_type": "iban"
    },
    "SN": {
        "routing_type": "none",
        "account_type": "iban"
    },
    "RS": {
        "routing_type": "swift",
        "account_type": "iban"
    },
    "SG": {
        "routing_type": "routing",
        "account_type": "account"
    },
    "SK": {
        "routing_type": "none",
        "account_type": "iban"
    },
    "SI": {
        "routing_type": "none",
        "account_type": "iban"
    },
    "ZA": {
        "routing_type": "swift",
        "account_type": "account"
    },
    "KR": {
        "routing_type": "swift",
        "account_type": "account"
    },
    "LK": {
        "routing_type": "none",
        "account_type": "account"
    },
    "ES": {
        "routing_type": "none",
        "account_type": "iban"
    },
    "SE": {
        "routing_type": "none",
        "account_type": "iban"
    },
    "CH": {
        "routing_type": "none",
        "account_type": "iban"
    },
    "TW": {
        "routing_type": "swift",
        "account_type": "account"
    },
    "TZ": {
        "routing_type": "swift",
        "account_type": "account"
    },
    "TH": {
        "routing_type": "none",
        "account_type": "account"
    },
    "TT": {
        "routing_type": "swift",
        "account_type": "account"
    },
    "TN": {
        "routing_type": "none",
        "account_type": "iban"
    },
    "TR": {
        "routing_type": "routing",
        "account_type": "account"
    },
    "AE": {
        "routing_type": "none",
        "account_type": "iban"
    },
    "GB": {
        "routing_type": "sort_code",
        "account_type": "account"
    },
    "US": {
        "routing_type": "routing",
        "account_type": "account"
    },
    "UY": {
        "routing_type": "routing",
        "account_type": "account"
    },
    "UZ": {
        "routing_type": "routing",
        "account_type": "account"
    },
    "VN": {
        "routing_type": "routing",
        "account_type": "account"
    }
}
