import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { map, take } from 'rxjs/operators';
import { DashboardUser } from '@dashboard_models/dashboard-user';
import { DetailedUser, RecentActivityDetails, Titles, AllowedUpdateFields, UserTransaction } from 'shared_models/user-management';
import { HelperService } from 'src/app/services/helper/helper.service';
import { UsersService } from 'src/app/services/users/users.service';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { DashboardCoupon, Coupon, CouponStatus, TriggerType } from 'shared_models/coupon';
import { RefundReasonModalComponent } from '../../misc/refund-reason-modal/refund-reason-modal.component';
import { RefundModalComponent } from '../../misc/refund-modal/refund-modal.component';
import { AddCommentModalComponent } from '../user-modals/add-comment-modal/add-comment-modal.component';
import { ApplyCouponModalComponent } from '../user-modals/apply-coupon-modal/apply-coupon-modal.component';
import { ApplyCreditPaymentModalComponent } from '../user-modals/apply-credit-payment-modal/apply-credit-payment-modal.component';
import { EditCouponsModalComponent } from '../user-modals/edit-coupons-modal/edit-coupons-modal.component';
import { CreditPaymentInfoModalComponent } from '../user-modals/credit-payment-info-modal/credit-payment-info-modal.component';
import { CustomToolTipComponent } from '../../misc/custom-tool-tip/custom-tool-tip.component';
import { LoadingComponent } from '../../loading/loading.component';
import { AnimatedButtonComponent } from '../../misc/animated-button/animated-button.component';
import { NgIf, NgFor, SlicePipe, KeyValuePipe, NgClass } from '@angular/common';
import { DateTimeService } from '@services/date-time/date-time.service';
@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgClass,
        NgIf,
        AnimatedButtonComponent,
        LoadingComponent,
        CustomToolTipComponent,
        NgFor,
        CreditPaymentInfoModalComponent,
        EditCouponsModalComponent,
        ApplyCreditPaymentModalComponent,
        ApplyCouponModalComponent,
        AddCommentModalComponent,
        RefundModalComponent,
        RefundReasonModalComponent,
        SlicePipe,
        KeyValuePipe,
        TranslateModule
    ]
})
export class UserComponent implements OnInit {
    numbers = Array(6)
        .fill(0)
        .map((x, i) => i);
    currentUser: DetailedUser;
    showLoadingIndicator = true;
    isMobile = false;
    user: DashboardUser;
    // currentUsedPaymentMethod: PaymentType;
    activityList: RecentActivityDetails[] = [];
    selectedTransaction: UserTransaction;
    lastIndex = 0;
    showSmallIndicatorTest = false;
    showSmallIndicatorBlock = false;
    forceClose: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    itemsToShowInitially = 5;
    itemsToShow: number = this.itemsToShowInitially;
    currentActivityIndex: number;
    errorShowing = false;
    savedTitle: Titles;
    transactionIndex: number;
    showLoadingOnActivity = false;
    userNotFound = false;
    triggerType = TriggerType;
    fetchedCoupons = false;
    userId: string;
    ownerUid: string;
    appliedCoupons: Coupon[] = [];
    availableCoupons: Coupon[] = [];

    constructor(
        private usersService: UsersService,
        public translate: TranslateService,
        protected helperService: HelperService,
        private route: ActivatedRoute,
        private modal: NgbModal,
        private breakpointObserver: BreakpointObserver,
        private toast: ToastrService,
        private router: Router,
        private dataTimeService: DateTimeService,
        private cdr: ChangeDetectorRef
    ) {
        this.breakpointObserver.observe(['(max-width: 768px)']).subscribe(result => {
            this.isMobile = result.matches;
        });
    }

    async ngOnInit(): Promise<void> {
        this.user = this.helperService.getUser();
        this.userId = await lastValueFrom(
            this.route.params.pipe(
                take(1),
                map(params => params.user_id as string)
            )
        );
        this.ownerUid = await lastValueFrom(
            this.route.queryParams.pipe(
                take(1),
                map(queryParams => queryParams.owner_uid as string)
            )
        );
        this.getCurrentUser();
        this.fetchCoupons();
        if (this.user === undefined) {
            this.errorShowing = true;
        }
    }

    formatCurrency(value: number | null, currency: string) {
        if (isNaN(value)) {
            return '-';
        }
        return this.helperService.localizeNumberWithCurrency(value ? value / 100 : 0, 2, 2, currency);
    }

    getLocaleDate(timestamp: number) {
        return this.dataTimeService.getDateAndTime(timestamp, false, true);
    }

    convertCardNumber(last4: string) {
        return `• • • • ${last4}`;
    }

    openModal(modal: TemplateRef<NgbModalRef>, size?: 'sm' | 'lg') {
        this.modal.open(modal, { size: size ? size : 'lg' });
    }

    async updateUserState(fields: AllowedUpdateFields) {
        if (fields.applied_coupons || fields.available_coupons) {
            await this.usersService.getCoupons(this.currentUser.uid, this.currentUser.owner_uid).then(coupons => {
                fields.applied_coupons = coupons.applied_coupons;
                fields.available_coupons = coupons.available_coupons;
                this.availableCoupons = coupons.available_coupons;
                this.appliedCoupons = coupons.applied_coupons;
                this.cdr.markForCheck();
            });
        }

        Object.keys(fields).forEach(key => {
            this.currentUser[key] = fields[key];
        });

        this.cdr.markForCheck();
    }

    async getCurrentUser() {
        this.currentUser = await this.usersService.getCurrentUser(this.userId, this.ownerUid ?? this.user.uid).catch(() => {
            this.userNotFound = true;
            this.router.navigate([`/users`]).then(() => {
                setTimeout(() => {
                    this.toast.warning(`${this.translate.instant('users.user.not_found')}`, this.translate.instant('misc.attention'));
                }, 800); // Delay to avoid showing the toast before the redirect
            });
            throw new Error('User not found');
        });

        if (this.currentUser.transactions.length <= 0) {
            this.currentUser.transactions = Array(6).fill('') as UserTransaction[];
        }

        console.log(this.currentUser);

        this.savedTitle = this.currentUser.user_title;
        this.showLoadingIndicator = false;

        this.cdr.markForCheck();
    }

    async fetchCoupons() {
        try {
            const coupons = await this.usersService.getCoupons(this.userId, this.ownerUid);
            this.appliedCoupons = coupons.applied_coupons;
            this.availableCoupons = coupons.available_coupons;
            this.fetchedCoupons = true;
            this.cdr.markForCheck();
        } catch (error) {
            console.error('Error fetching coupons:', error);
        }
    }

    openRefundModal(index: number, modal: NgbModalRef) {
        this.selectedTransaction = this.currentUser.transactions[index];
        this.transactionIndex = index;
        this.modal.open(modal);
    }

    // Handle test user
    handleTestUserSubmit() {
        this.showSmallIndicatorTest = true;
        const shouldMakeTestUser: boolean = this.currentUser.pm === 'test' ? false : true;
        this.usersService
            .handleTestUser(this.currentUser.uid, this.currentUser.owner_uid)
            .then(() => {
                setTimeout(() => {
                    this.forceClose.next(true);

                    const toUpdate: AllowedUpdateFields = {
                        pm: shouldMakeTestUser ? 'test' : 'no_payment_type'
                    };
                    this.updateUserState(toUpdate);
                    this.showSmallIndicatorTest = false;
                    this.toast.success(
                        `${this.currentUser.name.replaceAll('_', ' ')} ${this.translate.instant(shouldMakeTestUser ? `users.user.success_make_test_user` : `users.user.success_remove_test_user`)}`,
                        this.translate.instant('misc.success')
                    );
                }, 1000);
            })
            .catch(() => {
                this.toast.error(`${this.currentUser.name.replaceAll('_', ' ')} ${this.translate.instant(shouldMakeTestUser ? `users.user.error_make_test_user` : `users.user.error_remove_test_user`)}`, this.translate.instant('misc.error'));
                setTimeout(() => {
                    this.showSmallIndicatorTest = false;
                }, 1000);
            });

        this.cdr.markForCheck();
    }

    handleBlockUserSubmit() {
        this.showSmallIndicatorBlock = true;
        const shouldBlock: boolean = this.currentUser.blocked ? false : true;

        this.usersService
            .handleUserBlock(this.currentUser.uid, this.currentUser.owner_uid)
            .then(() => {
                setTimeout(() => {
                    const toUpdate: AllowedUpdateFields = {
                        blocked: shouldBlock
                    };
                    this.updateUserState(toUpdate);
                    this.forceClose.next(true);
                    this.showSmallIndicatorBlock = false;
                    this.toast.success(`${this.currentUser.name.replaceAll('_', ' ')} ${this.translate.instant(shouldBlock ? `users.user.success_block` : `users.user.success_unblock`)}`, this.translate.instant('misc.success'));
                }, 1000);
            })
            .catch(() => {
                this.toast.error(`${this.currentUser.name.replaceAll('_', ' ')} ${this.translate.instant(shouldBlock ? `users.user.error_block` : `users.user.error_remove_block`)}`, this.translate.instant('misc.error'));
                setTimeout(() => {
                    this.showSmallIndicatorBlock = false;
                }, 1000);
            });

        this.cdr.markForCheck();
    }

    extendActivityList() {
        this.itemsToShow += this.itemsToShowInitially;
    }

    async removeComment(commentId: string) {
        await this.usersService.deleteComment(commentId, this.currentUser.uid, this.currentUser.owner_uid).then(response => {
            this.toast.success(`${this.translate.instant(`users.user.success_remove_comment`)}`, this.translate.instant('misc.success'));
            this.refetchActivities();
        });

        this.cdr.markForCheck();
    }

    getStatusMessage(recentActivity: RecentActivityDetails) {
        switch (recentActivity.status_type) {
            case 'refund':
                return `${this.translate.instant('users.user.activity.refund')} ${this.formatCurrency(recentActivity.amount, recentActivity.currency)}`;
            case 'payment_failed':
                return `${this.translate.instant('users.user.activity.payment_failed')} ${this.formatCurrency(recentActivity.amount, recentActivity.currency)} ${this.translate.instant('users.user.activity.failed')}`;
            case 'wallet_payment_failed':
                return `${this.translate.instant('users.user.activity.wallet_payment_failed')} ${this.formatCurrency(recentActivity.amount, recentActivity.currency)} ${this.translate.instant('users.user.activity.failed')}`;
            case 'payment_method_successful':
                if (recentActivity.last4 && recentActivity.paymentType) {
                    return `${this.translate.instant('users.user.activity.payment_method_part1')} ${this.snakeToReadable(recentActivity.paymentType)} ${this.translate.instant('users.user.activity.payment_method_part2')} ${recentActivity.last4} ${this.translate.instant('users.user.activity.payment_method_part3')}`;
                } else {
                    return `${this.translate.instant('users.user.activity.payment_method_atttached')}`;
                }

            case 'user_creation':
                return this.translate.instant('users.user.activity.user_creation');
            default:
                return this.translate.instant('users.user.activity.comment');
        }
    }

    showMoreDetailsToggle(index: number) {
        if (this.currentActivityIndex === index) {
            this.currentActivityIndex = undefined;
        } else {
            this.currentActivityIndex = index;
        }
    }

    getId(paymentId: string, index: number) {
        return `${paymentId}_${index}`;
    }

    isExpired(date: string) {
        if (!date) {
            return false;
        }
        const [month, year] = date.split('/');
        const currentMonth = parseInt(moment().format('MM'));
        const currentYear = parseInt(moment().format('YYYY'));
        return currentYear > parseInt(year) || (currentYear === parseInt(year) && currentMonth > parseInt(month));
    }

    goToTransactions() {
        this.router.navigate(['transactions'], {
            queryParams: {
                owner_uid: this.currentUser.owner_uid,
                user_id: this.currentUser.uid,
                terminal: this.currentUser.platform === 'terminal' ? this.currentUser.pm_fingerprint : null
            }
        });
    }

    getUIDetails(coupon: DashboardCoupon): { icon: string; text: string } {
        switch (coupon.status) {
            case CouponStatus.ACTIVE:
                return { icon: 'check.svg', text: this.translate.instant('users.user.active') };
            case CouponStatus.EXPIRED:
                return { icon: 'cross.svg', text: this.translate.instant('users.user.expired') };
            case CouponStatus.VALID:
                return { icon: 'soon-indicator.svg', text: this.translate.instant('users.user.qualified_soon') };
            default:
                return { icon: 'check.svg', text: this.translate.instant('users.user.active') };
        }
    }

    getClassForCoupon(coupon: DashboardCoupon): object {
        const classes = { 'status-text': true };

        switch (coupon.status) {
            case CouponStatus.ACTIVE:
                return { ...classes, 'status-text-success': true };
            case CouponStatus.EXPIRED:
                return { ...classes, 'status-text-err': true };
            case CouponStatus.VALID:
                return { ...classes, 'status-text-warn': true };
            default:
                return { ...classes, 'status-text-success': true };
        }
    }

    getTerms(coupon: Coupon): string {
        return this.helperService.parseTranslationArray(coupon.description);
    }

    getLength(record: any) {
        return record === undefined || typeof record !== 'object' ? 0 : Object.keys(record).length;
    }

    async updateTransaction(transaction: UserTransaction) {
        this.currentUser.transactions[this.transactionIndex] = transaction;

        if (transaction.currency && transaction.currency === this.currentUser.currency) {
            this.currentUser.refunds_sum += transaction.amount;
            if (Object.hasOwn(this.currentUser.refund, transaction.currency)) {
                this.currentUser.refund[transaction.currency] += transaction.amount;
            } else {
                this.currentUser.refund[transaction.currency] = transaction.amount;
            }
        }
        this.refetchActivities();
    }

    refetchActivities() {
        this.showLoadingOnActivity = true;
        this.currentActivityIndex = undefined;
        this.getCurrentUser().then(() => {
            this.showLoadingOnActivity = false;
        });
    }

    getErrorFromDeclineCode(declineCode: string): string {
        const codesToUseDefault: string[] = [
            'call_issuer',
            'do_not_honor',
            'do_not_try_again',
            'no_action_taken',
            'revocation_of_all_authorizations',
            'revocation_of_authorization',
            'security_violation',
            'service_not_allowed',
            'stop_payment_order',
            'transaction_not_allowed',
            'generic_decline'
        ];

        if (codesToUseDefault.includes(declineCode)) {
            return this.translate.instant(`card_decline_codes.generic_decline.error`);
        }
        return this.translate.instant(`card_decline_codes.${declineCode}.error`);
    }

    getDescriptionFromDeclineCode(declineCode: string): string {
        const codesToUseDefault: string[] = [
            'call_issuer',
            'card_velocity_exceeded',
            'do_not_honor',
            'do_not_try_again',
            'fraudulent',
            'generic_decline',
            'lost_card',
            'merchant_blacklist',
            'new_account_information_available',
            'no_action_taken',
            'not_permitted',
            'restricted_card',
            'revocation_of_all_authorizations',
            'revocation_of_authorization',
            'security_violation',
            'service_not_allowed',
            'stolen_card',
            'stop_payment_order',
            'transaction_not_allowed'
        ];
        if (codesToUseDefault.includes(declineCode)) {
            return this.translate.instant(`card_decline_codes.card_declined`);
        }
        return this.translate.instant(`card_decline_codes.${declineCode}.description`);
    }
    getNextStepFromDeclineCode(declineCode: string): string {
        const codesToUseDefault: string[] = [
            'call_issuer',
            'do_not_honor',
            'do_not_try_again',
            'no_action_taken',
            'revocation_of_all_authorizations',
            'revocation_of_authorization',
            'security_violation',
            'service_not_allowed',
            'stop_payment_order',
            'transaction_not_allowed',
            'generic_decline'
        ];
        if (codesToUseDefault.includes(declineCode)) {
            return this.translate.instant(`card_decline_codes.contact_issuer`);
        }
        return this.translate.instant(`card_decline_codes.${declineCode}.next_step`);
    }

    formatPaymentFailedString(input: string): string {
        return `${this.translate.instant('misc.error')}: ${this.getErrorFromDeclineCode(input)}`;
    }

    snakeToReadable(input: string): string {
        const words = input.split('_');
        const formattedString = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        return formattedString;
    }
}
