import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CreditUser } from 'shared_models/credit-payment';
import { CreditPaymentService } from 'src/app/services/credit-payment/credit-payment.service';
import { HelperService } from 'src/app/services/helper/helper.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DashboardUser } from '@dashboard_models/dashboard-user';
import { ActivatedRoute } from '@angular/router';
import { LoadingComponent } from '../../../../loading/loading.component';
import { NgIf } from '@angular/common';
import { CustomModalComponent } from '../../../../misc/custom-modal/custom-modal.component';

@Component({
    selector: 'app-credit-payment-code-modal',
    templateUrl: './credit-payment-code-modal.component.html',
    styleUrls: ['./credit-payment-code-modal.component.scss'],
    standalone: true,
    imports: [CustomModalComponent, NgIf, LoadingComponent, TranslateModule]
})
export class CreditPaymentCodeModalComponent implements OnInit {
    @Input() creditUser: CreditUser;
    @Input() renewCode: boolean;
    @Input() smsNotification: boolean;
    @Input() modalService: NgbModal;
    @Input() isEdit: boolean;
    @Input() locationId: string;
    @Output() paymentCodeEmitter = new EventEmitter<{ smsNotification: boolean; newCode?: string }>();

    renewCodeProcessing: boolean;
    newCode: string;
    copied = false;
    user: DashboardUser;
    isCustomerOperated: boolean;
    uid: string;

    constructor(
        private creditPaymentService: CreditPaymentService,
        private translate: TranslateService,
        private toast: ToastrService,
        private helperService: HelperService,
        private clipboard: Clipboard,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.user = this.helperService.getUser();
        this.isCustomerOperated = window.location.pathname.split('/').includes('customers') ? true : false;
        this.uid = this.isCustomerOperated ? `${this.route.snapshot.paramMap.get('sub_customer_id')}_operated_by_${this.user.uid}` : this.user.uid;
    }

    closePaymentCodeModal(notify: boolean, simplyDismiss?: boolean) {
        this.renewCodeProcessing = true;

        if (simplyDismiss) {
            this.modalService.dismissAll();
            return;
        }

        const codeRequest: { smsNotification: boolean; newCode?: string } = {
            smsNotification: notify ? true : false,
            newCode: this.newCode
        };

        this.paymentCodeEmitter.next(this.newCode ? codeRequest : null);
    }

    async requestNewCode(): Promise<void> {
        this.renewCodeProcessing = true;
        await this.creditPaymentService
            .createCode(this.locationId, this.uid)
            .then(newCodeReq => {
                this.newCode = newCodeReq.code;
            })
            .catch(() => {
                this.toast.warning(this.translate.instant('location.failed_create_new_payment_code'), this.translate.instant('misc.warning'));
                this.renewCodeProcessing = false;
            });
        this.renewCodeProcessing = false;
    }

    formatPaymentCode(paymentCode: string): { partOne: string; partTwo: string } {
        return {
            partOne: String(paymentCode).substring(0, 3),
            partTwo: String(paymentCode).substring(3)
        };
    }

    async copyCodeToClip(value: string) {
        this.clipboard.copy(value);
        this.copied = !this.copied;
        await this.helperService.sleep(1.75 * 1000);
        this.copied = !this.copied;
    }
}
