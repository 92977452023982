import { Component, ElementRef, VERSION, AfterViewInit, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwaggerUIBundle, SwaggerUIStandalonePreset } from 'swagger-ui-dist';
import { DeveloperService } from '../../services/developer/developer.service';
import { DashboardUser } from '@dashboard_models/dashboard-user';
import { SnapshotAction } from '@angular/fire/compat/database';
import { Subscription } from 'rxjs';
import { Clipboard } from '@angular/cdk/clipboard';
import { HelperService } from 'src/app/services/helper/helper.service';
import { environment } from '../../../environments/environment';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CustomModalComponent } from '../misc/custom-modal/custom-modal.component';
import { LoadingComponent } from '../loading/loading.component';
import { NgFor, NgIf } from '@angular/common';
import { AnimatedButtonComponent } from '../misc/animated-button/animated-button.component';

@Component({
    selector: 'app-developer',
    templateUrl: './developer.component.html',
    styleUrls: ['./developer.component.scss'],
    standalone: true,
    imports: [AnimatedButtonComponent, NgFor, NgIf, LoadingComponent, CustomModalComponent, NgSelectModule, FormsModule, TranslateModule]
})
export class DeveloperComponent implements AfterViewInit, OnInit, OnDestroy {
    @ViewChild('swagger') swaggerDom: ElementRef<HTMLDivElement>;
    user: DashboardUser;
    name: string = 'Angular ' + VERSION.major;
    public_key: string;
    api_key: string;
    new_secret_key: string;
    versions: string[] = ['1.0.0', '2.0.0'];
    selectedVersion: string;
    setPublicKeySub: Subscription;
    endpointPaths: { id: number; name: string }[] = [];
    secret_key_copied: boolean;
    api_key_copied: boolean;
    prefixes: string[];
    apiKeysReady = false;

    secretApiKeys: Record<
        string,
        {
            allowed_paths: Record<string, boolean>;
        }
    >;

    selectedEndpointAccess: Record<
        string, // this is the secret api key prefix
        number[] // this is the id of the endpointPaths that are allowed to be accessed
    > = {};

    constructor(
        private developerService: DeveloperService,
        private modalService: NgbModal,
        private clipboard: Clipboard,
        private helperService: HelperService,
        private elementRef: ElementRef
    ) {}

    async ngOnInit() {
        this.user = this.helperService.getUser();
        this.api_key = await this.developerService.getApiKey();
        await this.getPathsFromSwaggerDoc();
        this.secretApiKeys = await this.developerService.getSecretApiKeys();
        this.setSecretKeys();
        this.legacyGetPublicKey(); // Legacy
        this.legacyGetPrivateKeys(); // Legacy
        this.apiKeysReady = true;
    }

    async ngAfterViewInit() {
        this.versionSelect('2.0.0');
        await this.authenticationModalStyling();
    }

    ngOnDestroy(): void {
        this.legacyPrivateKeySub ? this.legacyPrivateKeySub.unsubscribe() : null;
        this.legacyPublicKeySub ? this.legacyPublicKeySub.unsubscribe() : null;
    }

    async setSecretKeys() {
        this.prefixes = Object.keys(this.secretApiKeys);
        for (const prefix in this.secretApiKeys) {
            this.selectedEndpointAccess[prefix] = [];
            for (const pathName in this.secretApiKeys[prefix].allowed_paths) {
                for (const path of this.endpointPaths) {
                    if (path.name === pathName) this.selectedEndpointAccess[prefix].push(path.id);
                }
            }
        }
    }

    generateSecretKey() {
        this.developerService.generateSecretKey().then(res => {
            this.secretApiKeys[res.prefix] = { allowed_paths: {} };
            this.new_secret_key = `${res.prefix}.${res.key}`;
            this.setSecretKeys();
        });
    }

    async revokeSecret(prefix: string) {
        await this.developerService
            .deleteSecretKey(prefix)
            .then(async () => {
                delete this.secretApiKeys[prefix];

                for (const iterator of this.prefixes) {
                    if (iterator === prefix) {
                        this.prefixes.splice(this.prefixes.indexOf(iterator), 1);
                    }
                }
            })
            .catch(error => {
                console.error('Error revoking secret key:', error);
            });
    }

    async saveAPIKeys() {
        for (const prefix in this.selectedEndpointAccess) {
            this.secretApiKeys && this.secretApiKeys[prefix] ? (this.secretApiKeys[prefix].allowed_paths = {}) : null;
            for (const pathId of this.selectedEndpointAccess[prefix]) {
                for (const path in this.endpointPaths) {
                    if (this.endpointPaths[path].id === pathId && this.secretApiKeys[prefix]) this.secretApiKeys[prefix].allowed_paths[this.endpointPaths[path].name] = true;
                }
            }
        }
        await this.developerService
            .updateSecretApiKeys(this.secretApiKeys)
            .then(async () => {
                this.closeModal();
                this.setSecretKeys();
            })
            .catch(error => {
                console.error('Error saving secret keys:', error);
            });
    }

    async getPathsFromSwaggerDoc() {
        const filePath = `assets/swagger-configs/swagger-2.0.0${environment.production ? '-prod' : window.location.hostname === 'localhost' ? '-localhost' : '-usertest'}.yaml`;
        const mainYaml: Record<any, any> = await this.developerService.readYAML(filePath);
        let index = 1;

        if (Object.keys(mainYaml.paths).length)
            for (const path in mainYaml.paths) {
                for (const pathToFile in mainYaml.paths[path]) {
                    const yaml: Record<any, any> = await this.developerService.readYAML(`assets/swagger-configs/${mainYaml.paths[path][pathToFile]}`);
                    for (const method in yaml) {
                        if (method !== 'definitions') {
                            this.endpointPaths.push({ id: index, name: `${method.toUpperCase()} - ${path}` });
                            index++;
                        }
                    }
                }
            }
    }

    copy(toCopy: string) {
        this.clipboard.copy(toCopy);
    }

    copied(str?: string) {
        this.secret_key_copied = false;
        this.api_key_copied = false;
        switch (str) {
            case 'secret':
                this.secret_key_copied = true;
                break;
            case 'api':
                this.api_key_copied = true;
                break;
        }
    }

    openModal(modal: any) {
        this.modalService
            .open(modal, {
                ariaLabelledBy: 'modal-basic-title'
            })
            .result.then((event: any) => {})
            .catch((event: any) => {});
    }

    closeModal() {
        this.new_secret_key = null;
        this.modalService.dismissAll('Cancel');
    }

    // Function to add the CSS rule to hide .try-out elements
    addTryOutStyle() {
        const styleElement = document.createElement('style');
        styleElement.textContent = '.try-out { display: none; }';
        document.head.appendChild(styleElement);
    }

    // Function to remove the special CSS rule for hiding .try-out elements
    removeTryOutStyle() {
        const styleElements = document.head.getElementsByTagName('style');
        for (let i = 0; i < styleElements.length; i++) {
            const styleElement = styleElements[i];
            if (styleElement.textContent.includes('.try-out { display: none; }')) {
                styleElement.parentNode.removeChild(styleElement);
                break; // Remove only the first occurrence
            }
        }
    }

    versionSelect(version: string) {
        this.selectedVersion = version;
        switch (version) {
            case '1.0.0':
                SwaggerUIBundle({
                    url: `${environment.baseFrontUrl}/assets/swagger-configs/swagger-1.0.0${environment.production ? '-prod' : window.location.hostname === 'localhost' ? '-localhost' : '-usertest'}.yaml`,
                    domNode: this.swaggerDom.nativeElement,
                    deepLinking: false,
                    presets: [SwaggerUIBundle.presets.apis, SwaggerUIStandalonePreset]
                });

                // Remove the style
                this.removeTryOutStyle();

                break;
            case '2.0.0':
                SwaggerUIBundle({
                    url: `${environment.baseFrontUrl}/assets/swagger-configs/swagger-2.0.0${environment.production ? '-prod' : window.location.hostname === 'localhost' ? '-localhost' : '-usertest'}.yaml`,
                    domNode: this.swaggerDom.nativeElement,
                    deepLinking: false,
                    presets: [SwaggerUIBundle.presets.apis, SwaggerUIStandalonePreset]
                });

                // Remove the style
                this.removeTryOutStyle();

                break;

            default:
                break;
        }
    }

    /* * * * * * * * * * * * * * * * * * * * * * * * * *
     * * * * * * * * * * * * * * * * * * * * * * * * * *
     * * * * * * * * * Legacy below  * * * * * * * * * *
     * * * * * * * * * * * * * * * * * * * * * * * * * *
     * * * * * * * * * * * * * * * * * * * * * * * * * */
    legacyPrivateKeySub: Subscription;
    legacyPublicKeySub: Subscription;
    legacy_private_key_copied: boolean;
    legacy_public_key_copied: boolean;
    legacy_prefixes: string[];
    legacy_new_private_key: string;

    legacyGetPrivateKeys() {
        this.legacyPrivateKeySub = this.developerService
            .readPrivateKeys(this.user.uid)
            .snapshotChanges()
            .subscribe((privateKeysSnap: SnapshotAction<Record<string, string>>) => {
                if (privateKeysSnap.payload.exists()) {
                    this.legacy_prefixes = Object.keys(privateKeysSnap.payload.val());
                } else {
                    this.legacy_prefixes = [];
                    this.legacy_new_private_key = '';
                }
            });
    }

    legacyGetPublicKey() {
        this.legacyPublicKeySub = this.developerService
            .readPublicKey(this.user.uid)
            .snapshotChanges()
            .subscribe((publicKeySnap: SnapshotAction<Record<string, string>>) => {
                if (publicKeySnap.payload.exists()) {
                    this.public_key = publicKeySnap.payload.val() as unknown as string;
                } else {
                    this.legacy_new_private_key = this.developerService.legacyGeneratePublicKey(this.user.uid);
                }
            });
    }

    legacyRevokePrivateKey(key_prefix: string) {
        this.developerService.legacyRevokePrivateKey(this.user.uid, key_prefix);
    }

    legacyCloseModal() {
        this.legacy_new_private_key = null;
        this.modalService.dismissAll('Cancel');
    }

    legacyCopy(toCopy: string) {
        this.clipboard.copy(toCopy);
    }

    legacyCopied(str?: string) {
        this.legacy_private_key_copied = false;
        this.legacy_public_key_copied = false;
        switch (str) {
            case 'private':
                this.legacy_private_key_copied = true;
                break;
            case 'public':
                this.legacy_public_key_copied = true;
                break;
        }
    }

    legacyGeneratePrivateKey() {
        this.legacy_new_private_key = this.developerService.legacyGeneratePrivateKey(this.user.uid, this.legacy_prefixes);
    }

    async authenticationModalStyling() {
        // styling in the authentification modal for removing weird text ("&nbsp;(apiKey)" => a space with the text "(apiKey)") in the h4 elements
        await this.helperService.sleep(500); // to ensure the authorize button is rendered
        const buttonElement = document.querySelector('.btn.authorize.unlocked');
        if (buttonElement) {
            // Add a click event listener to the button element
            buttonElement.addEventListener('click', async () => {
                // When clicked look for the auth-container elements
                let h4Elements = document.querySelectorAll('.auth-container h4');
                let waitTime = 0;
                while (!h4Elements.length) {
                    if (waitTime > 800) {
                        break;
                    }
                    h4Elements = document.querySelectorAll('.auth-container h4');
                    await this.helperService.sleep(10);
                    waitTime += 10;
                }
                if (h4Elements.length) {
                    // Loop through each <h4> element
                    h4Elements.forEach(h4Element => {
                        // Check if the innerHTML contains "&nbsp;(apiKey)"
                        if (h4Element.innerHTML.includes('&nbsp;(apiKey)')) {
                            // Replace the specific text with an empty string
                            h4Element.innerHTML = h4Element.innerHTML.replace('&nbsp;(apiKey)', '');
                        }
                    });
                }
            });
        }
    }
}
