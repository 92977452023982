import { Component, OnDestroy, OnInit } from '@angular/core';
import { Device, sortParams } from '../../../../../../shared_models/operator/allDevices';
import { DeviceService } from '../../../../services/device/device.service';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import moment from 'moment';
import { OperatorService } from '../../../../services/operator/operator.service';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { HelperService } from '../../../../services/helper/helper.service';
import hash from 'object-hash';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { PriceUnit } from '../../../../../../shared_models/device';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DashboardUser } from '../../../../../dashboard-models/dashboard-user';
import { Observable } from 'rxjs';
import * as Claims from 'shared_models/claims';
import { AuthService } from '../../../../services/auth/auth.service';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { PaginatePipe } from '../../../../pipe/paginate.pipe';
import { SpecificDeviceComponent } from '../specific-device/specific-device.component';
import { LoadingComponent } from '../../../loading/loading.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { DateTimeService } from '@services/date-time/date-time.service';

@Component({
    selector: 'app-device-overview',
    templateUrl: './device-overview.component.html',
    styleUrls: ['./device-overview.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, RouterLink, LoadingComponent, MatPaginator, SpecificDeviceComponent, AsyncPipe, TranslateModule, PaginatePipe]
})
export class DeviceOverviewComponent implements OnInit, OnDestroy {
    isMobile = false;
    role$: Observable<Claims.Roles>;

    fromDate;
    toDate;
    devices: Device[] = [];
    loading = false;
    initLoading = true;
    pageSize = 10;
    pageNumber = 0;
    pageSizeOptions = [10, 50, 100];
    sortBy: sortParams = sortParams.device_name;
    sortingAccending = true;
    getDevicesFingerprint: string;
    specificDevice: Device;
    originalDeviceArr: Device[] = [];
    user: DashboardUser;
    uid: string;
    filterByMaintenance = false;

    private ngUnsubscribe = new Subject<void>();

    constructor(
        public authService: AuthService, // used in html
        private deviceService: DeviceService,
        private operatorService: OperatorService,
        private router: Router,
        private helperService: HelperService,
        private translateService: TranslateService,
        private breakpointObserver: BreakpointObserver,
        private modalService: NgbModal,
        private dateTimeService: DateTimeService
    ) {
        this.breakpointObserver.observe(['(max-width: 768px)']).subscribe((result: BreakpointState) => {
            this.isMobile = result.matches;
        });
        operatorService.selectedFrom$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
            this.fromDate = value;
            if (!this.initLoading) {
                this.getDevices();
            }
        });
        operatorService.selectedTo$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
            this.toDate = value;
            if (!this.initLoading) {
                this.getDevices();
            }
        });
        operatorService.selectedMaintenance$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
            this.filterByMaintenance = value;
            if (!this.initLoading) {
                if (this.filterByMaintenance) {
                    this.devices = this.devices.filter(device => {
                        return device.maintenance === this.filterByMaintenance;
                    });
                } else {
                    this.devices = this.originalDeviceArr;
                    this.sort(this.sortBy, true);
                }
            }
        });
    }

    ngOnInit(): void {
        this.role$ = this.authService.getRole;
        moment.locale(this.translateService.currentLang);
        this.user = this.helperService.getUser();
        /* this.uid = this.helperService.getUidWithType(this.activatedRoute.snapshot.paramMap.get('sub_customer_id'), this.user.settings.controlledUid, this.user.uid).uid; */
        this.loading = true;
        this.getDevices();
        this.initLoading = false;
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        this.operatorService.setMaintenanceValue(false);
    }

    async getDevices() {
        this.loading = true;
        let params = `fromDate=${this.fromDate}&toDate=${this.toDate}`;
        this.getDevicesFingerprint = hash.keys(`${params}${moment()}`);
        params += `&fingerprint=${this.getDevicesFingerprint}`;
        const deviceData = await this.deviceService.getDevicesForOverview(params).then(response => {
            this.loading = false;
            return response;
        });
        if (this.getDevicesFingerprint === deviceData['info']['fingerprint']) {
            this.devices = deviceData.data;
            this.originalDeviceArr = deviceData.data;
            this.sort(this.sortBy, true);
        }

        if (this.filterByMaintenance) {
            this.devices = this.devices.filter(device => {
                return device.maintenance === this.filterByMaintenance;
            });
        }
    }

    getLink(name: string, customerUid: string, locationKey?: string, deviceKey?: string): void {
        let link = '/locations';
        if (customerUid.includes('operated_by')) {
            const extrapolatedUid: string = customerUid.substring(0, customerUid.indexOf('_'));
            link = `customers/${extrapolatedUid}/locations`;
        }
        if (locationKey) {
            link = link.concat(`/${locationKey}`);
            if (deviceKey) {
                link = link.concat(`/devices/${deviceKey}`);
            }
        }

        this.router.navigate([link], {
            queryParams: {
                name
            }
        });
    }

    sort(sortBy: string, skipFlip?: boolean): void {
        if (!skipFlip) {
            this.sortingAccending = sortBy === this.sortBy ? !this.sortingAccending : false;
        }
        this.sortBy = sortBy as sortParams;
        function propCompare(prop: sortParams, ascending: boolean) {
            return function compare(a, b) {
                a[prop] === '-' ? (a[prop] = 0) : null;
                b[prop] === '-' ? (b[prop] = 0) : null;
                if (typeof a[prop] === 'string') {
                    return ascending ? a[prop].localeCompare(b[prop], 'en', { numeric: true, sensitivity: 'base' }) : b[prop].localeCompare(a[prop], 'en', { numeric: true, sensitivity: 'base' });
                }
                return ascending ? a[prop] - b[prop] : b[prop] - a[prop];
            };
        }
        this.devices = this.devices.slice().sort(propCompare(this.sortBy, this.sortingAccending));
    }

    openModal(modal: any, device: any) {
        //Responsible for passing the contract to contract-form-modal
        this.specificDevice = device;
        const modalOptions: NgbModalOptions = {
            ariaLabelledBy: 'modal-basic-title',
            size: 'lg'
        };
        const modalRef: NgbModalRef = this.modalService.open(modal, modalOptions);

        modalRef.result.then(
            () => {
                // on close
            },
            () => {
                // on error/dismiss
                // to remove the selected order and also reset the forced styling
            }
        );
    }

    handlePage(e: PageEvent) {
        this.pageSize = e.pageSize;
        this.pageNumber = e.pageIndex;
    }

    getPeriod(milsStamp: number) {
        return this.dateTimeService.getDateAndTime(milsStamp, true, true);
    }

    round(num: number | null): string {
        return num ? `${this.helperService.roundToTwoDecimals(num)}` : '0.00';
    }
}
