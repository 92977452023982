<!-- nav bar -->
<app-navbar *ngIf="isSignedIn$ | async"></app-navbar>
<div id="push-content-with-margin" *ngIf="isSignedIn$ | async"></div>
<!-- app -->
@defer (when (isRolesSet$ | async) || (isSignedIn$ | async) === false) {
    <router-outlet></router-outlet>
} @placeholder {
    <loading-indicator [size]="'large'" [color]="'secondary'"></loading-indicator>
}




