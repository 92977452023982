<app-custom-modal>
    <ng-container modal-title>
        <h4>{{'transactions.refund_transaction' | translate}}</h4>
    </ng-container>
    <ng-container modal-dismiss>
        <a (click)="modalService.dismissAll('Cancel')"><img src="../../../assets/icons/modal-close-icon.svg"
                alt="close dialog"></a>
    </ng-container>
    <ng-container modal-body>
        <p>{{'transactions.refund_please_select' | translate}}</p>
        <div class="refund-modal">
            <label for="refund" class="mb-1">{{'transactions.refund_tooltip_reason' | translate}}</label>
            <ng-select id="refund" [(ngModel)]="refundReason" [searchable]="false" [clearable]="false" class="common-input">
                <ng-option value="requested_by_customer">{{'transactions.refund_reason_requested' | translate}}
                </ng-option>
                <ng-option value="duplicate">{{'transactions.refund_reason_duplicate' | translate}}</ng-option>
                <ng-option value="fraudulent">{{'transactions.refund_reason_fraudulent' | translate}}</ng-option>
                <ng-option value="other">{{'transactions.refund_reason_other' | translate}}</ng-option>
            </ng-select>
            <div class="mt-3" *ngIf="refundReason === 'other'">
                <input class="form-control" [(ngModel)]="otherReason"
                    [placeholder]="this.translate.instant('transactions.refund_enter_reason')" #reasonInput
                    (focus)="placeholderText = reasonInput.placeholder; reasonInput.placeholder = ''"
                    (blur)="reasonInput.placeholder = placeholderText">
            </div>
        </div>
    </ng-container>
    <ng-container modal-footer>
        <div class="d-grid gap-2 mt-3">
            <button class="btn btn-secondary" tabindex="-1" (click)="refund(refundReason)">
                {{showSmallLoadingIndicator ? ' ' : 'transactions.refund' | translate}}
                <loading-indicator *ngIf="showSmallLoadingIndicator" [size]="'small'"
                    [color]="'white'"></loading-indicator>
            </button>
        </div>
    </ng-container>
</app-custom-modal>