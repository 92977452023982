import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ResetPasswordService {
    constructor(private http: HttpClient) {}

    async setPassword(resetKey: string, password: string): Promise<any> {
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(this.http.put(`${environment.baseUrl}/api_account/reset_password`, { resetKey, password }))
                .then((res: any) => {
                    return resolve(res);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    async requestResetPasswordEmail(email: string): Promise<any> {
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(this.http.post(`${environment.baseUrl}/api_account/reset_password`, { email }))
                .then((res: any) => {
                    return resolve(res);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }
}
