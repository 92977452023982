<form [formGroup]="signUpForm" autocomplete="off" (keydown.enter)="signUp()">
    @if (companyIndividualSignUp()) {
        <div class="input-group-wrapper-feedback business-type-picker">
            <label for="company-or-individual1">{{ "sign_up.business_type" | translate }}</label>
            <div class="aw-radio-wrapper">
                <div class="aw-radio">
                    <input type="radio" class="" value="company" name="businessType" formControlName="businessType" id="company-or-individual1" />
                    <label class="" for="company-or-individual1">
                        <span class="aw-radio-label-span">{{ "sign_up.company_partnership" | translate }}</span>
                    </label>
                </div>

                <div class="aw-radio">
                    <input type="radio" class="" value="individual" name="businessType" formControlName="businessType" id="company-or-individual2" />
                    <label class="" for="company-or-individual2">
                        <span class="aw-radio-label-span">{{ "sign_up.individual_person" | translate }}</span>
                    </label>
                </div>
            </div>
            <div class="feedback-container">
                @if (formSubmitted && businessType.invalid) {
                    <div class="error-msg" *ngIf="businessType.errors.required">
                        {{ "sign_up.business_type_req" | translate }}
                    </div>
                }
            </div>
        </div>
    }

    <div class="input-group-wrapper-feedback">
        <label for="country-select">{{ "misc.country" | translate }}</label>
        <select class="form-select" id="country-select" autocomplete="country" name="country" formControlName="country">
            <option selected disabled value="null">{{ "sign_up.choose_country" | translate }}</option>
            <option [ngValue]="country" *ngFor="let country of countries">
                {{ helperService.getCountryTranslated(country) }}
            </option>
        </select>
        <div class="feedback-container">
            @if (formSubmitted && country.invalid) {
                <div class="error-msg" *ngIf="country.errors.required">
                    {{ "sign_up.country_req" | translate }}
                </div>
            }
        </div>
    </div>

    <div class="input-group-wrapper-feedback">
        <label for="email">{{ "misc.email" | translate }}</label>
        <input class="form-control" type="email" autocomplete="email" id="email" name="email" for="email" formControlName="email" />
        <div class="feedback-container">
            @if (formSubmitted && email.invalid) {
                <div class="error-msg" *ngIf="email.errors.required">
                    {{ "misc.email_required" | translate }}
                </div>
            }
        </div>
    </div>

    <div class="input-group-wrapper-feedback">
        <label for="password">{{ "misc.password" | translate }}</label>
        <input autocomplete="new-password" id="password" name="password" class="form-control" type="password" formControlName="password" />
        <div class="feedback-container">
            @if (formSubmitted && password.invalid) {
                <div class="error-msg" *ngIf="password.errors.required">
                    {{ "misc.password_required" | translate }}
                </div>
                <div class="error-msg" *ngIf="password.errors.maxlength">
                    {{ "misc.password_too_long" | translate }}
                </div>
                <div class="error-msg" *ngIf="password.errors.minlength">
                    {{ "misc.password_too_short" | translate }}
                </div>
                <div class="error-msg" *ngIf="password.errors.weakPassword">
                    {{ "misc.password_is_weak" | translate }}
                </div>
            }
        </div>
    </div>

    <div class="input-group-wrapper-feedback">
        <label for="repeatPassword">{{ "sign_up.repeat_password" | translate }}</label>
        <input autocomplete="new-repeatPassword" name="repeatPassword" id="repeatPassword" class="form-control" type="password" formControlName="repeatPassword" />
        <div class="feedback-container">
            @if (formSubmitted && repeatPassword.invalid) {
                <div class="error-msg" *ngIf="repeatPassword.errors.required">
                    {{ "sign_up.password_need_repeat" | translate }}
                </div>
                <div class="error-msg" *ngIf="repeatPassword.errors && repeatPassword.errors.pwNoMatch">
                    {{ "sign_up.password_not_matching" | translate }}
                </div>
            }
        </div>
    </div>

    <div class="input-group-wrapper-feedback">
        <div class="checkbox">
            <app-aw-checkbox id="terms_check" [initialCheckedValue]="termsChecked" (checkStateEmitter)="toggleConsent('terms')" />
            <label for="terms_check"
                >{{ "sign_up.accept" | translate }}
                <a class="primary-color" href="{{ pickedRegion === 'eu' ? termsOfUse : '' }}" target="_blank" tabindex="-1">{{ "sign_up.terms_n_conds" | translate }}</a>
            </label>
        </div>
        <div class="feedback-container">
            <div class="error-msg" *ngIf="formSubmitted && !termsChecked">
                {{ "sign_up.terms_need_accept" | translate }}
            </div>
        </div>
    </div>

    <div class="input-group-wrapper-feedback terms">
        <div class="checkbox">
            <app-aw-checkbox id="gdpr_check" [initialCheckedValue]="gdprChecked" (checkStateEmitter)="toggleConsent('gdpr')" />
            <label for="gdpr_check"
                >{{ "sign_up.accept" | translate }}
                <a class="primary-color" href="{{ pickedRegion === 'eu' ? gdprPolicy : '' }}" target="_blank" tabindex="-1">{{ "misc.gdpr" | translate }}</a>
            </label>
        </div>
        <div class="feedback-container">
            <div class="error-msg" *ngIf="formSubmitted && !gdprChecked">
                {{ "sign_up.gdpr_needs_accept" | translate }}
            </div>
        </div>
    </div>
</form>
