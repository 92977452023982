import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomerService } from '../../services/customer/customer.service';
import { ToastrService } from 'ngx-toastr';
import { countries } from 'shared_data/countries';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { DashboardUser } from '@dashboard_models/dashboard-user';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { HelperService } from 'src/app/services/helper/helper.service';
import { CreateSubCustomerParams } from 'shared_models/sub-customer';
import { Title } from '@angular/platform-browser';
import { CountryDetails } from 'shared_models/country-details';
import { BusinessType } from 'shared_models/stripe';
import { HttpErrorResponse } from '@angular/common/http';
import { LoadingComponent } from '../loading/loading.component';
import { NgIf, NgFor } from '@angular/common';
import { AwCheckboxComponent } from '@components/misc/aw-checkbox/aw-checkbox.component';

@Component({
    selector: 'app-customers-create',
    templateUrl: './customers-create.component.html',
    styleUrls: ['./customers-create.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, NgFor, LoadingComponent, TranslateModule, AwCheckboxComponent]
})
export class CustomersCreateComponent implements OnInit {
    createSubCustomerForm: UntypedFormGroup;
    gdprChecked: boolean;
    termsChecked: boolean;
    formSubmitted = false;
    countries: CountryDetails[] = Object.values(countries);
    showLoading = false;
    createAccountBtnLabel = 'Create an account';
    supportPhone: {
        phoneNumber: string;
        telLink: string;
    } = environment.supportPhone;
    gdprPolicy: string = environment.gdprPolicy;
    termsOfUse: string = environment.termsOfUse;
    isNewSubCustomer: boolean;
    user: DashboardUser;

    constructor(
        private router: Router,
        private formBuilder: UntypedFormBuilder,
        private translate: TranslateService,
        private titleService: Title,
        private customerService: CustomerService,
        private toast: ToastrService,
        protected helperService: HelperService
    ) {}

    ngOnInit() {
        this.titleService.setTitle('Create customer');
        const user = this.helperService.getUser();
        this.user = user;
        this.setupSubCustomerForm();

        this.countries = this.countries
            .filter(function (countryItem) {
                return countryItem.platform === user.settings.stripe_region;
            })
            .sort(function (a, b) {
                // Assuming "country" is a string property, adjust the comparison accordingly
                return a.country.localeCompare(b.country);
            });
    }

    get email() {
        return this.createSubCustomerForm.get('email');
    }
    get country() {
        return this.createSubCustomerForm.get('country');
    }
    get businessType() {
        return this.createSubCustomerForm.get('businessType');
    }

    setupSubCustomerForm() {
        this.createSubCustomerForm = this.formBuilder.group({
            email: [null, [Validators.required, Validators.maxLength(255)]],
            country: [null, Validators.required],
            businessType: [null, Validators.required]
        });
    }

    createCustomer() {
        this.formSubmitted = true;
        this.showLoading = true;
        this.createAccountBtnLabel = this.translate.instant('customers-create.wait');

        if (this.country && this.country.value !== 'DK') {
            this.businessType.patchValue(BusinessType.COMPANY);
        }

        if (this.createSubCustomerForm.valid && this.gdprChecked && this.termsChecked) {
            const emailLowerCased = this.createSubCustomerForm.value['email'].replace(/\s/g, '').toLowerCase();
            const createParams: CreateSubCustomerParams = {
                email: emailLowerCased,
                business_type: this.businessType.value,
                country: this.country.value,
                share: 0
            };

            this.customerService
                .createSubCustomer(createParams)
                .then(() => {
                    this.showLoading = false;
                    this.toast.success(this.translate.instant('customers-create.succes_created'), this.translate.instant('misc.success'));
                    this.createSubCustomerForm.patchValue(
                        {
                            email: null,
                            country: null,
                            businessType: null
                        },
                        { emitEvent: false }
                    );
                    this.router.navigate(['/operator/customers']);
                })
                .catch((httpResponseError: HttpErrorResponse) => {
                    const { error } = httpResponseError.error;
                    this.toast.info(this.translate.instant(error.showToast ? error.showToast.description : 'customers-create.something_wrong'), this.translate.instant('misc.error'), { timeOut: 15000 });
                    this.showLoading = false;
                });
        } else {
            this.showLoading = false;
        }
    }

    toggleConsent(typeOfConsent: string) {
        if (typeOfConsent == 'terms' && !this.termsChecked) {
            this.termsChecked = true;
        } else if (typeOfConsent == 'terms' && this.termsChecked) {
            this.termsChecked = false;
        }

        if (typeOfConsent == 'gdpr' && !this.gdprChecked) {
            this.gdprChecked = true;
        } else if (typeOfConsent == 'gdpr' && this.gdprChecked) {
            this.gdprChecked = false;
        }
    }
}
