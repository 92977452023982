<app-custom-modal>
  <ng-container modal-title>
    <h4>{{ 'users.user.coupons.edit_coupons' | translate }}</h4>
  </ng-container>
  <ng-container modal-dismiss>
    <a (click)="closeModal()"><img src="../../../assets/icons/modal-close-icon.svg" alt="close dialog"></a>
  </ng-container>
  <ng-container modal-body>
    <div class="modal-body-wrapper">

      <div class="mb-2">
        <strong class="modal-body-title">{{ 'users.user.coupons.coupons_applied' | translate }}</strong>
      </div>

      <!-- List -->
      <div class="coupon-list">
        <div class="coupon" *ngFor="let coupon of shownAppliedCoupons; let i = index">
          <img class="coupon-img" src="../../../../../assets/icons/coupon-applied.svg" alt="coupon icon"
               style="width: 23px;
                    height: 16px;">
          <span [title]="coupon.name" class="coupon-name">{{ coupon.name }}</span>
          <span [title]="getTerms(coupon)" class="coupon-type">{{ getTerms(coupon) }}</span>

          <div (mouseenter)="showInfo(true, i)" (mouseleave)="showInfo(false, i)"
               class="d-flex align-items-center">
            <img *ngIf=" (!isExcluding) || (isExcluding && currentIndex !==i)" class="trash-img"
                 src="../../../../../assets/icons/trash-{{isForAll(coupon) ? 'grey' : 'blue'}}.svg"
                 alt="delete icon" style="width: 13px;
                    height: 15px;" [ngStyle]="{'cursor': isForAll(coupon) ? 'default' : 'pointer'}"
                 (click)="isForAll(coupon) ? showInfo(!isShowingInfo, i) : excludeCoupon(coupon, i);">
          </div>

          <loading-indicator class="loading position" *ngIf="isExcluding && i === currentIndex"
                             [size]="'tiny'" [color]="'secondary'"></loading-indicator>

          <div *ngIf="isShowingInfo && i === currentIndex && isForAll(coupon)"
               [className]="isShowingInfo && i === currentIndex && isForAll(coupon) ? 'tool-tip show-opacity' : 'tool-tip'">
            <div class="pointy-tip"></div>
            <div class="content">
              <span>{{ 'users.user.is_all_coupons' | translate }}</span>
            </div>
          </div>
        </div>
      </div>

      <form [formGroup]="couponForm" [className]="shownAppliedCoupons.length?'mt-2':''">
        <ng-select *ngIf="!isApplying" [hidden]="!shownAvailableCoupons.length" #select
                   (change)="updateAppliedCoupons($event)"
                   [searchable]="false"
                   [clearable]="false"
                   [placeholder]="shownAppliedCoupons.length ?
                    ('users.user.coupons.choose_another_coupon' | translate)
                    : ('users.user.coupons.choose_a_coupon' | translate)"
                   formControlName="selected_coupon"
                   class="common-input">
          <ng-option [value]="coupon.key" *ngFor="let coupon of shownAvailableCoupons; let i = index">
            {{ !isApplying ? (coupon.name + ' - ' + getTerms(coupon)) : '' }}
          </ng-option>
        </ng-select>
      </form>


    </div>
  </ng-container>
  <ng-container modal-footer>
    <div class="d-flex flex-column align-items-center">
      <button class="btn btn-secondary mt-3" style="width: 100%" [disabled]="hasSubmitted"
              (click)="applyChanges()">
        {{ hasSubmitted ? '' : ('device.save_changes' | translate) }}
        <loading-indicator *ngIf="hasSubmitted" [size]="'large'" [color]="'white'"></loading-indicator>
      </button>
    </div>
  </ng-container>
</app-custom-modal>