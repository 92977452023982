import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../services/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { DashboardUser } from '@dashboard_models/dashboard-user';
import { HelperService } from 'src/app/services/helper/helper.service';
import { lastValueFrom } from 'rxjs';

@Component({
    template: ''
})
export class StripelinkComponent implements OnInit {
    user: DashboardUser;
    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private helperService: HelperService
    ) {}

    async ngOnInit() {
        this.user = this.helperService.getUser();
        this.activatedRoute.queryParams
            .subscribe(async params => {
                if (params.uid) {
                    if (this.user.uid.includes(params.uid)) {
                        await this.openStripe();
                    } else {
                        this.authService.signOut(false, decodeURIComponent(params.mail));
                    }
                } else {
                    await this.openStripe();
                }
            })
            .unsubscribe();
    }

    async openStripe() {
        lastValueFrom(this.http.get(`${environment.baseUrl}/api_account/stripe_kyc_link?success_url=${window.location.origin}&failure_url=${window.location.origin}`))
            .then((res: any) => {
                window.open(res.url, '_self');
            })
            .catch(err => {
                console.error(err);
            });
    }
}
