import { CdkDragDrop, moveItemInArray, CdkDropList, CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import * as Claims from 'shared_models/claims';
import { DashboardUser } from '@dashboard_models/dashboard-user';
import { AuthService } from 'src/app/services/auth/auth.service';
import { HelperService } from 'src/app/services/helper/helper.service';
import { CardService } from 'src/app/services/location/card/card.service';
import { LocationService } from 'src/app/services/location/location.service';
import { Unit } from 'shared_models/device';
import { LoadingComponent } from '../../loading/loading.component';
import { NgFor, NgIf, AsyncPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CustomModalComponent } from '../../misc/custom-modal/custom-modal.component';

@Component({
    selector: 'app-device-order-modal',
    templateUrl: './device-order-modal.component.html',
    styleUrls: ['./device-order-modal.component.scss'],
    standalone: true,
    imports: [CustomModalComponent, FormsModule, CdkDropList, NgFor, CdkDrag, CdkDragHandle, NgIf, LoadingComponent, AsyncPipe, TranslateModule]
})
export class DeviceOrderModalComponent implements OnInit {
    @Input() deviceList: Unit[];
    @Input() locationId: string;
    @Input() modalRef: NgbModalRef;
    @Output() UpdateListEmitter = new EventEmitter<Unit[]>();

    role$: Observable<Claims.Roles> = this.authService.getRole;
    user: DashboardUser;
    orderedUnitArr: Unit[] = [];
    savingDeviceOrdering = false;
    removingWeight = false;
    subCustomerAllowed: boolean;
    uid: string;

    constructor(
        private helperService: HelperService,
        private translate: TranslateService,
        private toast: ToastrService,
        public authService: AuthService,
        public modalService: NgbModal,
        private locationService: LocationService,
        public cardService: CardService,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.user = this.helperService.getUser();
        const isCustomerOperated: boolean = window.location.pathname.split('/').includes('customers') ? true : false;
        this.uid = isCustomerOperated ? `${this.route.snapshot.paramMap.get('sub_customer_id')}_operated_by_${this.user.uid}` : this.user.uid;
        this.orderedUnitArr = Object.assign([], this.locationService.getDevices());
    }

    //dragndrop for device modal
    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.orderedUnitArr, event.previousIndex, event.currentIndex);
    }

    moveUp(i: number) {
        if (i === 0) {
            return;
        }
        const array = this.orderedUnitArr;
        const unit = array.splice(i, 1)[0];
        array.splice(i - 1, 0, unit);
        this.orderedUnitArr = array;
    }

    moveDown(i: number) {
        if (i + 1 > this.orderedUnitArr.length) {
            return;
        }
        const array = this.orderedUnitArr;
        const unit = array.splice(i, 1)[0];
        array.splice(i + 1, 0, unit);
        this.orderedUnitArr = array;
    }

    saveOrderSettings() {
        if (this.orderedUnitArr.length > 0) {
            this.savingDeviceOrdering = true;
            const deviceWeights: Record<string, number> = {};
            for (const i in this.orderedUnitArr) {
                const value = this.orderedUnitArr[i];
                deviceWeights[value.id] = Number(i);
            }
            this.locationService
                .sortDevices({ locationId: this.locationId, devices: deviceWeights }, this.uid)
                .then(value => {
                    this.toast.success(this.translate.instant('location.device_order_success'), this.translate.instant('misc.success'));
                    this.modalRef.close();
                    this.savingDeviceOrdering = false;
                    this.UpdateListEmitter.next(Object.values(value.devices));
                })
                .catch(() => {
                    this.toast.error(this.translate.instant('transactions.err_occurred'), this.translate.instant('misc.error'));
                    this.savingDeviceOrdering = false;
                });
        }
    }

    resetWeightedDevices() {
        this.removingWeight = true;
        this.locationService.resetSortDevices(this.locationId, this.uid).then(() => {
            this.removingWeight = false;
            this.toast.success(this.translate.instant('location.device_order_success'), this.translate.instant('misc.success'));
            this.modalRef.close();
        });
    }
}
