import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

export class MyCustomPaginatorIntl extends MatPaginatorIntl {
    // The paginator will show 1 - 10 of 11+ if the total count is larger than capPaginatorAt
    capPaginatorAt = 0;
    previousCap = 0;
    paginatorOf = this.translate.instant('paginator.of');

    constructor(
        private translate: TranslateService,
        capPaginatorAt?: number
    ) {
        super();
        if (capPaginatorAt !== 0) {
            this.capPaginatorAt = capPaginatorAt;
        }
    }

    capReached = false;
    itemsPerPageLabel = this.translate.instant('paginator.items_per_page');
    getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (length === 0 || pageSize === 0) {
            return `0 ${this.paginatorOf} ${length}`;
        }

        length = Math.max(length, 0);
        if (this.previousCap !== length) {
            this.capReached = false;
        }
        const startIndex: number = page * pageSize;
        const endIndex: number = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

        if ((length - 1) % pageSize !== 0 && endIndex >= length) {
            this.previousCap = length;
            this.capReached = true;
        }

        const tableIndex = `${startIndex + 1} - ${endIndex}`;
        const tableLength = `${this.capPaginatorAt && length > this.capPaginatorAt && !this.capReached ? length - 1 + '+' : length}`;

        return `${tableIndex} ${this.paginatorOf} ${tableLength}`; // + if more than capPaginatorAt - this is as of end of 2024 only a temporary solution for transactions count
    };
}
