import { Component, OnDestroy, OnInit, WritableSignal } from '@angular/core';
import { Router } from '@angular/router';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../services/auth/auth.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from '../../../services/helper/helper.service';
import { DashboardUser } from '../../../../dashboard-models/dashboard-user';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { ResetPasswordService } from '../../../services/reset-password/reset-password.service';
import { CustomModalComponent } from '../../misc/custom-modal/custom-modal.component';
import { LoadingComponent } from '../../loading/loading.component';
import { NgIf } from '@angular/common';
import { LocalStorageService } from '../../../services/local-storage/local-storage.service';
import { AwOtpInputComponent } from '../../misc/aw-otp-input/aw-otp-input.component';
import { getAuth, MultiFactorError } from 'firebase/auth';
import firebase from 'firebase/compat';
import { AuthPageService } from '@services/auth/auth-page.service';
import { fade } from '../../../animations/fade';

@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss'],
    standalone: true,
    animations: [fade],
    imports: [NgIf, FormsModule, ReactiveFormsModule, LoadingComponent, CustomModalComponent, TranslateModule, AwOtpInputComponent]
})
export class SignInComponent implements OnInit, OnDestroy {
    loading: WritableSignal<boolean>;

    formSubmitted = false;
    signInForm: UntypedFormGroup;

    resetPWLoading: boolean;

    mfaEnabled: WritableSignal<boolean>;
    mfaError: MultiFactorError;
    otpCode: string;
    loadingMfaCheck = false;

    constructor(
        private router: Router,
        private authService: AuthService,
        private localStorageService: LocalStorageService,
        protected modalService: NgbModal,
        private formBuilder: UntypedFormBuilder,
        private toast: ToastrService,
        private translate: TranslateService,
        private helperService: HelperService,
        private authPageService: AuthPageService,
        private title: Title,
        private resetPasswordService: ResetPasswordService
    ) {
        this.loading = this.authPageService.loading;
        this.mfaEnabled = this.authPageService.mfaSignIn;
    }

    async ngOnInit() {
        this.title.setTitle('Sign in');
        await this.handleSignIn();
    }

    ngOnDestroy() {
        this.loading.set(false);
        this.mfaEnabled.set(false);
    }

    async handleSignIn() {
        this.checkForToast();
        this.checkForIncorrectUser();
        const prefillMail: string = this.checkForPrefillMail();
        const user: DashboardUser = this.helperService.getUser(true);
        if (user) this.router.navigate([`/overview`]);
        this.signInForm = this.formBuilder.group({
            email: new UntypedFormControl(prefillMail ? prefillMail : null, [Validators.required]),
            password: new UntypedFormControl(null, [Validators.required])
        });
    }

    checkForIncorrectUser() {
        if (!this.helperService.compareUserToLocalStorage('user')) {
            this.localStorageService.removeItem('user');
        }
    }

    checkForPrefillMail(): string {
        const prefillMail: string = this.localStorageService.getItem('prefillMail');
        if (prefillMail) {
            this.localStorageService.removeItem('prefillMail');
        }
        return prefillMail;
    }

    checkForToast(): void {
        const diplayToast: 'true' | 'false' = this.localStorageService.getItem('passwordSet');
        if (diplayToast === 'true') {
            this.localStorageService.removeItem('prefillMail');
            this.helperService.defaultHtmlToast(this.translate.instant('customers-set-password.password_set'), this.translate.instant('customers-set-password.please_sign_in'), 'Info', { timeOut: 10000 });
        }
    }

    get password() {
        return this.signInForm.get('password');
    }

    get email() {
        return this.signInForm.get('email');
    }

    signIn() {
        this.formSubmitted = true;
        if (this.signInForm.valid) {
            this.loading.set(true);
            this.authService.signOut(true); // Sign out before signing in to avoid two active sessions at the same time
            if (this.toast && this.toast.toasts) {
                for (const toast of this.toast.toasts) {
                    this.toast.remove(toast.toastId);
                }
            }
            const emailLowerCased = this.signInForm.value['email'].replace(/\s/g, '').toLowerCase();
            this.authService.signIn(emailLowerCased, this.signInForm.value['password']).catch(() => {
                this.toast.warning(this.translate.instant('sign-in.err_proccess_log_in'), this.translate.instant('misc.attention'));
                this.loading.set(false);
            });
        }
    }

    async catchOtpEvent(otp: string) {
        this.otpCode = otp;
        await this.handleMfa();
    }

    async handleMfa() {
        this.loading.set(true);
        await this.authService
            .handleMfa(this.mfaError, this.otpCode)
            .then(() => {
                const user = getAuth().currentUser as firebase.User;
                this.authService.afterSigninProcess(user).finally(() => {
                    this.loadingMfaCheck = false;
                    this.loading.set(false);
                });
            })
            .catch(error => {
                if (error.code === 'auth/invalid-verification-code') {
                    this.helperService.defaultHtmlToast('', this.translate.instant('account.mfa.invalid_mfa'), 'Warning');
                } else if (error.code === 'auth/quota-exceeded') {
                    this.helperService.defaultHtmlToast('', this.translate.instant('account.mfa.quota_exceeded'), 'Error');
                } else {
                    this.helperService.defaultHtmlToast('', this.translate.instant('account.mfa.unidentified_error'), 'Error');
                }
                this.loadingMfaCheck = false;
                this.loading.set(false);
            });
    }

    async resetPassword() {
        this.resetPWLoading = true;
        const emailElement: HTMLInputElement = document.getElementById('forgot-password') as HTMLInputElement;
        if (!emailElement.value) {
            this.toast.warning(this.translate.instant('sign-in.type_in_email'), this.translate.instant('misc.attention'));
            document.getElementById('forgot-password').focus();
            this.resetPWLoading = false;
        } else if (!emailElement.checkValidity()) {
            this.toast.warning(this.translate.instant('sign-in.invalid_email_typed'), this.translate.instant('misc.attention'));
            document.getElementById('forgot-password').focus();
            this.resetPWLoading = false;
        } else {
            await this.resetPasswordService.requestResetPasswordEmail(emailElement.value).catch(() => {});
            this.toast.success(this.translate.instant('sign-in.reset_link_msg'), this.translate.instant('misc.success'));
            this.resetPWLoading = false;
            this.modalService.dismissAll();
        }
    }

    openModal(modal: any) {
        const modalOptions: NgbModalOptions = {
            ariaLabelledBy: 'modal-basic-title'
        };
        this.modalService
            .open(modal, modalOptions)
            .result.then((event: any) => {})
            .catch((event: any) => {});
    }
}
