<div class="filter-wrapper">
    <!--The filter button-->
    <div class="filter-button" (click)="togglePopdown()">
        <div class="filter-button-content">
            <p class="filter-button-text">{{ "discount_tool.manage" | translate }}</p>
        </div>
        <img src="../../../../assets/icons/arrow-down-blue.svg" [className]="isExpanded ? 'arrow arrow-clicked' : 'arrow'" />
    </div>

    <!--The Popup-->
    <div [hidden]="!isExpanded" [className]="popupAnchor === 'left' ? 'filter-popup left' : 'filter-popup right'">
        <!-- Manage -->
        <div class="filter-popup-accordion">
            <div class="filter-popup-accordion-button" (click)="openAccordion(0)">
                <img src="../../../../assets/icons/arrow-down-blue.svg" [className]="openAccordions.includes(0) ? 'arrow arrow-clicked' : 'arrow'" />
                <p>{{ "discount_tool.manage" | translate }}</p>
            </div>
            <div class="filter-popup-accordion-unfolded" [hidden]="!openAccordions.includes(0)">
                <div class="w-padding" (click)="$event.stopPropagation()">
                    <div>
                        <div class="common-input">
                            <input [(ngModel)]="selectedUid" (keydown.enter)="emitUid()" placeholder="UID" class="input" type="text" />
                            <button class="input-btn" (click)="emitUid()">
                                <span *ngIf="!loadingDevices">Go</span>
                                <loading-indicator [size]="'tiny'" [color]="'white'" *ngIf="loadingDevices"></loading-indicator>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Global Discount -->
        <div class="filter-popup-accordion">
            <div class="filter-popup-accordion-button" (click)="openAccordion(1)">
                <img src="../../../../assets/icons/arrow-down-blue.svg" [className]="openAccordions.includes(1) ? 'arrow arrow-clicked' : 'arrow'" />
                <p>{{ "discount_tool.global_discount" | translate }}</p>
            </div>
            <div class="filter-popup-accordion-unfolded" [hidden]="!openAccordions.includes(1)">
                <div class="w-padding" (click)="$event.stopPropagation()">
                    <div>
                        <div class="common-input">
                            <div class="input-wrapper">
                                <input [placeholder]="'discount_tool.global_discount' | translate" (keydown.enter)="saveDiscount()" [(ngModel)]="globalDiscount" class="input" type="number" [disabled]="!selectedUid" />
                            </div>
                            <button class="input-btn" (click)="saveDiscount()" [disabled]="!selectedUid">
                                <span *ngIf="!sendingGlobalDiscount && !loadingDevices">Save</span>
                                <loading-indicator [size]="'tiny'" [color]="'white'" *ngIf="sendingGlobalDiscount || loadingDevices"></loading-indicator>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
